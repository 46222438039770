import {createSelector} from '@reduxjs/toolkit';
import {IMachineTypeDTO, MachineTypeDTO} from 'api/api';
import {machineTypeClient} from 'state/api-clients';
import {DropdownOption} from 'view/components/default-components/baisc-dropdown/basic-search-dropdown';
import {apiSlice} from './api-slice';

const machineTypeSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['MachineType'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMachineTypes: builder.query<IMachineTypeDTO[], void>({
        query: () => () => machineTypeClient.get(),
        providesTags: ['MachineType'],
      }),
      createMachineType: builder.mutation<IMachineTypeDTO, MachineTypeDTO>({
        query: (newMachine) => () => machineTypeClient.create(newMachine),
        extraOptions: {showResponse: true},
        invalidatesTags: ['MachineType'],
      }),
      updateMachineType: builder.mutation<IMachineTypeDTO, MachineTypeDTO>({
        query: (newMachine) => () => machineTypeClient.update(newMachine),
        extraOptions: {showResponse: true},
        invalidatesTags: ['MachineType'],
      }),
      deleteMachineType: builder.mutation<void, MachineTypeDTO>({
        query: (machine) => () => machineTypeClient.delete(machine),
        extraOptions: {showResponse: true},
        invalidatesTags: ['MachineType'],
      }),
    }),
  });

export const selectMachineTypes = createSelector(
  machineTypeSlice.endpoints.getMachineTypes.select(),
  (result) => result.data ?? [],
);

export const selectMachineTypeById = (id: number | undefined) =>
  createSelector(selectMachineTypes, (machineTypes) => {
    return machineTypes.find((mt) => mt.id === id);
  });

export const selectMachineTypeDropdownOptions = createSelector(selectMachineTypes, (machineTypes) =>
  machineTypes.map((machineType) => new DropdownOption(machineType.name ?? '', machineType.id)),
);

export const {
  useGetMachineTypesQuery,
  useCreateMachineTypeMutation,
  useUpdateMachineTypeMutation,
  useDeleteMachineTypeMutation,
} = machineTypeSlice;
