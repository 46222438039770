import {Box, SxProps} from '@mui/system';
import React, {useCallback, useMemo} from 'react';
import {localized, localizedInterpolationDynamic} from '../../../../i18n/i18n';
import {containerBackground} from '../../../../styles/color-constants';
import {theme} from '../../../../styles/theme';
import {genericMemo} from '../../../../utilities/generic-memo';
import {normalizeOutput} from '../../../../utilities/platform-helpers/type-helper';
import {FlexRow} from '../flex-row';
import {useListTableInternal} from './list-table';
import {CheckboxActionConfig, IdBase} from './list-table-types';

export const ListTableCheckboxActions = genericMemo(<T extends IdBase>() => {
  const {checkboxActions: checkBoxActions, selectedIds, rows} = useListTableInternal();
  const selectedRows = useMemo(() => rows.filter((r) => selectedIds.includes(r.id)), [rows, selectedIds]);

  const onActionClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, action: CheckboxActionConfig<T>) => {
      const disabled = normalizeOutput(action.disabled, selectedRows);
      if (disabled) return;

      event.stopPropagation();
      action.onClick(selectedIds);
    },
    [selectedIds, selectedRows],
  );

  const actionElements = useMemo(
    () =>
      checkBoxActions?.map((action, index) => {
        const disabled = normalizeOutput(action.disabled, selectedRows);
        return (
          <Box
            key={'checkbox-action' + index}
            sx={disabled ? localStyles.actionDisabled : localStyles.action}
            onClick={(event) => onActionClick(event, action)}>
            <FlexRow alignItems={'center'} gap={1}>
              <Box>{action.label}</Box>
              {action.icon}
            </FlexRow>
          </Box>
        );
      }),
    [checkBoxActions, selectedRows, onActionClick],
  );

  return (
    <FlexRow sx={localStyles.checkboxActions}>
      <Box display="flex" flexDirection="row">
        <Box fontWeight={'bold'}>{localized('Actions')}</Box>
        <Box>
          {localizedInterpolationDynamic('SelectIntOfInt', selectedIds.length.toString(), rows.length.toString())}
        </Box>
        {actionElements}
      </Box>
      <Box sx={localStyles.triangleDown} />
    </FlexRow>
  );
});

const localStyles: {[key: string]: SxProps} = {
  checkboxActions: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingX: 2,
    height: 60,
    width: 'fit-content',
    borderRadius: 1,
    marginTop: 1,
    backgroundColor: containerBackground,
  },
  triangleDown: {
    position: 'absolute',
    top: 58,
    left: 22.5,
    width: 0,
    height: 0,
    borderLeft: 10 + 'px solid transparent',
    borderRight: 10 + 'px solid transparent',
    borderTop: theme.spacing(1) + ' solid ' + containerBackground,
  },
  action: {
    userSelect: 'none',
    cursor: 'pointer',
  },
  actionDisabled: {
    userSelect: 'none',
    opacity: 0.5,
  },
};
