import {Box} from '@mui/material';
import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useMatch, useParams} from 'react-router-dom';
import {selectMachineTypeDropdownOptions} from 'state/features/api/machine-type-slice';
import {selectMachineById, useCreateMachineMutation, useUpdateMachineMutation} from 'state/features/api/machines-slice';
import {selectOrganizationDropdownOptions} from 'state/features/api/organization-slice';
import {routes} from 'state/routes';
import {useGoBackOnSuccess} from 'utilities/navigation-helper';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {Form} from 'view/components/default-components/form/form';
import {DropdownOption} from 'view/components/default-components/form/form-types';
import {SelectInput} from 'view/components/default-components/form/select-input';
import {IMachineDTO, MachineDTO} from '../../../../api/api';
import {localized, localizedInterpolation} from '../../../../i18n/i18n';
import {primaryColor} from '../../../../styles/color-constants';
import {nameof} from '../../../../utilities/platform-helpers/nameof-helper';
import {linkValidator, requiredValidator} from '../../../../utilities/platform-helpers/validator-helper';
import {BasicCard} from '../../../components/default-components/basic-card/basic-card';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {FlexRow} from '../../../components/default-components/flex-row';
import {TextInput} from '../../../components/default-components/form/text-input';
import {TextLabel} from '../../../components/default-components/text-label';

interface PropsFromParent {}
const sharedCacheKey = 'machineMutation';
const activatedOptions: DropdownOption[] = [
  {label: localized('Activated'), value: true},
  {label: localized('Deactivated'), value: false},
];
export const MachineForm: FC<PropsFromParent> = React.memo(() => {
  let {machineId} = useParams<{machineId: string}>();
  const match = useMatch(routes.machinesEdit);
  const [updateMachine] = useUpdateMachineMutation({fixedCacheKey: sharedCacheKey});
  const [createMachine, {isLoading, isSuccess, reset}] = useCreateMachineMutation({fixedCacheKey: sharedCacheKey});

  const machineTypeOptions = useSelector(selectMachineTypeDropdownOptions);
  const organizationOptions = useSelector(selectOrganizationDropdownOptions);

  const machine = useSelector(selectMachineById(Number(machineId)));

  useGoBackOnSuccess(isSuccess, reset);

  const handleSubmit = useCallback(
    (data: IMachineDTO) => {
      if (machine) {
        const updatedMachine: IMachineDTO = {
          ...machine,
          ...data,
          activated: data.activated === undefined ? true : data.activated,
        };
        updateMachine(updatedMachine as MachineDTO);
      } else {
        const newMachine: IMachineDTO = {
          ...data,
          activated: data.activated === undefined ? true : data.activated,
        };
        createMachine(newMachine as MachineDTO);
      }
    },
    [createMachine, machine, updateMachine],
  );

  return (
    <Form onSubmit={handleSubmit} defaultValues={machine}>
      <FlexRow gap={4}>
        <BasicCard sx={{width: 500, height: 'fit-contet'}} m={0}>
          <FlexRow>
            <FlexColumn>
              <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
                {localized('Machine')}
              </TextLabel>
              <TextLabel variant="body1" marginBottom={3}>
                {localizedInterpolation(machine ? 'SaveMetaInt' : 'CreateMetaInt', 'Machine')}
              </TextLabel>
              <TextLabel variant="h6" fontWeight="bold">
                {localized('MachineInformation')}
              </TextLabel>
            </FlexColumn>
          </FlexRow>

          <Box flex={1} maxWidth={400}>
            <TextInput
              name={nameof<IMachineDTO>('serialNumber')}
              label={localized('SerialNumber')}
              validators={requiredValidator}
            />
            <SelectInput
              noDefault
              name={nameof<IMachineDTO>('machineTypeId')}
              label={localized('MachineType')}
              options={machineTypeOptions}
              validators={requiredValidator}
            />
            <TextInput
              name={nameof<IMachineDTO>('deviceId')}
              label={localized('DeviceId')}
              validators={requiredValidator}
            />
            <SelectInput
              noDefault
              name={nameof<IMachineDTO>('organizationId')}
              label={localized('Organization')}
              options={organizationOptions}
            />
            <SelectInput
              name={nameof<IMachineDTO>('activated')}
              label={localized('MachineState')}
              options={activatedOptions}
            />
          </Box>
          <FlexRow gap={2}>
            <BasicButton
              type="submit"
              buttonColor={'primary'}
              text={localizedInterpolation(match ? 'SaveInt' : 'CreateNewInt', 'Machine')}
            />
            <BasicButton backButton buttonColor={'secondary'} text={localized('Cancel')} />
          </FlexRow>
        </BasicCard>
        <BasicCard sx={{width: 800, height: 'fit-content'}} m={0}>
          <FlexColumn maxWidth={500}>
            <TextLabel variant="h6" fontWeight="bold">
              {localized('Links')}
            </TextLabel>

            <TextInput
              name={nameof<IMachineDTO>('linkSparePartManual')}
              label={localized('SparePartManual')}
              validators={linkValidator}
            />
            <TextInput
              name={nameof<IMachineDTO>('linkUserManual')}
              label={localized('UserManual')}
              validators={linkValidator}
            />
            <TextInput name={nameof<IMachineDTO>('linkVideo')} label={localized('Videos')} validators={linkValidator} />
            <TextInput
              name={nameof<IMachineDTO>('linkConsumables')}
              label={localized('Consumables')}
              validators={linkValidator}
            />
            <TextInput
              name={nameof<IMachineDTO>('linkSpareParts')}
              label={localized('SpareParts')}
              validators={linkValidator}
            />
          </FlexColumn>
        </BasicCard>
      </FlexRow>
    </Form>
  );
});
