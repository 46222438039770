import {IonIcon} from '@ionic/react';
import {Box} from '@mui/material';
import {IMachineDTO} from 'api/api';
import {bookOutline, playCircleOutline, storefrontOutline} from 'ionicons/icons';
import {FC, memo} from 'react';
import {invalidColor, onlineColor} from 'styles/color-constants';
import {FlexRow} from 'view/components/default-components/flex-row';

interface Props {
  machine?: IMachineDTO;
}
export const MachineLinkStatusCell: FC<Props> = memo(({machine}) => {
  return (
    <FlexRow>
      <FlexRow alignItems={'center'} sx={{color: machine?.linkUserManual ? onlineColor : invalidColor}}>
        <IonIcon icon={bookOutline} style={{fontSize: '20px'}} />
        <Box ml={0.5}>M</Box>
      </FlexRow>
      <FlexRow alignItems={'center'} sx={{color: machine?.linkSparePartManual ? onlineColor : invalidColor}}>
        <IonIcon icon={bookOutline} style={{fontSize: '20px'}} />
        <Box ml={0.5} mr={0.6}>
          SPM
        </Box>
      </FlexRow>
      <FlexRow alignItems={'center'} sx={{color: machine?.linkConsumables ? onlineColor : invalidColor}}>
        <IonIcon icon={storefrontOutline} style={{fontSize: '20px'}} />
        <Box ml={0.4}>C</Box>
      </FlexRow>
      <FlexRow alignItems={'center'} sx={{color: machine?.linkSpareParts ? onlineColor : invalidColor}}>
        <IonIcon icon={storefrontOutline} style={{fontSize: '20px'}} />
        <Box ml={0.4}>SP</Box>
      </FlexRow>
      <FlexRow alignItems={'center'} sx={{color: machine?.linkVideo ? onlineColor : invalidColor}}>
        <IonIcon icon={playCircleOutline} style={{fontSize: '24px'}} />
      </FlexRow>
    </FlexRow>
  );
});
