import {createSelector} from '@reduxjs/toolkit';
import {AddMachineDTO, IAddMachineDTO, IMachineDTO, MachineDTO} from 'api/api';
import {machineClient} from 'state/api-clients';
import {apiSlice} from './api-slice';

export const machinesSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Machine'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMachines: builder.query<IMachineDTO[], void>({
        query: () => () => machineClient.getForCurrentUser(),
        providesTags: ['Machine'],
      }),
      createMachine: builder.mutation<IMachineDTO, MachineDTO>({
        query: (newMachine) => () => machineClient.create(newMachine),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Machine'],
      }),
      updateMachine: builder.mutation<IMachineDTO, MachineDTO>({
        query: (newMachine) => () => machineClient.update(newMachine),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Machine'],
      }),
      addMachineToUser: builder.mutation<void, IAddMachineDTO>({
        query: (addNewMachineDTO) => () => machineClient.addNewMachine(addNewMachineDTO as AddMachineDTO),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Machine'],
      }),
      deleteMachine: builder.mutation<void, string>({
        query: (serialNumber) => () => machineClient.deleteMachineBySerialNumber(serialNumber),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Machine'],
      }),
    }),
  });

export const selectMachines = createSelector(
  machinesSlice.endpoints.getMachines.select(),
  (result) => result.data ?? [],
);

export const selectMachineById = (id: number | undefined) =>
  createSelector(selectMachines, (machines) => {
    return machines.find((mt) => mt.id === id);
  });

export const selectMachinesByIds = (ids: number[]) =>
  createSelector(selectMachines, (machines) => {
    return machines.filter((mt) => ids.includes(mt.id));
  });

export const selectMachinesByAgentId = (agentId: number | undefined) =>
  createSelector(selectMachines, (machines) => {
    return machines.filter((mt) => mt.agentId === agentId);
  });

export const selectMachineDropdownOptionsByAgentId = (agentId: number) =>
  createSelector(selectMachines, (machines) => {
    return machines.filter((m) => m.agentId === agentId || !m.agentId).map((m) => m.id) ?? [];
  });
export const selectMachineNameMap = createSelector(
  selectMachines,
  (machines) => new Map(machines?.map((m) => [m.id, m.serialNumber])),
);

export const {
  useGetMachinesQuery,
  useCreateMachineMutation,
  useUpdateMachineMutation,
  useDeleteMachineMutation,
  useAddMachineToUserMutation,
} = machinesSlice;
