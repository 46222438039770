import {IonIcon} from '@ionic/react';
import {Menu, MenuItem, Typography} from '@mui/material';
import {IMachineDTO} from 'api/api';
import {localized} from 'i18n/i18n';
import {bookOutline, mailOutline, playCircleOutline, storefrontOutline} from 'ionicons/icons';
import React, {FC, memo, useCallback, useState} from 'react';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
interface Props {
  machine?: IMachineDTO;
}

export const ResourcesMenu: FC<Props> = memo((props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const openInNewTab = useCallback((url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openLink = useCallback(
    (url?: string) => {
      if (!url) return;
      openInNewTab(url);
      handleClose();
    },
    [handleClose, openInNewTab],
  );

  return (
    <>
      <BasicButton disabled={false} onClick={handleClick} text={localized('Resources')} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => openLink(props.machine?.linkUserManual)} disabled={!props.machine?.linkUserManual}>
          <IonIcon icon={bookOutline} />
          <Typography ml={2}>{localized('UserManual')}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => openLink(props.machine?.linkSparePartManual)}
          disabled={!props.machine?.linkSparePartManual}>
          <IonIcon icon={bookOutline} />
          <Typography ml={2}>{localized('SparePartManual')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => openLink(props.machine?.linkVideo)} disabled={!props.machine?.linkVideo}>
          <IonIcon icon={playCircleOutline} />
          <Typography ml={2}>{localized('Videos')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => openLink(props.machine?.linkSupport)} disabled={!props.machine?.linkSupport}>
          <IonIcon icon={mailOutline} />
          <Typography ml={2}>{localized('ContactSupport')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => openLink(props.machine?.linkSpareParts)} disabled={!props.machine?.linkSupport}>
          <IonIcon icon={storefrontOutline} />
          <Typography ml={2}>{localized('SpareParts')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => openLink(props.machine?.linkConsumables)} disabled={!props.machine?.linkSupport}>
          <IonIcon icon={storefrontOutline} />
          <Typography ml={2}>{localized('Consumables')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
});
