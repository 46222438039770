import React, {FC, useMemo} from 'react';
import {useMatch} from 'react-router-dom';
import {useGetMachineTypesQuery} from 'state/features/api/machine-type-slice';
import {useGetMachinesQuery} from 'state/features/api/machines-slice';
import {useGetOrganizationsQuery} from 'state/features/api/organization-slice';
import {routes} from 'state/routes';
import {localizedInterpolation} from '../../../../i18n/i18n';
import {FlexRow} from '../../../components/default-components/flex-row';
import {PageContentContainer} from '../../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../components/default-components/page-title/page-title';
import {MachineForm} from './machine-form';

export const CreateMachine: FC = React.memo(() => {
  const match = useMatch(routes.machinesEdit);
  const title = useMemo(() => localizedInterpolation(match ? 'EditInt' : 'CreateNewInt', 'Machine'), [match]);
  // Fetching machine type data but using it through selectors
  useGetMachinesQuery();
  useGetMachineTypesQuery();
  useGetOrganizationsQuery();

  return (
    <PageTitle title={title}>
      <PageHeader area="machineCreate" title={title} />
      <PageContentContainer xy>
        <FlexRow height={1}>
          <MachineForm />
        </FlexRow>
      </PageContentContainer>
    </PageTitle>
  );
});
