import {SxProps} from '@mui/system';
import React, {FC, ReactNode, memo} from 'react';
import {BasicButton} from '../buttons/basic-button';
import {BasicSpinner} from '../spinner/basic-spinner';
import {buttonBoxShadow} from '../../../../styles/color-constants';

interface Props {
  sx?: SxProps;
  text?: string | ReactNode;
  onClick?: () => void;
  loading?: boolean;
  icon?: ReactNode;
}
export const PageHeaderButton: FC<Props> = memo(({text, onClick, loading, icon, sx}) => {
  return (
    <BasicButton
      sx={[baseSx, sx] as SxProps}
      onClick={onClick}
      text={typeof text === 'string' ? text?.toLocaleUpperCase() : text}
      icon={loading ? <BasicSpinner size={20} /> : icon}
    />
  );
});

const baseSx: SxProps = {
  height: 50,
  fontSize: 16,
  boxShadow: '0px 1px ' + buttonBoxShadow,
  ':hover': {
    boxShadow: '0px 1px ' + buttonBoxShadow,
  },
};
