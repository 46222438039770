import CloseIcon from '@mui/icons-material/Close';
import {Box} from '@mui/material';
import {localized} from 'i18n/i18n';
import {useDialog} from 'providers/dialog-provider';
import {FC, memo, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {selectMachineNameMap} from 'state/features/api/machines-slice';
import {selectUserById, useRemoveMachineFromUserMutation} from 'state/features/api/user-slice';
import {primaryColor} from 'styles/color-constants';
import {theme} from 'styles/theme';
import {BasicCard} from 'view/components/default-components/basic-card/basic-card';
import {FlexRow} from 'view/components/default-components/flex-row';
import MachineIcon from 'view/components/default-components/icons/machine-icon';
import {TextLabel} from 'view/components/default-components/text-label';
export const RemoveMachineCard: FC = memo(() => {
  const {openDeleteDialog} = useDialog();
  let {userId} = useParams<{userId: string}>();
  const user = useSelector(selectUserById(Number(userId)));
  const machineMap = useSelector(selectMachineNameMap);
  const [removeMachineFromUser] = useRemoveMachineFromUserMutation();

  const handleRemove = useCallback(
    (machineId: number) => {
      openDeleteDialog(localized('ConfirmRemoveMachine'), () => {
        removeMachineFromUser({machineId, userId: Number(userId)});
      });
    },
    [openDeleteDialog, removeMachineFromUser, userId],
  );

  const machineRows = useMemo(
    () =>
      user?.machineIds?.map((machineId) => (
        <FlexRow
          key={machineId}
          sx={{
            background: theme.colors.listTable.ListTableBgColor,
            marginTop: 1,
            marginBottom: 1,
            padding: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <FlexRow sx={{alignItems: 'center'}}>
            <MachineIcon />
            <TextLabel variant="h6" color={primaryColor} marginLeft={2}>
              {machineMap.get(machineId)}
            </TextLabel>
          </FlexRow>
          <Box
            sx={{cursor: 'pointer', justifyContent: 'center', display: 'flex'}}
            onClick={() => handleRemove(machineId)}>
            <CloseIcon />
          </Box>
        </FlexRow>
      )),
    [handleRemove, machineMap, user?.machineIds],
  );

  return (
    <BasicCard sx={{width: 'unset', height: 'fit-contet'}}>
      <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
        {localized('Machines')}
      </TextLabel>
      <TextLabel variant="body1" marginBottom={3}>
        {localized('RemoveMachineMeta')}
      </TextLabel>
      <TextLabel variant="h6" fontWeight="bold" marginBottom={0}>
        {localized('SerialNumber')}
      </TextLabel>
      {machineRows}
    </BasicCard>
  );
});
