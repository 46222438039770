import styled from '@emotion/styled';
import {Fade, ListItem, ListItemIcon, ListItemText, Typography, useTheme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {sidebarIconColor, whiteColor} from 'styles/color-constants';

interface PropsFromParent {
  selected: boolean;
  icon?: JSX.Element;
  identifier: any;
  linkTo: string;
  text?: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onMenuItemClicked?: (identifier: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    fixFirefox: {
      color: 'darkslategrey',
      textDecoration: 'none',
    },
  }),
);

const StyledListItem = styled(ListItem)`
  position: relative;
  padding: 16px 0 16px 24px;
  color: white;

  &.Mui-selected {
    background-color: white;
    color: ${({theme}) => theme.palette.common.flexoWashBlue};
  }
`;

const ActiveIndicator = styled.div<{selected: boolean}>`
  height: 100%;
  width: 5px;
  background-color: ${({selected, theme}) => (selected ? sidebarIconColor : theme.palette.primary.main)};
  position: absolute;
  right: 0px;
`;

const Icon = styled(ListItemIcon)<{selected: boolean}>`
  transition: ${({theme}) => theme.transitions.create('color', {duration: theme.transitions.duration.short})};
  color: ${({selected, theme}) => (selected ? sidebarIconColor : theme.palette.common.flexoWashBlueLight)};
  & > svg {
    font-size: 1.8rem;
  }
`;

export const DrawerItem: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        setIsDrawerOpen(true);
      }, theme.transitions.duration.short);
    } else {
      setIsDrawerOpen(false);
    }
  }, [props.open, theme.transitions.duration.short]);

  const onMenuItemClicked = () => {
    props.onMenuItemClicked && props.onMenuItemClicked(props.identifier);
  };

  return (
    <Link to={props.linkTo} className={classes.fixFirefox}>
      <StyledListItem selected={props.selected} onClick={onMenuItemClicked} key={props.identifier}>
        <Icon selected={Boolean(props.selected)}>{props.icon}</Icon>
        <ListItemText>
          <Typography
            sx={{
              margin: 'auto',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              whiteSpace: 'pre-line',
              wordWrap: 'break-word',
            }}
            color={props.selected ? 'primary' : whiteColor}
            variant="body1">
            {isDrawerOpen && props.text}
          </Typography>
        </ListItemText>
        <Fade
          in={props.selected}
          timeout={{
            enter: 225,
            exit: 0,
          }}>
          <ActiveIndicator selected={props.selected} />
        </Fade>
      </StyledListItem>
    </Link>
  );
});
