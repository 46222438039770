import {Box, SxProps} from '@mui/material';
import React, {useCallback} from 'react';
import {theme} from '../../../../styles/theme';
import {genericMemo} from '../../../../utilities/generic-memo';
import {FlexRow} from '../flex-row';
import {useListTableInternal} from './list-table';
import {ListTableCheckbox} from './list-table-check-box';
import {IdBase, ListTableRowProps} from './list-table-types';
import {
  VirutalListItemSize,
  getColumnFormattedValue,
  getColumnMaxWidth,
  getColumnMinWidth,
  getColumnStyle,
} from './list-table-util';

export const ListTableRow = genericMemo(<T extends IdBase>(props: ListTableRowProps<T>) => {
  const {columns, onDelete, onEdit, actions, actionCount, checkbox, selectedIds, orderBy, setSelectedIds} =
    useListTableInternal();

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      props.onRowClick && props.onRowClick(props.row);
    },
    [props],
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      event.stopPropagation();
      const ids = checked ? [...selectedIds, props.row.id] : selectedIds.filter((sId) => sId !== props.row.id);
      setSelectedIds(ids);
    },
    [props.row.id, selectedIds, setSelectedIds],
  );

  return (
    <FlexRow minWidth={'fit-content'} sx={localStyles.row} paddingLeft={checkbox ? 2 : 3}>
      {checkbox && <ListTableCheckbox checked={selectedIds.includes(props.row.id)} onChange={handleCheckboxChange} />}
      <FlexRow
        sx={[localStyles.onClickContainer, props.onRowClick && {cursor: 'pointer'}] as SxProps}
        onClick={onClick}>
        {columns.map((column, index, array) => {
          // Max width from props
          const maxWidth = getColumnMaxWidth(column, actionCount);
          const minWidth = getColumnMinWidth(column, actionCount);
          // Styling override from props
          const style = getColumnStyle(column);

          const formattedValue = getColumnFormattedValue(column, props.row, onDelete, onEdit, actions);
          return (
            <Box
              key={props.row.id + column.field.toString()}
              flex={1}
              sx={style}
              title={
                !column.customComponent && !column.disableTextEllipsis && column.field !== 'action'
                  ? formattedValue
                  : undefined
              }
              maxWidth={maxWidth}
              minWidth={minWidth}
              color={theme.colors.listTable.ListTableTextColor}
              fontWeight={orderBy === column.field ? 'bold' : 'normal'}>
              {column.customComponent && typeof column.customComponent === 'function'
                ? column.customComponent(props.row)
                : column.customComponent}
              {!column.customComponent && formattedValue}
            </Box>
          );
        })}
      </FlexRow>
    </FlexRow>
  );
});

const localStyles: {[key: string]: SxProps} = {
  onClickContainer: {
    flex: 1,
    alignItems: 'center',
    gap: 2,
    height: '100%',
  },
  row: {
    marginBottom: 1,
    paddingRight: 3,
    borderRadius: 1,
    gap: 2,
    backgroundColor: theme.colors.listTable.ListTableBgColor,
    height: VirutalListItemSize - 8,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#e3e3e3',
    },
  },
};
