import Grid from '@mui/material/Grid';
import {IStandardMachineStatisticDTO} from 'api/api';
import {localized} from 'i18n/i18n';
import React, {memo} from 'react';
import {StatisticItem, getValueOrUnknown} from '../statistic-item';
import {SmallStatisticItemWrapper} from '../statistic-item-container';
import {StatisticsProps} from '../statistics';
import {StatisticItemRoot} from '../statistics-container-item';

export const StandardStatistics: React.FC<StatisticsProps> = memo((props) => {
  const {statistics} = props.data;

  if (!statistics) {
    return <div>{localized('NoStatistics')}</div>;
  }

  const {
    totalWashes,
    washesSinceService,
    totalWashHours,
    washHoursSinceService,
    totalRinses,
    rinsesSinceService,
    totalRinseHours,
    rinseHoursSinceService,
    washesUntilNextService,
    washesUntilNextFilter,
  } = statistics as IStandardMachineStatisticDTO;

  return (
    <Grid
      container
      spacing={{
        xs: 5,
        xl: 6,
      }}>
      <StatisticItemRoot>
        <SmallStatisticItemWrapper>
          <StatisticItem label={localized('TotalWashes')} placeholder={getValueOrUnknown(totalWashes)} />
          <StatisticItem label={localized('WashesSinceService')} placeholder={getValueOrUnknown(washesSinceService)} />
          <StatisticItem label={localized('TotalWashHours')} placeholder={getValueOrUnknown(totalWashHours)} />
          <StatisticItem
            label={localized('WashHoursSinceService')}
            placeholder={getValueOrUnknown(washHoursSinceService)}
          />
        </SmallStatisticItemWrapper>
      </StatisticItemRoot>

      <StatisticItemRoot>
        <SmallStatisticItemWrapper>
          <StatisticItem label={localized('TotalRinses')} placeholder={getValueOrUnknown(totalRinses)} />
          <StatisticItem label={localized('RinsesSinceService')} placeholder={getValueOrUnknown(rinsesSinceService)} />
          <StatisticItem label={localized('TotalRinseHours')} placeholder={getValueOrUnknown(totalRinseHours)} />
          <StatisticItem
            label={localized('RinseHoursSinceService')}
            placeholder={getValueOrUnknown(rinseHoursSinceService)}
          />
        </SmallStatisticItemWrapper>
      </StatisticItemRoot>

      <StatisticItemRoot>
        <StatisticItem
          label={localized('WashesLeftBeforeService')}
          placeholder={getValueOrUnknown(washesUntilNextService)}
          variant="large"
        />
      </StatisticItemRoot>

      <StatisticItemRoot>
        <StatisticItem
          label={localized('WashesLeftBeforeFilterChange')}
          placeholder={getValueOrUnknown(washesUntilNextFilter)}
          variant="large"
        />
      </StatisticItemRoot>
    </Grid>
  );
});
