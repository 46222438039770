import {
  IMachineDTO,
  IMachineLatestCleaningDTO,
  IMachineLatestStatisticsDTO,
  IMachineLatestTankStatusDTO,
} from 'api/api';
import {localized} from 'i18n/i18n';

export const getDefaultMachineOverviewColumns = (): Map<
  keyof (IMachineDTO & IMachineLatestCleaningDTO & IMachineLatestStatisticsDTO & IMachineLatestTankStatusDTO),
  string
> => {
  const machineOverviewColumnsMap = new Map<
    keyof (IMachineDTO & IMachineLatestCleaningDTO & IMachineLatestStatisticsDTO & IMachineLatestTankStatusDTO),
    string
  >();
  machineOverviewColumnsMap.set('serialNumber', localized('SerialNumber'));
  machineOverviewColumnsMap.set('machineTypeName', localized('MachineType'));
  machineOverviewColumnsMap.set('organizationId', localized('CustomerName'));
  machineOverviewColumnsMap.set('currentState', localized('Status'));
  machineOverviewColumnsMap.set('deviceId', localized('Connection'));
  machineOverviewColumnsMap.set('linkSupport', localized('Links'));
  machineOverviewColumnsMap.set('id', localized('Details'));

  return machineOverviewColumnsMap;
};

export const getAllMachineOverviewColumns = (): Map<
  keyof (IMachineDTO & IMachineLatestCleaningDTO & IMachineLatestStatisticsDTO & IMachineLatestTankStatusDTO),
  string
> => {
  const machineOverviewColumnsMap = getDefaultMachineOverviewColumns();
  machineOverviewColumnsMap.set('currentAlarms', localized('ActiveAlarms'));
  machineOverviewColumnsMap.set('totalWashes', localized('TotalWashes'));
  machineOverviewColumnsMap.set('washesSinceService', localized('WashesSinceService'));
  machineOverviewColumnsMap.set('totalWashHours', localized('TotalWashHours'));
  machineOverviewColumnsMap.set('washHoursSinceService', localized('WashHoursSinceService'));
  machineOverviewColumnsMap.set('totalRinses', localized('TotalRinses'));
  machineOverviewColumnsMap.set('rinsesSinceService', localized('RinsesSinceService'));
  machineOverviewColumnsMap.set('totalRinseHours', localized('TotalRinseHours'));
  machineOverviewColumnsMap.set('rinseHoursSinceService', localized('RinseHoursSinceService'));
  machineOverviewColumnsMap.set('washesLeftBeforeService', localized('WashesLeftBeforeService'));
  machineOverviewColumnsMap.set('washesLeftBeforeFilterChange', localized('WashesLeftBeforeFilterChange'));
  machineOverviewColumnsMap.set('totalAniloxes', localized('TotalAniloxes'));
  machineOverviewColumnsMap.set('aniloxesSinceService', localized('AniloxesSinceService'));
  machineOverviewColumnsMap.set('totalLaserHours', localized('TotalLaserHours'));
  machineOverviewColumnsMap.set('laserHoursSinceService', localized('LaserHoursSinceService'));
  machineOverviewColumnsMap.set('totalVacuumHours', localized('TotalVacuumHours'));
  machineOverviewColumnsMap.set('vacuumHoursSinceFilterChange', localized('VacuumHoursSinceFilterChange'));
  machineOverviewColumnsMap.set('vacuumHoursUntilNextFilter', localized('VacuumHoursLeftBeforeFilterChange'));
  machineOverviewColumnsMap.set('date', localized('Date'));
  machineOverviewColumnsMap.set('time', localized('Time'));
  machineOverviewColumnsMap.set('sequence', localized('Sequence'));
  machineOverviewColumnsMap.set('recipe', localized('Recipe'));
  machineOverviewColumnsMap.set('washTime', localized('WashTime'));
  machineOverviewColumnsMap.set('tank', localized('Tank'));
  machineOverviewColumnsMap.set('temp', localized('Temperature'));
  machineOverviewColumnsMap.set('rinse', localized('Rinse'));
  machineOverviewColumnsMap.set('dry', localized('Dry'));
  machineOverviewColumnsMap.set('washSpeed', localized('WashSpeed'));
  machineOverviewColumnsMap.set('employeeId', localized('EmployeeId'));
  machineOverviewColumnsMap.set('rollId', localized('RollId'));
  machineOverviewColumnsMap.set('intensity', localized('Intensity'));
  machineOverviewColumnsMap.set('color', localized('Color'));
  machineOverviewColumnsMap.set('passes', localized('Passes'));
  machineOverviewColumnsMap.set('numberOfTanks', localized('NumberOfTanks'));
  machineOverviewColumnsMap.set('levelTank1', localized('LevelTank1'));
  machineOverviewColumnsMap.set('temperatureTank1', localized('TemperatureTank1'));
  machineOverviewColumnsMap.set('levelTank2', localized('LevelTank2'));
  machineOverviewColumnsMap.set('temperatureTank2', localized('TemperatureTank2'));

  return machineOverviewColumnsMap;
};
