import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {selectOrganizationById} from 'state/features/api/organization-slice';
import {routes} from '../../../../state/routes';
import {ContentBox} from '../../../components/default-components/content-box/content-box';
import {ContentBoxBody} from '../../../components/default-components/content-box/content-box-body';
import {ContentBoxHeader} from '../../../components/default-components/content-box/content-box-header';

interface PropsFromParent {
  organizationId: number;
}
export const OrganizationBoxItem: FC<PropsFromParent> = React.memo(({organizationId}) => {
  const navigate = useNavigate();
  const item = useSelector(selectOrganizationById(organizationId));

  const editOrganization = useCallback(
    () => {
      if (item) {
        navigate(`${routes.organization}/${item.id}/edit`);
      }
    }, // eslint-disable-next-line
    [organizationId],
  );
  if (!item) {
    return null;
  }
  return (
    <ContentBox itemSelected={editOrganization} contentItem={item}>
      <ContentBoxHeader>{item.name}</ContentBoxHeader>
      <ContentBoxBody>{item.description}</ContentBoxBody>
    </ContentBox>
  );
});
