import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const FrenchFlagIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path fill="#eee" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
      <path fill="#0052b4" d="M0 0h167v512H0z" />
      <path fill="#d80027" d="M345 0h167v512H345z" />
    </SvgIcon>
  );
};

export default React.memo(FrenchFlagIcon);
