import {IMachineStatisticDTO, MachineGroupType} from 'api/api';
import React, {memo} from 'react';
import {useParams} from 'react-router-dom';
import {useGetMachineStatisticsQuery} from 'state/features/api/machine-statistics-slice';
import {LaserStatistics} from './laser-statistics/laser-statistics';
import {PWStatistics} from './pw-statistics/pw-statistics';
import {StandardStatistics} from './standard-statistics/standard-statistics';

export interface StatisticsProps {
  data: IMachineStatisticDTO;
}

export const Statistics: React.FC = memo(() => {
  const {machineId} = useParams();
  const {data, isLoading, isError, error} = useGetMachineStatisticsQuery(Number(machineId), {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    pollingInterval: 5000,
  });

  if (isLoading) {
    return <h1>Loading</h1>;
  }

  if (isError) {
    return <h1>Error</h1>;
  }

  if (!data) {
    return <h1>No data</h1>;
  }
  switch (data.machineGroupType) {
    case MachineGroupType.Standard:
      return <StandardStatistics data={data} />;
    case MachineGroupType.PW:
      return <PWStatistics data={data} />;
    case MachineGroupType.Laser:
      return <LaserStatistics data={data} />;
    default:
      return <></>;
  }
});
