import styled from '@emotion/styled';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {Box, Typography} from '@mui/material';
import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {localized} from '../../../../i18n/i18n';
import {toggleView} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {defaultPageSetting, ViewType} from '../../../../state/features/page-header-setting/types';
import {AppState} from '../../../../state/store';
import {HorizontalToggleButtons} from '../toggle-button/toggle-button-group';
import {ToggleItem} from '../toggle-button/toggle-item';
import {usePageHeader} from './page-header';

interface PropsFromParent {}

const Container = styled(Box)`
  ${({theme}) => theme.breakpoints.up('sm')} {
    margin-right: ${({theme}) => theme.spacing(3)};
  }
  ${({theme}) => theme.breakpoints.up('md')} {
    margin-right: ${({theme}) => theme.spacing(4)};
  }
  ${({theme}) => theme.breakpoints.up('lg')} {
    margin-right: ${({theme}) => theme.spacing(6)};
  }
`;

export const PageHeaderToggleView: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const {areaId} = usePageHeader();

  const pageSetting =
    useSelector((store: AppState) => store.pageSettingReducer.pageSettings.find((setting) => setting.key === areaId)) ||
    defaultPageSetting(areaId);

  const viewChanged = useCallback(
    (newView: string | string[]) => {
      if ((newView as ViewType) in ViewType) {
        dispatch(toggleView({value: newView as ViewType, key: areaId}));
      }
    },
    [dispatch, areaId],
  );

  return (
    <Container columnGap={2} display="flex" alignItems="center" flexDirection="row">
      <Typography variant="body2" color="common.flexoWashBlueLight">
        {localized('Show')}
      </Typography>
      <HorizontalToggleButtons selection={pageSetting?.itemView || ViewType.ListView} selectionChanged={viewChanged}>
        <ToggleItem value={ViewType.ListView} title={localized('List')}>
          <ViewListIcon />
        </ToggleItem>
        <ToggleItem value={ViewType.BoxView} title={localized('Box')}>
          <ViewModuleIcon />
        </ToggleItem>
      </HorizontalToggleButtons>
    </Container>
  );
});
