import {Box} from '@mui/material';
import {IOrganizationDTO, Organization} from 'api/api';
import {useDialog} from 'providers/dialog-provider';
import React, {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {selectOrganizations, useDeleteOrganizationMutation} from 'state/features/api/organization-slice';
import {routes} from 'state/routes';
import {defaultVirtualizedListTableHeight} from 'styles/styling-constants';
import {ColumnDef} from 'view/components/default-components/list-table/list-table-types';
import {localized} from '../../../../i18n/i18n';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {filterListItems} from '../../../../utilities/helpers/general-helpers';
import {VirtualizedListTable} from 'view/components/default-components/list-table/virtual-list-table';

const columns: ColumnDef<IOrganizationDTO>[] = [
  {field: 'name', header: localized('Name')},
  {field: 'description', header: localized('Description')},
];

export const OrganizationListView: FC = React.memo(() => {
  const organizations = useSelector(selectOrganizations);
  const pageSetting = useSelector(selectPageSettingByKey('organization'));
  const navigate = useNavigate();
  const {openDeleteDialog} = useDialog();
  const [deleteOrganization] = useDeleteOrganizationMutation();

  const onEditClick = useCallback(
    (organization: IOrganizationDTO) => {
      navigate(`${routes.organization}/${organization.id}/edit`);
    },
    [navigate],
  );

  const handleDialogDelete = useCallback(
    (organization: IOrganizationDTO) => {
      deleteOrganization(organization as Organization);
    },
    [deleteOrganization],
  );

  const onDeleteClick = useCallback(
    (organization: IOrganizationDTO) => {
      openDeleteDialog(localized('ConfirmDeleteOrganization'), () => handleDialogDelete(organization));
    },
    [handleDialogDelete, openDeleteDialog],
  );

  const items = useMemo(
    () => filterListItems(organizations, ['name'], pageSetting?.searchString),
    [pageSetting, organizations],
  );

  return (
    <Box>
      <VirtualizedListTable<IOrganizationDTO>
        height={defaultVirtualizedListTableHeight}
        rows={items}
        columns={columns}
        defaultOrderBy={'name'}
        actionHeader={localized('Edit')}
        onEdit={onEditClick}
        onDelete={onDeleteClick}
      />
    </Box>
  );
});
