import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const UserSettingsIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21.734 24.644">
      <g transform="translate(-821.282 -741.747)">
        <path
          d="M1110.363,315.533l-.993-.165a.228.228,0,0,1-.183-.169,4.468,4.468,0,0,0-.471-1.146.227.227,0,0,1,.014-.251l.6-.8a.226.226,0,0,0-.021-.3l-.837-.838a.226.226,0,0,0-.292-.024l-.819.586a.226.226,0,0,1-.248.01,4.484,4.484,0,0,0-1.143-.478.227.227,0,0,1-.168-.188l-.137-.989a.226.226,0,0,0-.224-.195h-1.184a.226.226,0,0,0-.223.189l-.165.993a.227.227,0,0,1-.168.182,4.47,4.47,0,0,0-1.146.471.227.227,0,0,1-.251-.014l-.8-.6a.226.226,0,0,0-.3.021l-.837.838a.226.226,0,0,0-.024.291l.586.819a.227.227,0,0,1,.01.248,4.473,4.473,0,0,0-.479,1.143.227.227,0,0,1-.188.168l-.989.137a.226.226,0,0,0-.195.224v1.184a.226.226,0,0,0,.189.223l.993.165a.228.228,0,0,1,.183.168,4.462,4.462,0,0,0,.471,1.146.226.226,0,0,1-.014.251l-.6.8a.226.226,0,0,0,.021.3l.837.837a.226.226,0,0,0,.292.024l.819-.586a.227.227,0,0,1,.248-.01,4.469,4.469,0,0,0,1.143.478.227.227,0,0,1,.168.188l.137.989a.226.226,0,0,0,.224.2h1.184a.226.226,0,0,0,.223-.189l.165-.993a.227.227,0,0,1,.168-.182,4.478,4.478,0,0,0,1.146-.471.227.227,0,0,1,.251.014l.8.6a.226.226,0,0,0,.3-.02l.838-.838a.226.226,0,0,0,.024-.292l-.586-.819a.227.227,0,0,1-.01-.248,4.479,4.479,0,0,0,.479-1.143.227.227,0,0,1,.188-.168l.989-.137a.226.226,0,0,0,.2-.224v-1.184a.226.226,0,0,0-.189-.223m-5.539,3.145a2.358,2.358,0,1,1,2.358-2.358,2.358,2.358,0,0,1-2.358,2.358"
          transform="translate(-267.536 444.343)"
        />
        <path
          d="M1119.841,332.812a1.475,1.475,0,1,0-1.475-1.475,1.477,1.477,0,0,0,1.475,1.475"
          transform="translate(-282.552 429.327)"
        />
        <path
          d="M1063.666,272.067a5.466,5.466,0,0,1-.231-.563l-.59-.1a1.105,1.105,0,0,1-.927-1.094v-1.184a1.113,1.113,0,0,1,.956-1.1l.575-.08a5.363,5.363,0,0,1,.235-.561l-.348-.486a1.105,1.105,0,0,1,.118-1.43l.837-.837a1.1,1.1,0,0,1,.784-.325,1.116,1.116,0,0,1,.669.225l.463.35a5.306,5.306,0,0,1,.563-.23l.1-.59a1.1,1.1,0,0,1,.15-.4,9.868,9.868,0,0,0-1.781-.749,6.392,6.392,0,1,0-5.807,0,9.859,9.859,0,0,0-6.907,8.467.724.724,0,0,0,.652.789h10.406Z"
          transform="translate(-231.24 490.916)"
        />
      </g>
    </SvgIcon>
  );
};

export default React.memo(UserSettingsIcon);
