import {Box, FormControl, FormHelperText} from '@mui/material';
import React, {ChangeEvent, FC, InputHTMLAttributes, memo, useRef} from 'react';
import {Controller, Message, ValidationRule, useFormContext} from 'react-hook-form';
import {primaryTextColor} from 'styles/color-constants';
import {theme} from '../../../../styles/theme';
import {BasicButton} from '../buttons/basic-button';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  name: string;
  label: string;
  onChange: (image: ChangeEvent<HTMLInputElement>) => void;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    disabled: boolean;
  }>;
  errors?: string;

  isSuccess?: boolean;
}

export const ImageInput: FC<Props> = memo((props) => {
  const {name, validators, isSuccess, onChange, label, errors: parentErrors, ...rest} = props;
  const [fileName, setFileName] = React.useState('');
  const inputRef = useRef<HTMLElement | null>();
  const {
    control,
    formState: {errors},
    setError,
  } = useFormContext();

  React.useEffect(() => {
    if (isSuccess) {
      setFileName('');
    }
  }, [isSuccess]);

  const imageInputErrors = parentErrors || errors[name]?.message;

  const onFileChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e?.currentTarget?.files && e.currentTarget.files.length > 0) {
        setError(name, {
          message: '',
        });
        setFileName(e.currentTarget.files[0].name);
      }
      onChange(e);
    },
    [name, onChange, setError],
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={validators}
      render={({field}) => (
        <FormControl sx={{marginTop: 1, marginBottom: 2}}>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <BasicButton
              variant="outlined"
              sx={{color: primaryTextColor}}
              onClick={() => inputRef.current?.click()}
              text={
                <>
                  {label}
                  <input
                    type="file"
                    {...field}
                    ref={(e) => {
                      field.ref(e);
                      inputRef.current = e;
                    }}
                    style={{display: 'none'}}
                    onChange={(e) => {
                      field.onChange(e);
                      onFileChange(e);
                    }}
                    value={field.value || ''}
                    name={name}
                  />
                </>
              }
            />
            {!imageInputErrors && fileName && <FormHelperText>{fileName}</FormHelperText>}
          </Box>
          {imageInputErrors && (
            <FormHelperText sx={{color: theme.palette.error.main}}>{imageInputErrors}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
});
