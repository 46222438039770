import {Box, Button, SxProps, Theme} from '@mui/material';
import React, {FC, ReactNode, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {primaryTextColor} from 'styles/color-constants';
import {theme} from '../../../../styles/theme';

interface PropsFromParent {
  text: string | ReactNode;
  onClick?: (e: any) => void;
  icon?: React.ReactNode;
  containerClasses?: any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  sx?: SxProps<Theme>;
  variant?: 'text' | 'outlined' | 'contained';
  backButton?: boolean;
}

type Props = PropsFromParent;

export const BasicButton: FC<Props> = React.memo(
  ({
    icon,
    onClick,
    text,
    containerClasses,
    disabled,
    type,
    buttonColor = 'primary',
    sx,
    variant = 'contained',
    backButton,
  }) => {
    const navigate = useNavigate();
    const onClickLocal = useCallback(
      (e: any) => {
        if (onClick) {
          onClick(e);
        }
        if (backButton) {
          navigate(-1);
        }
      },
      [backButton, navigate, onClick],
    );

    return (
      <Box className={containerClasses}>
        <Button
          variant={variant}
          sx={{color: backButton ? primaryTextColor : theme.colors.button.btnTextColor, ...sx}}
          type={type}
          disabled={disabled}
          onClick={onClickLocal}
          color={buttonColor}
          startIcon={icon}>
          {text}
        </Button>
      </Box>
    );
  },
);
