import {Grid} from '@mui/material';
import {useLayoutStyles} from 'app-routes';
import {useAuth} from 'auth/auth-provider';
import {FC, useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {routes} from 'state/routes';
import {OverlaySpinner} from 'view/components/default-components/spinner/overlay-spinner';
import {AppDrawer} from 'view/navigation/app/app-drawer';
import {AppHeaderBar} from 'view/navigation/app/app-header-bar';
import {MenuDrawer} from 'view/navigation/desktop/menu-drawer';

export const AppLayout: FC = () => {
  const classes = useLayoutStyles();
  const {isAuthenticated, loading} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    // VERY IMPORTANT TO NOT NAVIGATE BEFORE USER IS AUTHENTICATED
    if (location.pathname === routes.root && !loading && isAuthenticated === true) {
      navigate(routes.machines);
    }
  }, [location.pathname, navigate, isAuthenticated, loading]);

  return (
    <div>
      {isAuthenticated && <AppHeaderBar />}
      <Grid wrap="nowrap" container={true}>
        {isAuthenticated && <MenuDrawer />}
        <OverlaySpinner />
        <div className={classes.appContent}>
          <Outlet />
        </div>
      </Grid>

      <div className={classes.mobileOnly}>{isAuthenticated && <AppDrawer />}</div>
    </div>
  );
};
