import {Typography} from '@mui/material';
import {GridCallbackDetails, GridColumns, GridSortModel} from '@mui/x-data-grid';
import {CleaningType, SortOrder} from 'api/api';
import {localized} from 'i18n/i18n';
import {FC, memo, useCallback, useMemo, useState} from 'react';
import {useGetCleaningHistoryQuery} from 'state/features/api/machines-history-slice';
import {HistoryDataGrid, defaultRowsPerPage} from 'view/components/specific-components/data-grid/history-data-grid';
import {
  PLC_FWcleaningColumns,
  laserCleaningColumns,
  pwCleaningColumns,
  standardCleaningColumns,
} from './cleaning-columns';

interface Props {
  machineId: number;
}
type WithoutUnknown = Exclude<CleaningType, CleaningType.Unknown>;
type CleaningColumnMapper = Record<WithoutUnknown, GridColumns>;

const cleaningColumnMapper: CleaningColumnMapper = {
  [CleaningType.Laser]: laserCleaningColumns,
  [CleaningType.PLC_FW]: PLC_FWcleaningColumns,
  [CleaningType.Standard]: standardCleaningColumns,
  [CleaningType.PW]: pwCleaningColumns,
};

const sortingMapper: Record<'asc' | 'desc', SortOrder> = {
  asc: SortOrder.Asc,
  desc: SortOrder.Desc,
};

export const CleaningHistory: FC<Props> = memo((props) => {
  const {machineId} = props;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultRowsPerPage);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'date',
      sort: 'desc',
    },
  ]);

  const queryOptions = useMemo(() => {
    let sortOrder = sortModel[0]?.sort ? sortingMapper[sortModel[0].sort] : SortOrder.Desc;
    return {
      page,
      pageSize,
      sortField: sortModel[0]?.field ?? 'date',
      sortOrder: sortOrder,
    };
  }, [page, pageSize, sortModel]);

  const {data, isFetching} = useGetCleaningHistoryQuery({
    pageNumber: queryOptions.page,
    pageSize: queryOptions.pageSize,
    machineId: machineId,
    sortField: queryOptions.sortField,
    sortOrder: queryOptions.sortOrder,
  });

  const {pagedCleaningHistory, cleaningType} = data ?? {};

  const onPageSizeChange = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
    },
    [setPageSize],
  );

  const onPageChange = useCallback((pageNumber: number) => {
    setPage(pageNumber);
  }, []);

  const onSortModelChange = (model: GridSortModel, details: GridCallbackDetails<any>) => {
    setSortModel(model);
  };

  const columns = useMemo(() => {
    if (cleaningType === undefined || cleaningType === CleaningType.Unknown) return [];
    return cleaningColumnMapper[cleaningType];
  }, [cleaningType]);

  if (cleaningType === CleaningType.Unknown) {
    return <Typography variant="body2">{localized('CleaningTypeUnknown')}</Typography>;
  }

  return (
    <HistoryDataGrid
      initialState={{
        sorting: {
          sortModel: sortModel,
        },
      }}
      loading={isFetching}
      rows={pagedCleaningHistory?.data ?? []}
      page={page}
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      paginationMode="server"
      sortingMode="server"
      rowCount={pagedCleaningHistory?.totalItems ?? 0}
      columns={columns}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      pagination
    />
  );
});
