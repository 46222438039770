import ClearIcon from '@mui/icons-material/Clear';
import {Chip, SxProps} from '@mui/material';
import React, {FC, memo, useCallback} from 'react';
import {blackColor, whiteColor} from '../../../../styles/color-constants';
import {useFilterPanelContextInternal} from '../page-header.tsx/page-header-filter';

interface Props {
  field: string;
  value: string;
}

export const FilterPanelChip: FC<Props> = memo(({field, value}) => {
  const {selectedValues, setSelectedValues} = useFilterPanelContextInternal();

  const onDelete = useCallback(() => {
    if (selectedValues[field] === undefined) return;
    const copy = {...selectedValues};
    copy[field] = copy[field].filter((v) => v !== '' + value);
    setSelectedValues(copy);
  }, [field, selectedValues, setSelectedValues, value]);

  return <Chip label={value} sx={chipSx} onDelete={onDelete} deleteIcon={<ClearIcon />} />;
});

const chipSx: SxProps = {
  backgroundColor: blackColor,
  color: whiteColor,
  '& .MuiChip-deleteIcon': {
    color: whiteColor,
    fontSize: 16,
    marginRight: 1,
    marginTop: 0.1,
  },
};
