import styled from '@emotion/styled';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Stack, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {AlarmSeverity, ConnectionState, IMachineDTO} from 'api/api';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {alarmColor, warningColor} from 'styles/color-constants';
import BlobImage from 'view/components/default-components/blob-image/blob-image';
import CurrentState from './current-state';
interface Props {
  machine: IMachineDTO;
}

const MachineRoot = styled.div`
  height: 100%;
  background-color: ${({theme}) => theme.palette.common.blue50};
  cursor: pointer;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: ${({theme}) => theme.shadows[5]};
  }
`;

export const Machine: React.FC<Props> = (props) => {
  const {machine} = props;

  const iconColor: string = useMemo(
    () => (machine.currentAlarms?.some((a) => a.alarmSeverity === AlarmSeverity.Alarm) ? alarmColor : warningColor),
    [machine.currentAlarms],
  );

  const showIcon = useMemo(
    () => machine.currentAlarms?.length && machine.currentState?.connectionState === ConnectionState.Online,
    [machine.currentAlarms?.length, machine.currentState?.connectionState],
  );
  return (
    <MachineRoot>
      <Link to={machine?.id.toString()} style={{textDecoration: 'none'}}>
        {showIcon ? (
          <WarningAmberIcon sx={{position: 'relative', top: 20, left: 24, fontSize: 40, color: iconColor}} />
        ) : (
          <></>
        )}
        <Stack padding={3} direction="column" height="100%" marginTop={showIcon ? -6 : 0}>
          <Box margin={'auto'}>
            <BlobImage
              alt={`Serialnumber: ${machine.serialNumber}`}
              src={`/images/machines/${machine.machineTypeImageUrl}`}
              maxWidth="236px"
              minHeight="200px"
            />
          </Box>

          <Stack textAlign="center" marginTop={4}>
            <Typography color="common.flexoWashBlue" variant="h4">
              {machine.machineTypeName}
            </Typography>
            <Typography variant="body3" color="common.flexoWashBlueLight">
              #{machine.serialNumber}
            </Typography>
          </Stack>

          <Box marginTop={5} width="100%">
            {machine.currentState && <CurrentState currentState={machine.currentState} />}
          </Box>
        </Stack>
      </Link>
    </MachineRoot>
  );
};
