import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useGetUsersQuery} from 'state/features/api/user-slice';
import {useAuth} from '../../../auth/auth-provider';
import {localized, localizedInterpolation} from '../../../i18n/i18n';
import {selectPageSettingByKey} from '../../../state/features/page-header-setting/page-header-setting-slice';
import {ViewType} from '../../../state/features/page-header-setting/types';
import {routes} from '../../../state/routes';
import {BasicButton} from '../../components/default-components/buttons/basic-button';
import {FlexColumn} from '../../components/default-components/flex-column';
import {FlexRow} from '../../components/default-components/flex-row';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../components/default-components/page-header.tsx/page-header-content';
import {PageHeaderSearch} from '../../components/default-components/page-header.tsx/page-header-search';
import {PageHeaderToggleView} from '../../components/default-components/page-header.tsx/page-header-toggle-view';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {BasicSpinner} from '../../components/default-components/spinner/basic-spinner';
import {UserManagementBoxView} from './user-management-box/user-management-box-view';
import {UserManagementListView} from './user-management-list/user-management-list-view';

export const UserManagement: FC = React.memo(() => {
  const navigate = useNavigate();
  const {isAdmin} = useAuth();
  const {isLoading: showSpinner} = useGetUsersQuery();
  const pageSetting = useSelector(selectPageSettingByKey('userManagement'));

  const goToCreateUser = useCallback(() => {
    navigate(routes.userManagementCreate);
  }, [navigate]);

  return (
    <PageTitle title={localized('UserManagement')}>
      <PageHeader area="userManagement" title={localized('UserManagement')}>
        <PageHeaderContent>
          <FlexColumn>
            <FlexRow flexWrap="wrap" rowGap={1}>
              <PageHeaderSearch />
              <FlexRow flex={1}>
                <PageHeaderToggleView />
              </FlexRow>
            </FlexRow>
          </FlexColumn>
          {isAdmin && <BasicButton onClick={goToCreateUser} text={localizedInterpolation('CreateNewInt', 'User')} />}
        </PageHeaderContent>
      </PageHeader>
      <PageContentContainer xy>
        {showSpinner ? (
          <BasicSpinner />
        ) : !pageSetting || pageSetting.itemView === ViewType.BoxView ? (
          <UserManagementBoxView />
        ) : pageSetting.itemView === ViewType.ListView ? (
          <UserManagementListView />
        ) : null}
      </PageContentContainer>
    </PageTitle>
  );
});
