import {SxProps, Theme} from '@mui/material';
import {TankLevel} from 'api/api';
import {ElementType, FC} from 'react';
import {TankStatusBelowOkIcon} from '../icons/tank-status-below-ok-icon/tank-status-below-ok-icon';
import { TankStatusLevelOkIcon } from '../icons/tank-status-level-ok-icon/tank-status-level-ok-icon';
import { TankStatusMaximumLevelIcon } from '../icons/tank-status-maximum-level-icon/tank-status-maximum-level-icon';
import { TankStatusMinimumLevelIcon } from '../icons/tank-status-minimum-level-icon/tank-status-minimum-level-icon';

type TankLevelMapper = Record<TankLevel, ElementType>;
interface Props {
  tankLevel: TankLevel;
  sx?: SxProps<Theme> | undefined;
}

const tankLevelMapper: TankLevelMapper = {
  [TankLevel.BelowOk]: TankStatusBelowOkIcon,
  [TankLevel.LevelOk]: TankStatusLevelOkIcon,
  [TankLevel.MaximumLevel]: TankStatusMaximumLevelIcon,
  [TankLevel.MinimumLevel]: TankStatusMinimumLevelIcon,
};

const TankStatusLevel: FC<Props> = (props) => {
  const {tankLevel, sx} = props;

  const TankLevel = tankLevelMapper[tankLevel];

  return <TankLevel sx={sx} />;
};

export default TankStatusLevel;
