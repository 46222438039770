import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthState} from './types';

export const initialState: AuthState = {
  accessToken: '',
  pending: false,
  initLogin: false,
  initLoginSilent: false,
  initLogout: false,
  initLogoutSilent: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setAuthPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    // ONLY FOR API HELPER. DO NOT USE IN COMPONENT
    initLogin: (state, action: PayloadAction<boolean>) => {
      state.initLogin = action.payload;
    },
    initLoginSilent: (state, action: PayloadAction<boolean>) => {
      state.initLoginSilent = action.payload;
    },
    // ONLY FOR API HELPER. DO NOT USE IN COMPONENT
    initLogout: (state, action: PayloadAction<boolean>) => {
      state.initLogout = action.payload;
    },
    initLogoutSilent: (state, action: PayloadAction<boolean>) => {
      state.initLogoutSilent = action.payload;
    },
  },
});

export const {setAccessToken, setAuthPending, initLogin, initLoginSilent, initLogout, initLogoutSilent} = authSlice.actions;

export default authSlice.reducer;
