import {Accordion, AccordionDetails, AccordionSummary, Box, SxProps} from '@mui/material';
import dayjs from 'dayjs';
import React, {useCallback, useMemo} from 'react';
import {primaryColor, whiteColor} from '../../../../styles/color-constants';
import {genericMemo} from '../../../../utilities/generic-memo';
import {FlexColumn} from '../flex-column';
import {FlexRow} from '../flex-row';
import {FilterPanelOption} from './filter-panel-option';
import {FilterConfig} from './filter-panel-types';
import {useFilterPanelContextInternal} from '../page-header.tsx/page-header-filter';
import {ArrowDownward} from '@mui/icons-material';

interface Props<T> {
  heightOffset: number;
  activePanel?: string;
  onOpen(panel?: string): void;
  filterConfig: FilterConfig<T>;
  options: (T[keyof T] | string)[];
}

export const FilterPanelRow = genericMemo(<T,>(props: Props<T>) => {
  const {filterConfig, options, activePanel, heightOffset, onOpen} = props;
  const expanded = useMemo(() => activePanel === filterConfig.field, [activePanel, filterConfig.field]);
  const {selectedValues} = useFilterPanelContextInternal();

  const categoryCount = useMemo(() => {
    return selectedValues[filterConfig.field.toString()]?.length;
  }, [filterConfig.field, selectedValues]);

  const optionElements = useMemo(() => {
    return (filterConfig.options ?? options)
      .filter((value) => value !== undefined)
      .sort((a, b) => ('' + a).localeCompare('' + b, dayjs().locale()))
      .map((o, index) => <FilterPanelOption key={index} filterConfig={filterConfig} value={o} />);
  }, [filterConfig, options]);

  const onChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      onOpen(isExpanded ? panel : undefined);
    },
    [onOpen],
  );

  return (
    <Accordion
      disableGutters
      sx={localStyles.accordion}
      key={filterConfig.field.toString()}
      expanded={expanded}
      onChange={onChange(filterConfig.field.toString())}>
      <AccordionSummary expandIcon={<ArrowDownward />}>
        <FlexRow alignItems={'center'} gap={0.5}>
          <Box sx={{fontWeight: expanded ? 'bold' : undefined}}>{filterConfig.header}</Box>
          {categoryCount > 0 ? <Box sx={localStyles.filterCount}>{categoryCount}</Box> : <></>}
        </FlexRow>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          maxHeight: `calc(100vh - 240px - ${heightOffset}px)`,
          minHeight: optionElements.length < 5 ? optionElements.length * 50 : 180,
          overflowY: 'auto',
        }}>
        <FlexColumn gap={0.5}>{optionElements}</FlexColumn>
      </AccordionDetails>
    </Accordion>
  );
});

const localStyles: {[key: string]: SxProps} = {
  accordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  filterCount: {
    backgroundColor: primaryColor,
    color: whiteColor,
    borderRadius: 30,
    height: 24,
    width: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
