import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {AppBar, Theme, Toolbar, styled} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FlexoWashLogo from 'assets/images/flexo_wash_logo.svg';
import clsx from 'clsx';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {useLayoutStyles} from '../../../app-routes';
import {useAuth} from '../../../auth/auth-provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: '70px',
    },
    logOutIcon: {
      color: theme.palette.text.primary,
      position: 'absolute',
      right: '10px',
    },
  }),
);

const LogoLink = styled(Link)`
  display: flex;
`;

export const AppHeaderBar: FC = React.memo((props) => {
  const {logout, isAuthenticated} = useAuth();
  const logOut = () => logout();
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();

  return (
    <div className={clsx(classes.container, layoutClasses.mobileOnly)}>
      <AppBar position="fixed">
        <Toolbar>
          <LogoLink to="/">
            <img width={150} src={FlexoWashLogo} alt="FLexowash Logo" className="logo" />
          </LogoLink>

          {isAuthenticated && <ExitToAppIcon onClick={logOut} width={40} className={classes.logOutIcon} height={40} />}
        </Toolbar>
      </AppBar>
    </div>
  );
});
