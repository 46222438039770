import {Box, styled} from '@mui/material';
import {DataGrid, DataGridProps, GridCallbackDetails, GridColumns} from '@mui/x-data-grid';
import {localized, localizedDynamic} from 'i18n/i18n';
import React, {FC, useCallback, useEffect} from 'react';
import {headerBackgroundColor} from 'styles/color-constants';

const CustomThemeDataGrid = styled(DataGrid)(({theme}) => ({
  border: 0,
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-headerSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 0,
    // 'max-height': '20px',
  },
  '& .MuiDataGrid-cell': {
    // 'padding-right': '30px',
    '&:focus': {
      outline: 'none',
    },
  },
}));

interface Props extends DataGridProps {}

export const defaultRowsPerPage = 10;

const getPageOptions = (numberOfRows: number | undefined): number[] => {
  if (numberOfRows === undefined || numberOfRows < defaultRowsPerPage) {
    return [10];
  }
  if (numberOfRows < 20) {
    return [5, 10, numberOfRows];
  }
  return [5, 10, 20];
};

export const HistoryDataGrid: FC<Props> = React.memo((props) => {
  const {columns, onPageSizeChange: onPageSizeChangeProp, rowCount, ...rest} = props;
  const [pageSize, setPageSize] = React.useState(defaultRowsPerPage);
  const [dataGridColumns, setDataGridColumns] = React.useState(columns);

  // If language changes, we need to localize the headernames to the new langauge
  useEffect(() => {
    const cols: GridColumns = [];

    for (const col of columns) {
      const newCol = {...col};
      newCol['headerName'] = col.headerName ? localizedDynamic(col.headerName) : col.headerName;
      cols.push(newCol);
    }

    setDataGridColumns(cols);
  }, [columns]);

  const onPageSizeChange = useCallback(
    (pageSize: number, details: GridCallbackDetails<any>) => {
      setPageSize(pageSize);
      onPageSizeChangeProp && onPageSizeChangeProp(pageSize, details);
    },
    [onPageSizeChangeProp],
  );

  return (
    <Box
      sx={{
        height: `calc(108px + ${pageSize * (52 + 10)}px)`,
        width: '100%',
        '& .header': {
          fontSize: 11,
          border: 'none',
          color: 'common.flexoWashBlueLight',
        },
        '& .row': {
          bgcolor: headerBackgroundColor,
          marginTop: '10px',
        },
      }}>
      <CustomThemeDataGrid
        headerHeight={16}
        disableColumnMenu
        disableSelectionOnClick={true}
        columns={dataGridColumns}
        getRowClassName={(params) => 'row'}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={getPageOptions(rowCount)}
        rowCount={rowCount ?? 0}
        localeText={{
          noRowsLabel: localized('NoRows'),
          MuiTablePagination: {
            labelDisplayedRows: ({from, to, count}) => `${from} - ${to} ${localized('Of')} ${count}`,
            labelRowsPerPage: `${localized('RowsPerPage')}:`,
            // noRowsLabel: 'Nod dsadsa'
          },
        }}
        {...rest}
      />
    </Box>
  );
});
