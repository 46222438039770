import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const UserIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19.486 21.2">
      <path
        d="M19.483,20.416a9.793,9.793,0,0,0-6.856-8.409,6.348,6.348,0,1,0-5.767,0A9.792,9.792,0,0,0,0,20.416a.719.719,0,0,0,.662.785H18.82a.719.719,0,0,0,.666-.715c0-.023,0-.046,0-.069"
        transform="translate(0 -0.001)"
      />
    </SvgIcon>
  );
};

export default React.memo(UserIcon);
