import {useAuth} from 'auth/auth-provider';
import {localized, localizedInterpolation} from 'i18n/i18n';
import {FC, memo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {selectAgentById} from 'state/features/api/agents-slice';
import {useGetUsersQuery} from 'state/features/api/user-slice';
import {selectPageSettingByKey} from 'state/features/page-header-setting/page-header-setting-slice';
import {routes} from 'state/routes';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {FlexColumn} from 'view/components/default-components/flex-column';
import {FlexRow} from 'view/components/default-components/flex-row';
import PageContentContainer from 'view/components/default-components/page-container/page-content-container';
import {PageHeader} from 'view/components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from 'view/components/default-components/page-header.tsx/page-header-content';
import {PageHeaderSearch} from 'view/components/default-components/page-header.tsx/page-header-search';
import {PageTitle} from 'view/components/default-components/page-title/page-title';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import {AgentUsersListView} from './agent-users-list-view/agent-users-list-view';

export const AgentUsers: FC = memo(() => {
  const navigate = useNavigate();
  const {isGlobalAdmin} = useAuth();
  const {agentId} = useParams<{agentId: string}>();
  const agent = useSelector(selectAgentById(Number(agentId)));
  const pageSetting = useSelector(selectPageSettingByKey('agentUsers'));
  const {isLoading: showSpinner} = useGetUsersQuery();

  const goToCreateAgentUser = useCallback(() => {
    navigate(`${routes.agent}/${agentId}/users/create`);
  }, [agentId, navigate]);

  return (
    <PageTitle title={localized('AgentUsers')}>
      <PageHeader
        area="agentUsers"
        title={agent !== undefined ? `${agent.name} - ${localized('Users')}` : localized('AgentUsers')}>
        <PageHeaderContent>
          <FlexColumn>
            <FlexRow flexWrap="wrap">
              <PageHeaderSearch />
            </FlexRow>
          </FlexColumn>
          {isGlobalAdmin && (
            <BasicButton onClick={goToCreateAgentUser} text={localizedInterpolation('CreateNewInt', 'User')} />
          )}
        </PageHeaderContent>
      </PageHeader>
      <PageContentContainer xy>{showSpinner ? <BasicSpinner /> : <AgentUsersListView />}</PageContentContainer>
    </PageTitle>
  );
});
