import {FC, memo} from 'react';
import {Outlet} from 'react-router-dom';
import {useAuth} from './auth-provider';
import {NoPermissionPage} from './no-permission-page';

// Redirect to start page, if somehow a normal user access an admin page
export const AdminRoute: FC = memo(() => {
  const {isAdmin, loading} = useAuth();

  // The default state of isAdmin is undefined, which is when the user has not been validated yet
  // Therefore we do not render the Outlet or the NoPermissionsPage
  if (isAdmin === undefined) return null;

  // Validation has been performed and the user has no access, so show NoPermissionsPage
  if (isAdmin === false && !loading) {
    return <NoPermissionPage />;
  }

  // Return the outlet if the user is admin.
  return <Outlet />;
});
