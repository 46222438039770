import React, {FC} from 'react';
import {useGetOrganizationsQuery} from 'state/features/api/organization-slice';
import {localized} from '../../../../i18n/i18n';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {FlexRow} from '../../../components/default-components/flex-row';
import {PageContentContainer} from '../../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../../components/default-components/page-header.tsx/page-header-content';
import {PageTitle} from '../../../components/default-components/page-title/page-title';
import {OrganizationForm} from './organization-form';

export const CreateOrganization: FC = React.memo(() => {
  useGetOrganizationsQuery();

  return (
    <PageTitle title={localized('CreateOrganization')}>
      <PageHeader area="userCreate" title={localized('CreateOrganization')}>
        <PageHeaderContent></PageHeaderContent>
      </PageHeader>
      <PageContentContainer xy>
        <FlexRow height={1} maxWidth={700}>
          <FlexColumn flex={1}>
            <OrganizationForm />
          </FlexColumn>
        </FlexRow>
      </PageContentContainer>
    </PageTitle>
  );
});
