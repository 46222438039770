import {Autocomplete, Box, TextField} from '@mui/material';
import {IAgentDTO, IMachineDTO} from 'api/api';
import {localized, localizedInterpolation} from 'i18n/i18n';
import {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {selectAgentById} from 'state/features/api/agents-slice';
import {
  selectMachineDropdownOptionsByAgentId,
  selectMachineNameMap,
  selectMachinesByIds,
} from 'state/features/api/machines-slice';
import {selectOrganizations} from 'state/features/api/organization-slice';
import {nameof} from 'utilities/platform-helpers/nameof-helper';
import {ColumnDef} from 'view/components/default-components/list-table/list-table-types';
import {VirtualizedListTable} from 'view/components/default-components/list-table/virtual-list-table';
import {CreateAgentListRemoveCell} from './create-agent-list-remove-cell';

const tableHeight = 'calc(100vh - 455px)';
export const CreateAgentMachineList: FC = memo(() => {
  const organizations = useSelector(selectOrganizations);
  const columns: ColumnDef<IMachineDTO>[] = [
    {
      field: 'serialNumber',
      header: localized('SerialNumber'),
      maxWidth: 150,
      valueFormatter: (machine) => `#${machine.serialNumber}`,
    },
    {field: 'machineTypeName', header: localized('MachineType')},
    {
      field: 'organizationId',
      valueFormatter: (machine) => organizations.find((org) => org.id === machine.organizationId)?.name ?? '',
      header: localized('CustomerName'),
    },
    {
      field: 'id',
      header: localized('Remove'),
      sortable: false,
      maxWidth: 80,
      customComponent: (machine) => <CreateAgentListRemoveCell machine={machine} />,
    },
  ];
  const {watch, setValue, getValues} = useFormContext();
  const {agentId} = useParams<{agentId: string}>();
  const agent = useSelector(selectAgentById(Number(agentId)));
  const machineMap = useSelector(selectMachineNameMap);
  const machineOptions = useSelector(selectMachineDropdownOptionsByAgentId(Number(agentId)));
  const machineIds: number[] | undefined = watch(nameof<IAgentDTO>('machineIds'));
  const machines = useMemo(() => machineIds ?? [], [machineIds]);

  const selectedMachines = useSelector(selectMachinesByIds(machineIds ?? []));

  const [inputValue, setInputValue] = useState<string>('');
  const [selectValue, setSelectValue] = useState<number | null>(null);

  const filteredOptions = useMemo(
    () => machineOptions.filter((m) => !machines.includes(m)),
    [machineOptions, machines],
  );

  useEffect(() => {
    setValue(nameof<IAgentDTO>('machineIds'), agent?.machineIds);
  }, [agent?.machineIds, setValue]);

  const getMachineName = useCallback((machineId: unknown) => machineMap.get(machineId as number) ?? '-', [machineMap]);

  const handleMachineSelect = useCallback(
    (event, value: number | null) => {
      if (!value) return;
      const currentMachines = getValues(nameof<IAgentDTO>('machineIds'));
      setValue(nameof<IAgentDTO>('machineIds'), [...currentMachines, value]);
      setTimeout(() => {
        setSelectValue(null);
        setInputValue('');
      }, 0);
    },
    [getValues, setValue],
  );

  const handleAutocompleteInputChange = useCallback((event, newValue: string) => {
    setInputValue(newValue);
  }, []);

  return (
    <>
      <Box maxWidth={400}>
        <Autocomplete
          options={filteredOptions}
          value={selectValue}
          inputValue={inputValue}
          getOptionLabel={getMachineName}
          onChange={handleMachineSelect}
          onInputChange={handleAutocompleteInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={localizedInterpolation('SearchByInt', 'SerialNumber')}
              InputLabelProps={{shrink: true}}
              sx={{mb: 2}}
            />
          )}
        />
      </Box>
      <VirtualizedListTable<IMachineDTO>
        height={tableHeight}
        rows={selectedMachines}
        columns={columns}
        defaultOrderBy="serialNumber"
      />
    </>
  );
});
