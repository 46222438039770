import {Box} from '@mui/material';
import {IUserDTO, UserDTO, UserRole} from 'api/api';
import {localized, localizedDynamic} from 'i18n/i18n';
import {useDialog} from 'providers/dialog-provider';
import React, {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {selectUserByAgentId, useDeleteUserMutation} from 'state/features/api/user-slice';
import {selectPageSettingByKey} from 'state/features/page-header-setting/page-header-setting-slice';
import {routes} from 'state/routes';
import {defaultVirtualizedListTableHeight} from 'styles/styling-constants';
import {filterListItems} from 'utilities/helpers/general-helpers';
import {ActionConfig, ColumnDef} from 'view/components/default-components/list-table/list-table-types';
import {UserResendInvitationAction} from 'view/pages/user-management/user-management-list/user-resend-invitation-action';
import {VirtualizedListTable} from 'view/components/default-components/list-table/virtual-list-table';

const columns: ColumnDef<IUserDTO>[] = [
  {field: 'name', header: localized('Name')},
  {field: 'role', header: localized('UserRole'), valueFormatter: (user) => localizedDynamic(UserRole[user.role])},
  {field: 'email', header: localized('Email')},
];

export const AgentUsersListView: FC = React.memo(() => {
  const pageSetting = useSelector(selectPageSettingByKey('agentUsers'));
  const {agentId} = useParams<{agentId: string}>();
  const filteredUsers = useSelector(selectUserByAgentId(Number(agentId)));
  const navigate = useNavigate();
  const [deleteUser] = useDeleteUserMutation();
  const {openDeleteDialog} = useDialog();

  const onEditClick = useCallback(
    (user: IUserDTO) => {
      navigate(`${routes.agent}/${user?.agentId}/users/${user.id}/edit`);
    },
    [navigate],
  );

  const handleDialogDelete = useCallback(
    (user: IUserDTO) => {
      deleteUser(user as UserDTO);
    },
    [deleteUser],
  );

  const onDeleteClick = useCallback(
    (user: IUserDTO) => {
      openDeleteDialog(localized('ConfirmDeleteAgent'), () => handleDialogDelete(user));
    },
    [handleDialogDelete, openDeleteDialog],
  );

  const items = useMemo(() => {
    return filterListItems(filteredUsers, ['name'], pageSetting?.searchString);
  }, [filteredUsers, pageSetting]);

  const actions: ActionConfig<IUserDTO>[] = useMemo(
    () => [
      {
        icon: (user) => <UserResendInvitationAction user={user} />,
        label: localized('ResendInvitation'),
      },
    ],
    [],
  );
  return (
    <Box>
      <VirtualizedListTable<IUserDTO>
        height={defaultVirtualizedListTableHeight}
        rows={items}
        columns={columns}
        defaultOrderBy={'name'}
        actionHeader={localized('Edit')}
        onEdit={onEditClick}
        onDelete={onDeleteClick}
        actions={actions}
      />
    </Box>
  );
});
