import {SxProps, Theme} from '@mui/material';
import { TankTempStatus} from 'api/api';
import {ElementType, FC} from 'react';
import { TankStatusMaxTemperatureIcon } from '../icons/tank-status-max-temperature-icon/tank-status-max-temperature-icon';
import { TankStatusMinTemperatureIcon } from '../icons/tank-status-min-temperature-icon/tank-status-min-temperature-icon';
import { TankStatusTemperatureOkIcon } from '../icons/tank-status-temperature-ok-icon/tank-status-temperature-ok-icon';

type TankTemperatureMapper = Record<TankTempStatus, ElementType>;
interface Props {
  tempStatus: TankTempStatus;
  sx?: SxProps<Theme> | undefined;
}

const tankTemperatureMapper: TankTemperatureMapper = {
  [TankTempStatus.MaximumTemperature]: TankStatusMaxTemperatureIcon,
  [TankTempStatus.MinimumTemperature]: TankStatusMinTemperatureIcon,
  [TankTempStatus.TemperatureOk]: TankStatusTemperatureOkIcon,
};

const TankStatusTemperature: FC<Props> = (props) => {
  const {tempStatus, sx} = props;

  const TankTemperature = tankTemperatureMapper[tempStatus];

  return <TankTemperature sx={sx} />;
};

export default TankStatusTemperature;
