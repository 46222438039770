import {Box} from '@mui/material';
import React, {HTMLProps, forwardRef, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {AutoSizer} from 'react-virtualized';
import {FixedSizeList, ListChildComponentProps, ListItemKeySelector} from 'react-window';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {genericMemo} from '../../../../utilities/generic-memo';
import {createListTableContext} from './list-table';
import {ListTableHeader} from './list-table-header';
import {ListTableRow} from './list-table-row';
import {IdBase, IdBaseType, ListTablePropsBase, Order} from './list-table-types';
import {
  VirutalListItemSize,
  appendActionColumn,
  filterRows,
  getActionCount,
  getSkeletonElements,
  sortRows,
} from './list-table-util';

export const VirtualizedListTable = genericMemo(<T extends IdBase>(props: ListTablePropsBase<T>) => {
  const ListTableContext = createListTableContext<T>();
  const pageSetting = useSelector(selectPageSettingByKey(props.pageSettingKey));
  const [order, setOrder] = useState<Order>(props.defaultOrderDirection ?? 'asc');
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(props.defaultOrderBy);
  const [selectedIds, setSelectedIds] = useState<IdBaseType[]>([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setSelectedIds([]);
  }, [props.rows]);

  const filteredRows = useMemo(
    () => filterRows(props.rows, searchParams, props.filterConfigs, pageSetting?.searchString, props.searchKeys),
    [pageSetting?.searchString, props.filterConfigs, props.rows, props.searchKeys, searchParams],
  );

  const sortedRows = useMemo(
    () => sortRows(filteredRows, props.columns, order, orderBy),
    [filteredRows, order, orderBy, props.columns],
  );

  const actionCount = useMemo(() => getActionCount(props), [props]);
  const skeletonElements = useMemo(() => getSkeletonElements(props.skeletonAmount), [props.skeletonAmount]);

  const columns = useMemo(
    () => appendActionColumn(props.columns, actionCount, props.actionHeader),
    [props.columns, props.actionHeader, actionCount],
  );

  const rowRenderer = useCallback(
    ({index, style}: ListChildComponentProps<T>): JSX.Element | null => {
      const row = sortedRows[index];
      // Important to apply the style to the wrapper of the row as the table are virtualized and some styling are involved to achieve this.
      return (
        <Box style={style} key={row.id} minWidth={'fit-content'}>
          <ListTableRow row={row} onRowClick={props.onRowClick} />
        </Box>
      );
    },
    [props.onRowClick, sortedRows],
  );

  // Note: Data is undefined there for we need to find the data in the sortedRows array
  const keySelector: ListItemKeySelector<T> = useCallback(
    (index: number, data: T) => sortedRows[index].id ?? '',
    [sortedRows],
  );

  const innerElementType = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(({children, ...rest}, ref) => {
    return (
      <div ref={ref} {...rest}>
        <Box sx={{top: 0, left: 0, width: '100%', position: 'sticky', zIndex: 1}}>
          <ListTableHeader />
        </Box>
        <Box position={'relative'}>{children}</Box>
      </div>
    );
  });

  return (
    <ListTableContext.Provider
      value={{
        columns,
        rows: sortedRows,
        loading: props.loading,
        selectedIds,
        order,
        orderBy,
        setSelectedIds,
        setOrder,
        setOrderBy,
        onEdit: props.onEdit,
        onDelete: props.onDelete,
        actions: props.actions,
        actionCount,
        checkbox: props.checkbox,
        checkboxActions: props.checkboxActions,
      }}>
      <Box height={'calc(' + props.height + ')'}>
        <AutoSizer>
          {({height, width}: {height: number; width: number}) => (
            <Box sx={{height: height, width: width}}>
              {/* Loading indicator elements */}
              {props.loading && <>{skeletonElements}</>}
              {/* List view */}
              {!props.loading && (
                <FixedSizeList
                  innerElementType={innerElementType}
                  height={height ?? 0} // Subtract header from height
                  width={width!}
                  overscanCount={20}
                  itemCount={sortedRows.length}
                  itemSize={VirutalListItemSize}
                  itemKey={keySelector}>
                  {rowRenderer}
                </FixedSizeList>
              )}
            </Box>
          )}
        </AutoSizer>
      </Box>
    </ListTableContext.Provider>
  );
});
