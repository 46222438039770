import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import {Avatar, Box, Drawer, Fade, IconButton, styled, Theme, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {UserRole} from 'api/api';
import FlexoWashLogo from 'assets/images/flexo_wash_logo.svg';
import clsx from 'clsx';
import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {selectCurrentUser} from 'state/features/api/user-slice';
import {toggleDrawer} from 'state/features/webapp/webapp-slice';
import {routes} from 'state/routes';
import {primaryColor} from 'styles/color-constants';
import GroupIcon from 'view/components/default-components/icons/group-icon';
import MachineIcon from 'view/components/default-components/icons/machine-icon';
import UserIcon from 'view/components/default-components/icons/user-icon';
import UserSettingsIcon from 'view/components/default-components/icons/user-settings-icon';
import {useLayoutStyles} from '../../../app-routes';
import LogoSmall from '../../../assets/iterator_small.png';
import {useAuth} from '../../../auth/auth-provider';
import {localized, localizedDynamic} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {theme} from '../../../styles/theme';
import {BasicSpinner} from '../../components/default-components/spinner/basic-spinner';
import {DrawerItem} from './drawer-item';
const drawerWidth = 240;
const logoTransitionDuration = {
  enter: 300,
  exit: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: theme.colors.sideBar.sidebarBackgroundColor,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    drawerPaper: {
      color: primaryColor,
    },
    logoContainer: {
      height: '70px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    toggleBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      backgroundColor: theme.colors.sideBar.siderbarToggleBarColor,
    },
    spacer: {
      flex: 1,
    },
    userField: {
      backgroundColor: 'rgb(245, 244, 244)',
      width: '100%',
    },
    userContainer: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: theme.spacing(2),
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    },
    avatar: {
      backgroundColor: theme.palette.common.flexoWashBlueLight,
    },
    avatarName: {
      margin: '16px 0',
    },
    logoutOpen: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
      '& img': {
        marginLeft: '8px',
      },
    },
    logoutClosed: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '32px 0',
    },
  }),
);

const LogoLink = styled(Link)`
  display: flex;
`;

export const MenuDrawer: FC = React.memo(() => {
  const {logout, isGlobalAdmin, loading} = useAuth();

  const handleLogout = useCallback(() => logout(), [logout]);
  let location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: AppState) => state.webAppReducer.open);
  const currentUser = useSelector(selectCurrentUser);
  const layoutClasses = useLayoutStyles();

  const handleToggleDrawer = useCallback(() => {
    dispatch(toggleDrawer());
  }, [dispatch]);

  const locationBaseString = '/' + location.pathname.split('/')[1];

  return (
    <div className={layoutClasses.desktopOnly}>
      <Drawer
        variant="permanent"
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        PaperProps={{
          sx: {
            backgroundColor: primaryColor,
          },
        }}>
        <div className={classes.logoContainer}>
          <LogoLink to="/">
            <Fade in={open} unmountOnExit timeout={logoTransitionDuration}>
              <img width={160} src={FlexoWashLogo} alt="Logo" />
            </Fade>
            <Fade in={!open} unmountOnExit timeout={logoTransitionDuration}>
              <img width={40} src={LogoSmall} alt="Logo" />
            </Fade>
          </LogoLink>
        </div>

        <Divider />

        <List sx={{paddingTop: '0px'}}>
          <DrawerItem
            open={open}
            linkTo={routes.machines}
            icon={<MachineIcon />}
            text={localized('Machines')}
            selected={locationBaseString === routes.machines}
            identifier={routes.machines}
          />

          {isGlobalAdmin && (
            <DrawerItem
              open={open}
              linkTo={routes.machineType}
              icon={<UserSettingsIcon />}
              text={localized('MachineType')}
              selected={locationBaseString === routes.machineType}
              identifier={routes.machineType}
            />
          )}

          {isGlobalAdmin && (
            <DrawerItem
              open={open}
              linkTo={routes.userManagement}
              icon={<GroupIcon />}
              text={localized('Users')}
              selected={locationBaseString === routes.userManagement}
              identifier={routes.userManagement}
            />
          )}

          {isGlobalAdmin && (
            <DrawerItem
              open={open}
              linkTo={routes.organization}
              icon={<BusinessIcon />}
              text={localized('Organization')}
              selected={locationBaseString === routes.organization}
              identifier={routes.organization}
            />
          )}

          {isGlobalAdmin && (
            <DrawerItem
              open={open}
              linkTo={routes.agent}
              icon={<AccountTreeIcon />}
              text={localized('Agents')}
              selected={locationBaseString === routes.agent}
              identifier={routes.agent}
            />
          )}
        </List>

        <div className={classes.spacer}></div>

        <Box
          sx={{
            width: '100%',
            backgroundColor: theme.colors.sideBar.sideBarProfileBackgroundColor,
            padding: theme.spacing(2),
          }}>
          {open ? (
            !loading ? (
              <div className={classes.userContainer}>
                <Avatar className={classes.avatar} src="">
                  <UserIcon fontSize="small" />
                </Avatar>
                <div className={classes.avatarName}>
                  <Typography variant="body2" sx={{color: 'white'}}>
                    {currentUser?.name}
                  </Typography>
                  {isGlobalAdmin && (
                    <Typography variant="body2" sx={{color: 'white'}}>
                      {currentUser?.role === UserRole.GlobalAdmin && localizedDynamic(UserRole[UserRole.GlobalAdmin])}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: 'common.flexoWashBlueLight',
                    }}
                    onClick={handleLogout}
                    variant="body2">
                    {localized('LogOut')}
                  </Typography>
                </div>
              </div>
            ) : (
              <BasicSpinner />
            )
          ) : (
            <div onClick={handleLogout} className={classes.logoutClosed}>
              <LogoutIcon color="secondary" sx={{fontSize: 32}} />
              <Typography variant="body2">{localized('LogOut')}</Typography>
            </div>
          )}
        </Box>

        <div className={classes.toggleBar}>
          <IconButton onClick={handleToggleDrawer} size="large">
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
      </Drawer>
    </div>
  );
});
