import styled from '@emotion/styled';
import {IAgentDTO} from 'api/api';
import React, {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useMatch, useParams} from 'react-router-dom';
import {
  selectAgentById,
  useCreateAgentMutation,
  useGetAgentsQuery,
  useUpdateAgentMutation,
} from 'state/features/api/agents-slice';
import {useGetMachinesQuery} from 'state/features/api/machines-slice';
import {useGetOrganizationsQuery} from 'state/features/api/organization-slice';
import {routes} from 'state/routes';
import {useGoBackOnSuccess} from 'utilities/navigation-helper';
import {Form} from 'view/components/default-components/form/form';
import {localized, localizedInterpolation} from '../../../../i18n/i18n';
import {FlexRow} from '../../../components/default-components/flex-row';
import {PageContentContainer} from '../../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../components/default-components/page-title/page-title';
import {CreateAgentForm} from './create-agent-form';

const ContentContainer = styled(PageContentContainer)`
  ${({theme}) => theme.breakpoints.up('xs')} {
    padding: ${({theme}) => theme.spacing(5)};
  }
  ${({theme}) => theme.breakpoints.up('xl')} {
    padding: ${({theme}) => theme.spacing(6)};
  }
`;
const sharedCacheKey = 'agentMutation';

export const CreateAgent: FC = React.memo(() => {
  const {agentId} = useParams<{agentId: string}>();
  const match = useMatch(routes.agentEdit);
  const [createAgent] = useCreateAgentMutation({fixedCacheKey: sharedCacheKey});
  const [updateAgent, {isLoading, isSuccess, reset}] = useUpdateAgentMutation({fixedCacheKey: sharedCacheKey});
  const agent = useSelector(selectAgentById(Number(agentId)));
  useGoBackOnSuccess(isSuccess, reset);
  useGetMachinesQuery();
  useGetOrganizationsQuery();
  useGetAgentsQuery();

  const handleSubmit = useCallback(
    async (data: any) => {
      if (agent) {
        // Update
        const updatedAgent: IAgentDTO = {...agent, ...data};
        await updateAgent(updatedAgent);
      } else {
        // Insert
        const newAgent: IAgentDTO = {...data};
        await createAgent(newAgent);
      }
    },
    [agent, updateAgent, createAgent],
  );

  const title = useMemo(
    () => (match !== null ? localized('EditAgent') : localizedInterpolation('CreateNewInt', 'Agent')),
    [match],
  );
  return (
    <PageTitle title={title}>
      <PageHeader area="agentCreate" title={title} />
      <ContentContainer>
        <FlexRow height={1}>
          <Form onSubmit={handleSubmit} sx={{flex: 1}} defaultValues={agent}>
            <CreateAgentForm />
          </Form>
        </FlexRow>
      </ContentContainer>
    </PageTitle>
  );
});
