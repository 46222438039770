import {createSelector} from '@reduxjs/toolkit';
import {IOrganizationDTO, OrganizationDTO} from 'api/api';
import {organizationClient} from 'state/api-clients';
import {DropdownOption} from 'view/components/default-components/form/form-types';
import {apiSlice} from './api-slice';

const organizationSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Organization'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOrganizations: builder.query<IOrganizationDTO[], void>({
        query: () => () => organizationClient.get(),
        providesTags: ['Organization'],
      }),
      createOrganization: builder.mutation<IOrganizationDTO, OrganizationDTO>({
        query: (organization) => () => organizationClient.post(organization),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Organization'],
      }),
      updateOrganization: builder.mutation<IOrganizationDTO, OrganizationDTO>({
        query: (organization) => () => organizationClient.put(organization),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Organization'],
      }),
      deleteOrganization: builder.mutation<void, OrganizationDTO>({
        query: (organization) => () => organizationClient.delete(organization),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Organization'],
      }),
    }),
  });

export const selectOrganizations = createSelector(
  organizationSlice.endpoints.getOrganizations.select(),
  (result) => result.data ?? [],
);

export const selectOrganizationById = (id: number | undefined) =>
  createSelector(selectOrganizations, (organizations) => {
    return organizations.find((mt) => mt.id === id);
  });

export const selectOrganizationDropdownOptions = createSelector(selectOrganizations, (organizations) =>
  organizations.map((organization) => new DropdownOption(organization.name ?? '', organization.id)),
);

export const {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
} = organizationSlice;
