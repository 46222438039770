import {Grid} from '@mui/material';
import React, {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMatch, useParams} from 'react-router-dom';
import {
  selectMachineTypeById,
  useCreateMachineTypeMutation,
  useUpdateMachineTypeMutation,
} from 'state/features/api/machine-type-slice';
import {showErrorSnackbar} from 'state/features/snackbar/snackbar-slice';
import {routes} from 'state/routes';
import {theme} from 'styles/theme';
import {useGoBackOnSuccess} from 'utilities/navigation-helper';
import {getMachineGroupTypeDropdownOptions} from 'utilities/platform-helpers/enum-helper';
import {ImageInput} from 'view/components/default-components/form/image-input';
import {SelectInput} from 'view/components/default-components/form/select-input';
import {IMachineTypeDTO, MachineTypeDTO} from '../../../../api/api';
import {localized, localizedDynamic, localizedInterpolation} from '../../../../i18n/i18n';
import {primaryColor} from '../../../../styles/color-constants';
import {nameof} from '../../../../utilities/platform-helpers/nameof-helper';
import {requiredValidator} from '../../../../utilities/platform-helpers/validator-helper';
import {BasicCard} from '../../../components/default-components/basic-card/basic-card';
import {BasicButton} from '../../../components/default-components/buttons/basic-button';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {FlexRow} from '../../../components/default-components/flex-row';
import {Form} from '../../../components/default-components/form/form';
import {TextInput} from '../../../components/default-components/form/text-input';
import {TextLabel} from '../../../components/default-components/text-label';

const sharedCacheKey = 'machineTypeMutation';
interface PropsFromParent {}

export const MachineTypeForm: FC<PropsFromParent> = React.memo(() => {
  const dispatch = useDispatch();
  const [image, setImage] = useState<File>();
  const [imageError, setImageError] = useState<string | undefined>(undefined);
  const [updateMachineType] = useUpdateMachineTypeMutation({fixedCacheKey: sharedCacheKey});
  const [createMachineType, {isLoading, isSuccess, reset}] = useCreateMachineTypeMutation({
    fixedCacheKey: sharedCacheKey,
  });
  const match = useMatch(routes.machineTypeEdit);
  const {machineTypeId} = useParams<{machineTypeId: string}>();
  const machineType = useSelector(selectMachineTypeById(Number(machineTypeId)));
  const machineGroupOptions = useMemo(() => getMachineGroupTypeDropdownOptions(), []);

  useGoBackOnSuccess(isSuccess, reset);

  const save = useCallback(
    async (dto: IMachineTypeDTO) => {
      if (machineType) {
        const updateObj = {...machineType, ...dto} as MachineTypeDTO;
        if (image) {
          const reader = new FileReader();
          reader.readAsDataURL(image);

          reader.onload = (e) => {
            updateObj.imageBase64 = e.target?.result as string;
            updateObj.imageName = image.name;
            updateMachineType(updateObj);
          };
        } else {
          updateMachineType(updateObj);
        }
      } else {
        if (image) {
          const reader = new FileReader();
          reader.readAsDataURL(image);

          reader.onload = (e) => {
            createMachineType({
              name: dto.name,
              machineGroupType: dto.machineGroupType,
              imageBase64: e.target?.result as string,
              imageName: image.name,
            } as MachineTypeDTO);
          };
        }
      }
    },
    [createMachineType, image, machineType, updateMachineType],
  );

  const onFileChange = useCallback(
    (image: ChangeEvent<HTMLInputElement>) => {
      if (image.currentTarget.files && image.currentTarget.files.length > 0) {
        if (!image.currentTarget.files[0].type.match(/image.*/)) {
          dispatch(showErrorSnackbar(localizedDynamic('FileNotSupportedError')));
          setImageError(localizedDynamic('FileNotSupportedError'));
          setImage(undefined);
        } else {
          setImageError(undefined);
          setImage(image.currentTarget.files[0]);
        }
      }
    },
    [dispatch],
  );

  return (
    <BasicCard sx={{width: 'unset', height: 'fit-contet'}} m={0}>
      <FlexRow>
        <FlexColumn>
          <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
            {localized('MachineType')}
          </TextLabel>
          <TextLabel variant="body1" marginBottom={3}>
            {localized('CreateMachineTypeFormSubTitle')}
          </TextLabel>
          <TextLabel variant="h6" fontWeight="bold">
            {localized('MachineTypeInformation')}
          </TextLabel>
        </FlexColumn>
      </FlexRow>
      <Form onSubmit={save} sx={{maxWidth: 400}} defaultValues={machineType}>
        <TextInput name={nameof<MachineTypeDTO>('name')} label={localized('Name')} validators={requiredValidator} />
        <SelectInput
          name={nameof<MachineTypeDTO>('machineGroupType')}
          label={localized('MachineGroup')}
          options={machineGroupOptions}
        />
        <Grid container sx={{marginBottom: theme.spacing(2)}}>
          <Grid item>
            <ImageInput
              label={localized('UploadImage')}
              name={nameof<MachineTypeDTO>('imageBase64')}
              accept="image/*"
              onChange={onFileChange}
              errors={imageError}
              isSuccess={isSuccess}
              hidden
            />
          </Grid>
        </Grid>
        <FlexRow gap={2}>
          <BasicButton
            type="submit"
            buttonColor={'primary'}
            disabled={isLoading}
            text={localizedInterpolation(match ? 'SaveInt' : 'CreateNewInt', 'MachineType')}
            sx={{display: 'flex', flexGrow: 1, minHeight: '100%', justifyContent: 'flex-end'}}
          />
          <BasicButton backButton buttonColor={'secondary'} text={localized('Cancel')} />
        </FlexRow>
      </Form>
    </BasicCard>
  );
});
