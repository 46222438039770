import {AddMachineDTO, IAddMachineDTO} from 'api/api';
import {localized, localizedInterpolation} from 'i18n/i18n';
import {FC, memo, useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAddMachineToUserMutation} from 'state/features/api/machines-slice';
import {primaryColor} from 'styles/color-constants';
import {nameof} from 'utilities/platform-helpers/nameof-helper';
import {requiredValidator} from 'utilities/platform-helpers/validator-helper';
import {BasicCard} from 'view/components/default-components/basic-card/basic-card';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {FlexColumn} from 'view/components/default-components/flex-column';
import {FlexRow} from 'view/components/default-components/flex-row';
import {Form} from 'view/components/default-components/form/form';
import {TextInput} from 'view/components/default-components/form/text-input';
import PageContentContainer from 'view/components/default-components/page-container/page-content-container';
import {PageHeader} from 'view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from 'view/components/default-components/page-title/page-title';
import {TextLabel} from 'view/components/default-components/text-label';

export const AddMachine: FC = memo(() => {
  const [addMachine, {isLoading, isSuccess, reset}] = useAddMachineToUserMutation();
  const title = useMemo(() => localizedInterpolation('AddInt', 'Machine'), []);
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (data: AddMachineDTO) => {
      addMachine(data);
    },
    [addMachine],
  );

  useEffect(() => {
    if (isSuccess) {
      reset();
      navigate(-1);
    }
  }, [isSuccess, navigate, reset]);

  return (
    <PageTitle title={title}>
      <PageHeader area="machineCreate" title={title} />
      <PageContentContainer xy>
        <BasicCard sx={{maxWidth: 500, height: 'fit-contet'}} m={0}>
          <FlexRow>
            <FlexColumn>
              <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
                {localized('Machine')}
              </TextLabel>
              <TextLabel variant="body1" marginBottom={3}>
                {localized('AddNewMachineMeta')}
              </TextLabel>
              <TextLabel variant="h6" fontWeight="bold">
                {localized('MachineInformation')}
              </TextLabel>
            </FlexColumn>
          </FlexRow>

          <Form onSubmit={handleSubmit} sx={{maxWidth: 400}}>
            <TextInput
              name={nameof<IAddMachineDTO>('serialNumber')}
              label={localized('SerialNumber')}
              validators={requiredValidator}
            />
            <TextInput
              name={nameof<IAddMachineDTO>('pinCode')}
              label={localized('PinCode')}
              validators={requiredValidator}
            />
            <FlexRow gap={2}>
              <BasicButton type="submit" disabled={isLoading} buttonColor={'primary'} text={title} />
              <BasicButton backButton buttonColor={'secondary'} text={localized('Cancel')} />
            </FlexRow>
          </Form>
        </BasicCard>
      </PageContentContainer>
    </PageTitle>
  );
});
