import {createSelector} from '@reduxjs/toolkit';
import {AgentDTO, IAgentDTO, IUserDTO, UserDTO} from 'api/api';
import {agentsClient, userClient} from 'state/api-clients';
import {apiSlice} from './api-slice';

export const agentsSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Agent'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAgents: builder.query<IAgentDTO[], void>({
        query: () => () => agentsClient.get(),
        providesTags: ['Agent'],
      }),
      createAgent: builder.mutation<IAgentDTO, IAgentDTO>({
        query: (agent) => () => agentsClient.insert(agent as AgentDTO),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Agent'],
      }),
      updateAgent: builder.mutation<IAgentDTO, IAgentDTO>({
        query: (agent) => () => agentsClient.update(agent as AgentDTO),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Agent'],
      }),
      deleteAgent: builder.mutation<void, IAgentDTO>({
        query: (agent) => () => agentsClient.delete(agent as AgentDTO),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Agent'],
      }),
      deleteAgentUser: builder.mutation<void, IUserDTO>({
        query: (user) => () => userClient.delete(user as UserDTO),
        extraOptions: {showResponse: true},
        invalidatesTags: ['Agent'],
      }),
    }),
  });

export const selectAgentsResult = agentsSlice.endpoints.getAgents.select();

const emptyAgents: IAgentDTO[] = [];

export const selectAllAgents = createSelector(selectAgentsResult, (agentsResult) => agentsResult?.data ?? emptyAgents);

export const selectAgentById = (id: number | undefined) =>
  createSelector(selectAllAgents, (agents) => {
    return agents.find((agent) => agent.id === id);
  });

export const {
  useGetAgentsQuery,
  useCreateAgentMutation,
  useDeleteAgentMutation,
  useUpdateAgentMutation,
  useDeleteAgentUserMutation,
} = agentsSlice;
