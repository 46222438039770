import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import React from 'react';
import { AssetsBlobStorageUrl } from 'utilities/constants';

interface Props extends BoxProps {
  src: string;
  alt: string;
}

const defaultSxProps: SxProps<Theme> = {
  objectFit: 'cover',
  width: '100%',
  height: '100%',
};

export const BlobImage: React.FC<Props> = (props) => {
  const {src, alt, sx, ...rest} = props;

  const srcPath = `${AssetsBlobStorageUrl}${src}`;

  const sxProps: SxProps<Theme> = React.useMemo(
    () => ({
      ...defaultSxProps,
      ...sx,
    }),
    [sx],
  );

  return (
    <Box
      component="img"
      sx={sxProps}
      alt={alt}
      src={srcPath}
      {...rest}
    />
  );
};

export default React.memo(BlobImage);
