import {SortDirection} from '@mui/material';

export const filterListItems = <K extends keyof T, T>(
  items: T[],
  propertyName: K[],
  searchString: string | undefined,
) => {
  if (searchString) {
    return items.filter((item) => {
      // Iterate item properties
      for (let i = 0; i < propertyName.length; i++) {
        const keyValue = item[propertyName[i]];
        const valueString = keyValue ? (keyValue as any).toString() : '';

        if (valueString.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }
  return items;
};

export const sortListItems = <K extends keyof T, T extends Partial<Record<K, string>>>(
  items: T[],
  propertyName: K,
  sortType: SortDirection,
) => {
  const sortedArray = items.concat().sort((a, b) => {
    return ('' + a[propertyName]).localeCompare('' + b[propertyName]);
  });
  if (sortType === 'desc') {
    sortedArray.reverse();
  }

  return sortedArray;
};

export const distinctProperties = <T>(
  data: T[],
  key: keyof T,
  valueFormatter?: (item: T) => string,
): (T[keyof T] | string)[] => {
  if (data.length > 0 && Array.isArray(data[0][key])) {
    return [...new Set(data.flatMap((item) => item[key]))];
  }

  return [...new Set(data.map((item) => (valueFormatter ? valueFormatter(item) : item[key])))];
};

export const isNullOrUndefined = (data: any) => {
  return data === undefined || data === null;
};
