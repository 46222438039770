export const routes = {
  root: '/',

  userManagement: '/user-management',
  userManagementCreate: '/user-management/create',
  userManagementEdit: '/user-management/:userId/edit',
  userManagementDelete: '/user-management/delete-user',

  organization: '/organization',
  organizationCreate: '/organization/create',
  organizationEdit: '/organization/:organizationId/edit',

  agent: '/agent',
  agentCreate: '/agent/create',
  agentEdit: '/agent/:agentId/edit',
  agentUsers: '/agent/:agentId/users',
  agentUsersCreate: '/agent/:agentId/users/create',
  agentUsersEdit: '/agent/:agentId/users/:userId/edit',

  allMachines: '/all-machines',
  allMachinesDetails: '/all-machines/:machineId',
  machines: '/machines',
  machinesCreate: '/machines/create',
  machinesEdit: '/machines/:machineId/edit',
  machineDetails: '/machines/:machineId',
  machinesAdd: '/machines/add',

  machineType: '/machine-type',
  machineTypeCreate: '/machine-type/create',
  machineTypeEdit: '/machine-type/:machineTypeId/edit',

  callback: '/callback',
  signUp: '/signup',
  noPermission: '/no-permission',
};

export const defaultRoute = routes.machines;
