import Grid from '@mui/material/Grid';
import {IPWMachineStatisticDTO} from 'api/api';
import {localized} from 'i18n/i18n';
import React from 'react';
import {StatisticItem, getValueOrUnknown} from '../statistic-item';
import {SmallStatisticItemWrapper} from '../statistic-item-container';
import {StatisticsProps} from '../statistics';
import {StatisticItemRoot} from '../statistics-container-item';

export const PWStatistics: React.FC<StatisticsProps> = (props) => {
  const {statistics} = props.data;

  if (!statistics) {
    return <div>{localized('NoStatistics')}</div>;
  }

  const {totalWashHours, washHoursSinceService, washHoursUntilNextService, washHoursUntilNextFilter} =
    statistics as IPWMachineStatisticDTO;

  return (
    <Grid
      container
      spacing={{
        xs: 5,
        xl: 6,
      }}>
      <StatisticItemRoot xs={12} lg={3}>
        <SmallStatisticItemWrapper>
          <StatisticItem label={localized('TotalWashHours')} placeholder={getValueOrUnknown(totalWashHours)} />
          <StatisticItem
            label={localized('WashHoursSinceService')}
            placeholder={getValueOrUnknown(washHoursSinceService)}
          />
        </SmallStatisticItemWrapper>
      </StatisticItemRoot>

      <StatisticItemRoot xs lg>
        <StatisticItem
          label={localized('WashHoursLeftBeforeService')}
          placeholder={getValueOrUnknown(washHoursUntilNextService)}
          variant="large"
        />
      </StatisticItemRoot>

      <StatisticItemRoot xs lg>
        <StatisticItem
          label={localized('WashHoursLeftBeforeFilterChange')}
          placeholder={getValueOrUnknown(washHoursUntilNextFilter)}
          variant="large"
        />
      </StatisticItemRoot>
    </Grid>
  );
};
