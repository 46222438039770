import {Box, SxProps} from '@mui/material';
import {ISelfSignUpRequestDTO, SelfSignUpRequestDTO} from 'api/api';
import {localized} from 'i18n/i18n';
import {FC, memo, useCallback} from 'react';
import {useSelfSignUpMutation} from 'state/features/api/user-slice';
import {theme} from 'styles/theme';
import {nameof} from 'utilities/platform-helpers/nameof-helper';
import {requiredValidator} from 'utilities/platform-helpers/validator-helper';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {Form} from 'view/components/default-components/form/form';
import {TextInput} from 'view/components/default-components/form/text-input';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import {TextLabel} from 'view/components/default-components/text-label';

const buttonSx: SxProps = {minWidth: 350, mt: 2};
const inputSx: SxProps = {maxHeight: 48, fontSize: 16};
interface PropsFromParent {
  hideForm: () => void;
}
export const SignUpForm: FC<PropsFromParent> = memo((props) => {
  const [signUp, {isLoading, isSuccess}] = useSelfSignUpMutation();
  const handleSubmit = useCallback(
    (data: SelfSignUpRequestDTO) => {
      signUp(data);
    },
    [signUp],
  );

  return isLoading ? (
    <BasicSpinner />
  ) : isSuccess ? (
    <Box margin={'auto'}>
      <TextLabel fontSize={16} maxWidth={350} textAlign={'center'}>
        {localized('SignUpConfirmation')}
      </TextLabel>
      <BasicButton
        sx={{...buttonSx, color: theme.palette.text.primary}}
        text={isSuccess ? localized('GoToSignIn') : localized('Cancel')}
        onClick={props.hideForm}
        buttonColor="secondary"
      />
    </Box>
  ) : (
    <Form onSubmit={handleSubmit} sx={{maxWidth: 350, margin: 'auto'}}>
      <TextLabel variant="h6" fontWeight={'bold'}>
        {localized('CreateNewAccount')}
      </TextLabel>

      <TextLabel fontSize={16}>{localized('Email')}</TextLabel>
      <TextInput name={nameof<ISelfSignUpRequestDTO>('email')} validators={requiredValidator} inputSx={inputSx} />

      <TextLabel fontSize={16}>{localized('Name')}</TextLabel>
      <TextInput name={nameof<ISelfSignUpRequestDTO>('name')} validators={requiredValidator} inputSx={inputSx} />

      <BasicButton type="submit" buttonColor={'primary'} text={'Create account'} sx={{width: '100%', mt: 2}} />
      <BasicButton
        sx={{...buttonSx, color: theme.palette.text.primary}}
        text={isSuccess ? localized('GoToSignIn') : localized('Cancel')}
        onClick={props.hideForm}
        buttonColor="secondary"
      />
    </Form>
  );
});
