import {FC, memo} from 'react';
import {useMatch} from 'react-router-dom';
import {routes} from 'state/routes';
import {IAgentDTO} from '../../../../api/api';
import {localized, localizedInterpolation} from '../../../../i18n/i18n';
import {primaryColor} from '../../../../styles/color-constants';
import {nameof} from '../../../../utilities/platform-helpers/nameof-helper';
import {requiredValidator} from '../../../../utilities/platform-helpers/validator-helper';
import {BasicCard} from '../../../components/default-components/basic-card/basic-card';
import {BasicButton} from '../../../components/default-components/buttons/basic-button';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {FlexRow} from '../../../components/default-components/flex-row';
import {TextInput} from '../../../components/default-components/form/text-input';
import {TextLabel} from '../../../components/default-components/text-label';
import {CreateAgentMachineList} from './create-agent-machine-list/create-agent-machine-list';

export const CreateAgentForm: FC = memo(() => {
  const match = useMatch(routes.agentEdit);

  return (
    <FlexRow gap={4} flex={1}>
      <BasicCard sx={{width: 'unset', maxWidth: 400, height: 'fit-content'}}>
        <FlexRow>
          <FlexColumn>
            <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
              {localized('Agent')}
            </TextLabel>
            <TextLabel variant="body1" marginBottom={3}>
              {match !== null ? localized('SaveAgentFormSubTitle') : localized('CreateAgentFormSubTitle')}
            </TextLabel>
            <TextLabel variant="h6" fontWeight="bold">
              {localized('AgentInformation')}
            </TextLabel>
          </FlexColumn>
        </FlexRow>
        <TextInput name={nameof<IAgentDTO>('name')} label={localized('AgentName')} validators={requiredValidator} />

        <FlexRow gap={2}>
          <BasicButton
            type="submit"
            buttonColor={'primary'}
            text={localizedInterpolation(match ? 'SaveInt' : 'CreateNewInt', 'Agent')}
          />
          <BasicButton backButton buttonColor={'secondary'} text={localized('Cancel')} />
        </FlexRow>
      </BasicCard>
      {match && (
        <BasicCard flex={1}>
          <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
            {localized('Machines')}
          </TextLabel>
          <TextLabel variant="body1" marginBottom={3}>
            {localized('AgetAddMachineMeta')}
          </TextLabel>
          <CreateAgentMachineList />
        </BasicCard>
      )}
    </FlexRow>
  );
});
