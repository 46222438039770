import {Box, SortDirection, SxProps} from '@mui/material';
import React, {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectOrganizations} from 'state/features/api/organization-slice';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {theme} from '../../../../styles/theme';
import {filterListItems, sortListItems} from '../../../../utilities/helpers/general-helpers';
import {VirtualizedContentBoxList} from '../../../components/default-components/virtual-content-box-list/virtual-content-box-list';
import {OrganizationBoxItem} from './organization-box-item';

export const OrganizationBoxView: FC = React.memo(() => {
  const pageSetting = useSelector(selectPageSettingByKey('organization'));

  const organizations = useSelector(selectOrganizations);

  const filteredItems = useMemo(() => {
    const filteredArray = filterListItems(organizations, ['name'], pageSetting?.searchString);
    return sortListItems(filteredArray, 'name', pageSetting ? (pageSetting.sort as SortDirection) : 'asc').map(
      (org) => org.id!,
    );
  }, [pageSetting, organizations]);

  const renderUserManagementBoxItem = useCallback(
    ({index}: any) => {
      if (index > filteredItems.length - 1) {
        return null;
      }
      return <OrganizationBoxItem key={filteredItems[index]} organizationId={filteredItems[index]} />;
    },
    [filteredItems],
  );

  return (
    <Box sx={{marginTop: theme.spacing(3)} as SxProps}>
      <VirtualizedContentBoxList items={filteredItems} rowRenderer={renderUserManagementBoxItem} />
    </Box>
  );
});
