import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useMatch, useParams} from 'react-router-dom';
import {
  selectOrganizationById,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} from 'state/features/api/organization-slice';
import {useGoBackOnSuccess} from 'utilities/navigation-helper';
import {Organization, OrganizationDTO} from '../../../../api/api';
import {localized, localizedInterpolation} from '../../../../i18n/i18n';
import {routes} from '../../../../state/routes';
import {primaryColor} from '../../../../styles/color-constants';
import {nameof} from '../../../../utilities/platform-helpers/nameof-helper';
import {requiredValidator} from '../../../../utilities/platform-helpers/validator-helper';
import {BasicCard} from '../../../components/default-components/basic-card/basic-card';
import {BasicButton} from '../../../components/default-components/buttons/basic-button';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {FlexRow} from '../../../components/default-components/flex-row';
import {Form} from '../../../components/default-components/form/form';
import {TextInput} from '../../../components/default-components/form/text-input';
import {TextLabel} from '../../../components/default-components/text-label';

const sharedCacheKey = 'organizationMutation';
export const OrganizationForm: FC = React.memo(() => {
  const [updateOrganization] = useUpdateOrganizationMutation({fixedCacheKey: sharedCacheKey});
  const [createOrganization, {isLoading, isSuccess, reset}] = useCreateOrganizationMutation({
    fixedCacheKey: sharedCacheKey,
  });
  const match = useMatch(routes.organizationEdit);
  let {organizationId} = useParams<{organizationId: string}>();
  const organization = useSelector(selectOrganizationById(Number(organizationId)));

  useGoBackOnSuccess(isSuccess, reset);

  const save = useCallback(
    (newOrg: OrganizationDTO) => {
      if (organization) {
        organization.name = newOrg.name;
        organization.description = newOrg.description;
        updateOrganization(organization as OrganizationDTO);
      } else {
        createOrganization(newOrg);
      }
    },
    [organization, updateOrganization, createOrganization],
  );

  return (
    <BasicCard sx={{width: 'unset', height: 'fit-contet'}}>
      <FlexRow>
        <FlexColumn>
          <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
            {localized('Organization')}
          </TextLabel>
          <TextLabel variant="body1" marginBottom={3}>
            {localized('CreateOrganizationFormSubTitle')}
          </TextLabel>
          <TextLabel variant="h6" fontWeight="bold">
            {localized('OrganizationInformation')}
          </TextLabel>
        </FlexColumn>
      </FlexRow>
      <Form onSubmit={save} sx={{maxWidth: 400}} defaultValues={organization}>
        <TextInput name={nameof<Organization>('name')} label={localized('Name')} validators={requiredValidator} />
        <TextInput name={nameof<Organization>('description')} label={localized('Description')} />
        <FlexRow gap={2}>
          <BasicButton
            type="submit"
            buttonColor={'primary'}
            disabled={isLoading}
            text={localizedInterpolation(match !== null ? 'SaveInt' : 'CreateNewInt', 'Organization')}
          />

          <BasicButton sx={{marginRight: 1}} buttonColor={'secondary'} backButton text={localized('Cancel')} />
        </FlexRow>
      </Form>
    </BasicCard>
  );
});
