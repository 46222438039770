import React, {FC} from 'react';
import {useMatch} from 'react-router-dom';
import {useGetMachinesQuery} from 'state/features/api/machines-slice';
import {useGetOrganizationsQuery} from 'state/features/api/organization-slice';
import {useGetUsersQuery} from 'state/features/api/user-slice';
import {localized} from '../../../../i18n/i18n';
import {routes} from '../../../../state/routes';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {FlexRow} from '../../../components/default-components/flex-row';
import {PageContentContainer} from '../../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../../components/default-components/page-header.tsx/page-header-content';
import {PageTitle} from '../../../components/default-components/page-title/page-title';
import {UserForm} from './user-form';

export const CreateUser: FC = React.memo(() => {
  const match = useMatch(routes.userManagementEdit);
  useGetOrganizationsQuery();
  useGetUsersQuery();
  useGetMachinesQuery();

  return (
    <PageTitle title={localized('CreateUser')}>
      <PageHeader area="userCreate" title={match !== null ? localized('EditUser') : localized('CreateUser')}>
        <PageHeaderContent></PageHeaderContent>
      </PageHeader>
      <PageContentContainer xy>
        <FlexRow height={1} maxWidth={1000}>
          <FlexColumn flex={1}>
            <UserForm />
          </FlexColumn>
        </FlexRow>
      </PageContentContainer>
    </PageTitle>
  );
});
