import {Theme} from '@emotion/react';
import {Box, SxProps, Typography} from '@mui/material';
import {ConnectionState, IMachineDTO} from 'api/api';
import {localized, localizedDynamic} from 'i18n/i18n';
import {FC, memo} from 'react';
import {MachineConnectionMapper} from 'utilities/mappers/machine-connection-mapper';
import {getFormattedDate} from 'utilities/platform-helpers/date-helpers';

const iconStyles: SxProps<Theme> = {color: 'common.flexoWashBlueLight', fontSize: '1.2rem', marginRight: '10px'};

interface Props {
  machine: IMachineDTO;
}

export const MachineConnectionCell: FC<Props> = memo(({machine}) => {
  const {connectionState, latestConnectionStateChange} = machine.currentState ?? {};
  const {
    stateTranslationKey: connectionStatus,
    icon: ConnectionIcon,
    color,
  } = MachineConnectionMapper[connectionState ?? ConnectionState.Unknown];

  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <ConnectionIcon sx={iconStyles} />
      <span>
        <Typography variant="body3" color={color}>
          {localizedDynamic(connectionStatus)}
        </Typography>{' '}
        {latestConnectionStateChange === undefined ? (
          <>{`- ${localized('MachineNoActivity')}`}</>
        ) : (
          <>
            {connectionState !== ConnectionState.Online &&
              `${localized('Since').toLowerCase()} ${getFormattedDate(latestConnectionStateChange)}`}
          </>
        )}
      </span>
    </Box>
  );
});
