import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const PauseIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 41 41">
      <g id="Group_38" data-name="Group 38" transform="translate(-874 -613)">
        <circle
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="20.5"
          cy="20.5"
          r="20.5"
          transform="translate(874 613)"
          fill="#7a9cc5"
        />
        <g id="Group_1" data-name="Group 1" transform="translate(-0.836 -1.271)">
          <line
            id="Line_14"
            data-name="Line 14"
            y2="13.468"
            transform="translate(891.836 628.271)"
            fill="none"
            stroke="#dae3f2"
            strokeLinecap="round"
            strokeWidth="2.5"
          />
          <line
            id="Line_14-2"
            data-name="Line 14"
            y2="13.468"
            transform="translate(898.836 628.271)"
            fill="none"
            stroke="#dae3f2"
            strokeLinecap="round"
            strokeWidth="2.5"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default React.memo(PauseIcon);
