import {localized, localizedInterpolation} from 'i18n/i18n';
import React, {FC, useMemo} from 'react';
import {useMatch} from 'react-router-dom';
import {routes} from 'state/routes';
import {FlexRow} from 'view/components/default-components/flex-row';
import PageContentContainer from 'view/components/default-components/page-container/page-content-container';
import {PageHeader} from 'view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from 'view/components/default-components/page-title/page-title';
import {CreateAgentUserForm} from './create-agent-user-form';

export const AgentUsersCreate: FC = React.memo(() => {
  const match = useMatch(routes.agentUsersEdit);
  const title = useMemo(
    () => (match !== null ? localized('EditUser') : localizedInterpolation('CreateNewInt', 'User')),
    [match],
  );
  return (
    <PageTitle title={title}>
      <PageHeader area="agentUserCreate" title={title} />
      <PageContentContainer xy>
        <FlexRow height={1}>
          <CreateAgentUserForm />
        </FlexRow>
      </PageContentContainer>
    </PageTitle>
  );
});
