import styled from '@emotion/styled';
import {Box, BoxProps} from '@mui/material';
import React from 'react';

interface Props extends BoxProps {
  xy?: boolean;
}

const PageContentContainerRoot = styled(Box)<{xy: boolean}>`
  width: 100%;
  box-sizing: border-box;

  ${({theme}) => theme.breakpoints.up('xs')} {
    padding: ${({theme, xy}) => `${!xy ? '0' : ''} ${theme.spacing(5)}`};
    max-width: none;
  }

  ${({theme}) => theme.breakpoints.up('xl')} {
    padding: ${({theme, xy}) => `${!xy ? '0' : ''} ${theme.spacing(6)}`};
    max-width: none;
  }
`;

export const PageContentContainer: React.FC<Props> = (props) => {
  const {children, xy = false, ...rest} = props;
  return (
    <PageContentContainerRoot xy={xy} {...rest}>
      {children}
    </PageContentContainerRoot>
  );
};

export default React.memo(PageContentContainer);
