import React, {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useMatch, useParams} from 'react-router-dom';
import {selectUserById, useCreateUserMutation, useUpdateUserMutation} from 'state/features/api/user-slice';
import {useGoBackOnSuccess} from 'utilities/navigation-helper';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import {User, UserDTO, UserRole} from '../../../../api/api';
import {useAuth} from '../../../../auth/auth-provider';
import {localized, localizedInterpolation} from '../../../../i18n/i18n';
import {routes} from '../../../../state/routes';
import {primaryColor} from '../../../../styles/color-constants';
import {requiredValidator} from '../../../../utilities/platform-helpers/validator-helper';
import {BasicCard} from '../../../components/default-components/basic-card/basic-card';
import {BasicButton} from '../../../components/default-components/buttons/basic-button';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {FlexRow} from '../../../components/default-components/flex-row';
import {CheckboxInput} from '../../../components/default-components/form/checkbox-input';
import {Form} from '../../../components/default-components/form/form';
import {TextInput} from '../../../components/default-components/form/text-input';
import {TextLabel} from '../../../components/default-components/text-label';
import {RemoveMachineCard} from './remove-machine-card';

interface PropsFromParent {}

const sharedCacheKey = 'userMutation';
export const UserForm: FC<PropsFromParent> = React.memo(() => {
  const [updateUser] = useUpdateUserMutation({fixedCacheKey: sharedCacheKey});
  const [createUser, {isLoading, isSuccess, reset}] = useCreateUserMutation({fixedCacheKey: sharedCacheKey});
  const match = useMatch(routes.userManagementEdit);
  let {userId} = useParams<{userId: string}>();
  const {isGlobalAdmin} = useAuth();

  useGoBackOnSuccess(isSuccess, reset);

  const user = useSelector(selectUserById(Number(userId)));

  const save = useCallback(
    (data: UserDTO) => {
      if (user) {
        user.name = data.name;
        updateUser(user as UserDTO);
      } else {
        const newUser = new User();
        newUser.init(data);
        newUser.role = data.role ? UserRole.GlobalAdmin : UserRole.User;
        createUser(newUser);
      }
    },
    [createUser, updateUser, user],
  );

  // Mapping the user object to form values as organization role value is in a nested object
  const defaultValues = useMemo(() => {
    return {
      ...user,
      role: user?.role === UserRole.GlobalAdmin,
    };
  }, [user]);

  return (
    <FlexRow sx={{gap: 8}}>
      <BasicCard sx={{width: 'unset', height: 'fit-contet'}}>
        <FlexRow>
          <FlexColumn>
            <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
              {localized('UserProfile')}
            </TextLabel>
            <TextLabel variant="body1" marginBottom={3}>
              {match !== null ? localized('SaveUserFormSubTitle') : localized('CreateUserFormSubTitle')}
            </TextLabel>
            <TextLabel variant="h6" fontWeight="bold">
              {localized('PersonalInformation')}
            </TextLabel>
          </FlexColumn>
        </FlexRow>
        <Form onSubmit={save} sx={{maxWidth: 400}} defaultValues={defaultValues}>
          <TextInput name="name" label={localized('Name')} validators={requiredValidator} />
          <TextInput name="email" label={localized('Email')} validators={requiredValidator} disabled={match !== null} />
          {isGlobalAdmin && <CheckboxInput name="role" label={localized('GlobalAdmin')} />}
          <FlexRow gap={2}>
            <BasicButton
              type="submit"
              buttonColor={'primary'}
              disabled={isLoading}
              text={localizedInterpolation(match !== null ? 'SaveInt' : 'CreateNewInt', 'User')}
            />
            <BasicButton backButton buttonColor={'secondary'} text={localized('Cancel')} />
            {isLoading && <BasicSpinner size={35} />}
          </FlexRow>
        </Form>
      </BasicCard>
      {match && <RemoveMachineCard />}
    </FlexRow>
  );
});
