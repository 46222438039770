import { FileResponse } from 'api/api';
import React, { useRef, useState } from 'react';

// Taken from https://www.techprescient.com/react-custom-hook-typescript-to-download-a-file-through-api/
export interface DownloadFileProps {
  readonly apiDefinition: () => Promise<FileResponse | null | undefined>;
  readonly preDownloading?: () => void;
  readonly postDownloading?: () => void;
  readonly onError?: () => void;
  namePostfix?: () => string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly name: string | undefined;
  readonly url: string | undefined;
  readonly isLoading: boolean;
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  namePostfix,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();

  const download = async () => {
    try {
      setIsLoading(true);
      preDownloading && preDownloading();
      const {data, fileName} = (await apiDefinition()) as FileResponse;
      const url = URL.createObjectURL(data instanceof Blob ? data : new Blob([data]));
      setFileUrl(url);

      let decodedFileName = decodeURIComponent(fileName?.replaceAll('+', ' ') ?? '');

      if (namePostfix) {
        var fileNameAndExtension = decodedFileName.split(/\.(?=[^.]+$)/);
        decodedFileName = `${fileNameAndExtension[0]}${namePostfix()}.${fileNameAndExtension[1]}`
      }

      setFileName(decodedFileName);
      ref.current?.click();
      postDownloading && postDownloading();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      onError && onError();
    } finally {
      setIsLoading(false);
    }
  };

  return {download, ref, url, name, isLoading};
};
