import {css, SerializedStyles} from '@emotion/react';
import styled from '@emotion/styled';
import {Stack, Theme} from '@mui/material';
import Typography, {TypographyProps} from '@mui/material/Typography';
import {localized} from 'i18n/i18n';

import React from 'react';

type Variant = 'small' | 'large';

interface Props {
  label: string;
  placeholder: string;
  variant?: Variant;
}

const smallStyles = (theme: Theme) => css`
  display: table-row;
  flex-direction: row;

  & > * {
    display: table-cell;
    /* border: 1px solid grey; */
  }
`;

const largeStyles = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  row-gap: ${theme.spacing(1)};
  padding: ${theme.spacing(3)} 0px;
`;

const variantMapper: Record<Variant, (theme: Theme) => SerializedStyles> = {
  small: smallStyles,
  large: largeStyles,
};

const placeHolderStylesMapper: Record<Variant, TypographyProps> = {
  large: {
    variant: 'h1',
  },
  small: {
    variant: 'body2',
    textAlign: 'end',
    paddingRight: 2,
  },
};

const StatisticItemRoot = styled(Stack)<{variant: Variant}>`
  ${({variant, theme}) => variantMapper[variant](theme)};
`;

export const StatisticItem: React.FC<Props> = (props) => {
  const {label, placeholder, variant = 'small'} = props;

  return (
    <StatisticItemRoot variant={variant}>
      <Typography {...placeHolderStylesMapper[variant]}>{placeholder}</Typography>
      <Typography variant="body3">{label}</Typography>
    </StatisticItemRoot>
  );
};

export const getValueOrUnknown = (value: number | undefined): string => {
  return value !== undefined && value !== null ? value.toString() : localized('Unknown');
};
