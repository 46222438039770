import {Box, SxProps, useMediaQuery} from '@mui/material';
import FlexoWashLogo from 'assets/images/flexowash.png';
import {useAuth} from 'auth/auth-provider';
import {localized} from 'i18n/i18n';
import {FC, memo, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'state/store';
import {whiteColor} from 'styles/color-constants';
import {theme} from 'styles/theme';
import {AppStoreLink, PlayStoreLink} from 'utilities/constants';
import {Images} from 'utilities/images';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {FlexColumn} from 'view/components/default-components/flex-column';
import {FlexRow} from 'view/components/default-components/flex-row';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import {TextLabel} from 'view/components/default-components/text-label';
import background from '../../../assets/images/signin_background_image.png';
import {SignUpForm} from './sign-up-form';

const backgroundSx: SxProps = {
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100vh',
};

const buttonSx: SxProps = {minWidth: 350, mt: 2};
export const LandingPage: FC = memo(() => {
  const {login} = useAuth();
  const [showForm, setShowForm] = useState<boolean>(false);
  const {pending} = useSelector((store: AppState) => store.authReducer);
  const match = useMediaQuery(theme.breakpoints.up('md'));

  const onPlayStoreClick = useCallback(() => {
    window.location.href = PlayStoreLink;
  }, []);

  const onAppStoreClick = useCallback(() => {
    window.location.href = AppStoreLink;
  }, []);

  return (
    <Box sx={match ? backgroundSx : {}}>
      <FlexColumn
        sx={{
          backgroundColor: whiteColor,
          maxWidth: 450,
          width: '100%',
          position: match ? 'absolute' : 'unset',
          right: 0,
          height: '100%',
          paddingTop: 10,
        }}>
        <Box sx={{display: 'flex', justifyContent: 'center', mb: 20}}>
          <img src={FlexoWashLogo} alt="Logo" />
        </Box>
        {pending ? (
          <BasicSpinner />
        ) : showForm ? (
          <SignUpForm hideForm={() => setShowForm(false)} />
        ) : (
          <FlexColumn alignItems={'center'}>
            <BasicButton sx={buttonSx} text={localized('SignIn')} onClick={login} />
            <BasicButton sx={buttonSx} text={localized('SignUp')} onClick={() => setShowForm(true)} />

            <FlexColumn alignItems={'center'} mt={8} position={'absolute'} bottom={24}>
              <TextLabel fontSize={16} textAlign={'center'}>
                {localized('TryOurAppMeta')}
              </TextLabel>
              <FlexRow>
                <Box
                  component={'img'}
                  src={Images.PlayStoreBadge}
                  width={match ? 200 : '48vw'}
                  sx={{cursor: 'pointer'}}
                  onClick={onPlayStoreClick}
                />
                <Box
                  component={'img'}
                  src={Images.AppStoreBadge}
                  width={match ? 160 : '38vw'}
                  marginLeft={1}
                  marginRight={1}
                  sx={{cursor: 'pointer'}}
                  onClick={onAppStoreClick}
                />
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        )}
      </FlexColumn>
    </Box>
  );
});
