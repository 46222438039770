import {Button, DialogActions, DialogTitle} from '@mui/material';
import {ReactNode, createContext, memo, useCallback, useContext, useRef, useState} from 'react';
import {BasicModal} from 'view/components/default-components/modal/basic-modal';
import {localized} from '../i18n/i18n';

interface IDialogContext {
  openDeleteDialog: (title: string, onConfirm: () => void, onCancel?: () => void) => void;

  openConfirmDialog: (title: string, onConfirm: () => void, onCancel?: () => void) => void;
}

const initialState: IDialogContext = {
  openDeleteDialog: () => {},
  openConfirmDialog: () => {},
};

interface PropsFromParent {
  children: ReactNode;
}

export const DialogContext = createContext(initialState);

export const useDialog = (): IDialogContext => useContext(DialogContext);

export const DialogProvider = memo<PropsFromParent>(({children}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [confirmText, setConfirmText] = useState<string>();
  const onConfirmCallback = useRef<() => void>(() => {});
  const onCancelCallback = useRef<(() => void) | undefined>(undefined);

  const openDeleteDialog = useCallback((title: string, onConfirm: () => void, onCancel?: () => void) => {
    onConfirmCallback.current = onConfirm;
    onCancelCallback.current = onCancel;
    setTitle(title);
    setConfirmText(localized('Delete'));
    setShowDialog(true);
  }, []);

  const openConfirmDialog = useCallback((title: string, onConfirm: () => void, onCancel?: () => void) => {
    onConfirmCallback.current = onConfirm;
    onCancelCallback.current = onCancel;
    setTitle(title);
    setConfirmText(localized('Confirm'));
    setShowDialog(true);
  }, []);

  const onDismiss = useCallback(() => {
    setShowDialog(false);
    onCancelCallback.current = undefined;
  }, []);

  const onConfirmLocal = useCallback(() => {
    onConfirmCallback.current();
    onDismiss();
  }, [onDismiss]);

  const onCancelLocal = useCallback(() => {
    onCancelCallback.current && onCancelCallback.current();
    onDismiss();
  }, [onDismiss]);

  return (
    <DialogContext.Provider value={{openDeleteDialog, openConfirmDialog}}>
      {children}
      <BasicModal open={showDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogActions>
          <Button variant="contained" onClick={onCancelLocal} color={'secondary'}>
            {localized('Cancel')}
          </Button>
          <Button variant="contained" onClick={onConfirmLocal} color={'error'}>
            {confirmText}
          </Button>
        </DialogActions>
      </BasicModal>
    </DialogContext.Provider>
  );
});
