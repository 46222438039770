import {SxProps, Tooltip} from '@mui/material';
import {Box} from '@mui/system';
import React, {useCallback, useMemo} from 'react';
import {BasicIcon} from '../basic-icon';
import {localized} from '../../../../i18n/i18n';
import {theme} from '../../../../styles/theme';
import {genericMemo} from '../../../../utilities/generic-memo';
import {normalizeOutput} from '../../../../utilities/platform-helpers/type-helper';
import {FlexRow} from '../flex-row';
import {ActionConfig, IdBase} from './list-table-types';

interface Props<T> {
  onDelete?: ActionConfig<T> | ((row: T) => void);
  onEdit?: ActionConfig<T> | ((row: T) => void);
  actions?: ActionConfig<T>[];
  row: T;
}
const iconAdjust: number = 1;
export const ListTableActionColumn = genericMemo(<T extends IdBase>(props: Props<T>) => {
  const onEditLocal = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!props.onEdit) return;
      event.stopPropagation();

      if (typeof props.onEdit === 'function') props.onEdit(props.row);
      else props.onEdit.onClick?.(props.row);
    },
    [props],
  );

  const onDeleteLocal = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!props.onDelete) return;
      event.stopPropagation();

      if (typeof props.onDelete === 'function') props.onDelete(props.row);
      else props.onDelete.onClick?.(props.row);
    },
    [props],
  );

  const onActionClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, action: ActionConfig<T>) => {
      event.stopPropagation();
      action.onClick?.(props.row);
    },
    [props.row],
  );

  const actionElements = useMemo(
    () =>
      props.actions?.map((action, index) => (
        <Tooltip
          key={'action' + index}
          title={action.label ?? ''}
          sx={
            [
              action.onClick && {
                cursor: 'pointer',
              },
              action.containerSx,
            ] as SxProps
          }
          onClick={(event) => onActionClick(event, action)}>
          <Box display={'flex'} alignItems={'center'}>
            {normalizeOutput(action.icon, props.row)}
          </Box>
        </Tooltip>
      )),
    [onActionClick, props.actions, props.row],
  );

  return (
    <FlexRow gap={2} justifyContent={'flex-end'} alignItems={'center'}>
      {actionElements}
      {props.onEdit && (
        <Tooltip title={localized('Edit')} sx={{cursor: 'pointer'}} onClick={onEditLocal}>
          <Box>
            {typeof props.onEdit === 'function' ? (
              <BasicIcon icon="edit" sx={{color: theme.palette.primary.main, mt: iconAdjust}} />
            ) : (
              normalizeOutput(props.onEdit?.icon, props.row) ?? (
                <BasicIcon
                  icon="edit"
                  sx={{color: props.onEdit?.iconColor ?? theme.palette.primary.main, mt: iconAdjust}}
                />
              )
            )}
          </Box>
        </Tooltip>
      )}
      {props.onDelete && (
        <Tooltip title={localized('Delete')} sx={{cursor: 'pointer'}} onClick={onDeleteLocal}>
          <Box>
            {typeof props.onEdit === 'function' ? (
              <BasicIcon icon="delete" sx={{color: theme.palette.primary.main, mt: iconAdjust}} />
            ) : (
              normalizeOutput(props.onEdit?.icon, props.row) ?? (
                <BasicIcon
                  icon="delete"
                  sx={{color: props.onEdit?.iconColor ?? theme.palette.primary.main, mt: iconAdjust}}
                />
              )
            )}
          </Box>
        </Tooltip>
      )}
    </FlexRow>
  );
});
