import {Box} from '@mui/material';
import React, {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectAllAgents, useDeleteAgentMutation} from 'state/features/api/agents-slice';
import {defaultVirtualizedListTableHeight} from 'styles/styling-constants';
import {localized} from '../../../../i18n/i18n';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {filterListItems} from '../../../../utilities/helpers/general-helpers';

import {AgentDTO, IAgentDTO} from 'api/api';
import {useDialog} from 'providers/dialog-provider';
import {useNavigate} from 'react-router-dom';
import {routes} from 'state/routes';
import GroupIcon from 'view/components/default-components/icons/group-icon';
import MachineIcon from 'view/components/default-components/icons/machine-icon';
import UserIcon from 'view/components/default-components/icons/user-icon';
import {ActionConfig, ColumnDef} from 'view/components/default-components/list-table/list-table-types';
import {VirtualizedListTable} from 'view/components/default-components/list-table/virtual-list-table';

const columns: ColumnDef<IAgentDTO>[] = [
  {field: 'name', header: localized('Name')},
  {
    field: 'machineIds',
    header: localized('Machines'),
    maxWidth: 150,
    customComponent: (agent) => {
      return (
        <Box sx={{alignItems: 'center', display: 'flex'}}>
          <Box sx={{marginRight: 1}}>{agent.machineIds?.length}</Box>
          <MachineIcon />
        </Box>
      );
    },
  },
  {
    field: 'userIds',
    header: localized('Users'),
    maxWidth: 150,
    customComponent: (agent) => {
      return (
        <Box sx={{alignItems: 'center', display: 'flex'}}>
          <Box sx={{marginRight: 1}}>{agent.userIds?.length}</Box>
          <UserIcon />
        </Box>
      );
    },
  },
];

export const AgentsListView: FC = React.memo(() => {
  const agents = useSelector(selectAllAgents);
  const pageSetting = useSelector(selectPageSettingByKey('userManagement'));
  const navigate = useNavigate();
  const {openDeleteDialog} = useDialog();
  const [deleteAgent] = useDeleteAgentMutation();

  const onEditAgentClick = useCallback(
    (agent: IAgentDTO) => {
      navigate(`${routes.agent}/${agent.id}/edit`);
    },
    [navigate],
  );

  const goToAgentUsers = useCallback(
    (agent: IAgentDTO) => {
      navigate(`${routes.agent}/${agent.id}/users`);
    },
    [navigate],
  );

  const handleDialogDelete = useCallback(
    (agent: IAgentDTO) => {
      deleteAgent(agent as AgentDTO);
    },
    [deleteAgent],
  );

  const onAgentDeleteClick = useCallback(
    (agent: IAgentDTO) => {
      openDeleteDialog(localized('ConfirmDeleteAgent'), () => handleDialogDelete(agent));
    },
    [handleDialogDelete, openDeleteDialog],
  );

  const items = useMemo(() => {
    return filterListItems(agents, ['name'], pageSetting?.searchString);
  }, [pageSetting, agents]);

  const actions: ActionConfig<IAgentDTO>[] = [
    {
      icon: <GroupIcon sx={{mt: 1.25, mr: 1}} />,
      label: localized('Users'),
      onClick: goToAgentUsers,
    },
  ];

  return (
    <Box>
      <VirtualizedListTable<IAgentDTO>
        height={defaultVirtualizedListTableHeight}
        rows={items}
        columns={columns}
        defaultOrderBy={'name'}
        actionHeader={localized('Edit')}
        onEdit={onEditAgentClick}
        onDelete={onAgentDeleteClick}
        actions={actions}
      />
    </Box>
  );
});
