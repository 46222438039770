import {Box, SxProps, Theme, Typography} from '@mui/material';
import {ConnectionState, IMachineDTO, MachineState as MachineStateEnum} from 'api/api';
import {localizedDynamic} from 'i18n/i18n';
import {FC, memo} from 'react';
import {MachineStateMapper} from 'utilities/mappers/machine-state-mapper';

const iconStyles: SxProps<Theme> = {color: 'common.flexoWashBlueLight', fontSize: '1.2rem', marginRight: '10px'};

interface Props {
  machine: IMachineDTO;
}

export const MachineStatusCell: FC<Props> = memo(({machine}) => {
  const machineState =
    machine.currentState?.machineState !== undefined &&
    machine.currentState?.connectionState !== ConnectionState.Offline
      ? machine.currentState?.machineState
      : MachineStateEnum.Unknown;

  const {stateTranslationKey: stateStatus, icon: StateIcon} = MachineStateMapper[machineState];
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <StateIcon sx={iconStyles} />
      <Typography variant="body3" color="common.flexoWashBlue">
        {localizedDynamic(stateStatus)}
      </Typography>
    </Box>
  );
});
