import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const OfflineIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 42 42">
      <circle cx="21" cy="21" r="21" fill="#cb4600" />
    </SvgIcon>
  );
};

export default React.memo(OfflineIcon);
