import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const GroupIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 29.354 24.7">
      <g id="Group_41" data-name="Group 41" transform="translate(-39.184 -524.441)">
        <path
          id="Path_75"
          data-name="Path 75"
          d="M12.684,159.4a6.377,6.377,0,1,0-5.793,0A9.837,9.837,0,0,0,0,167.85a.722.722,0,0,0,.665.788H18.906a.722.722,0,0,0,.669-.719c0-.023,0-.046,0-.07a9.837,9.837,0,0,0-6.887-8.447"
          transform="translate(39.184 380.503)"
        />
        <path
          id="Path_76"
          data-name="Path 76"
          d="M629.835,20.509a9.837,9.837,0,0,0-6.887-8.448,6.378,6.378,0,1,0-8.15-9.3.63.63,0,0,0,.136.861,7.73,7.73,0,0,1,.9,11.534,11.23,11.23,0,0,1,4.9,5.739.625.625,0,0,0,.585.4h7.856a.722.722,0,0,0,.669-.719c0-.023,0-.046,0-.07"
          transform="translate(-561.301 524.44)"
        />
      </g>
    </SvgIcon>
  );
};

export default React.memo(GroupIcon);
