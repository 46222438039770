import {Box, Stack, SxProps, Theme, Typography} from '@mui/material';
import {ITank, TankLevel} from 'api/api';
import {localized, localizedDynamic} from 'i18n/i18n';
import {FC} from 'react';
import TankStatusLevel from 'view/components/default-components/tank-status-level/tank-status-level';
import TankStatusTemperature from 'view/components/default-components/tank-status-temperature/tank-status-temperature';

const svgStyles: SxProps<Theme> = {fontSize: '6rem', width: 'auto', height: '1em'};

type TankLevelDescriptionMapper = Record<TankLevel, string>;

const tankLevelDescriptionMapper: TankLevelDescriptionMapper = {
  [TankLevel.BelowOk]: localizedDynamic('TankLevel.BelowOK'),
  [TankLevel.LevelOk]: localizedDynamic('TankLevel.LevelOk'),
  [TankLevel.MaximumLevel]: localizedDynamic('TankLevel.Maximum'),
  [TankLevel.MinimumLevel]: localizedDynamic('TankLevel.Minimum'),
};

interface Props {
  tank?: ITank | undefined;
  tankNumber: number;
}

const TankStatus: FC<Props> = (props) => {
  const {tank, tankNumber} = props;

  if (tank === undefined) {
    return null;
  }

  return (
    <Stack direction="row" columnGap={2} alignItems="flex-end">
      <Typography>{tank.temperature} °C</Typography>
      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Typography marginBottom={1} minWidth={70} textAlign={'center'}>
          {localized('Tank') + ' ' + tankNumber}
        </Typography>
        <Stack flexDirection="row" columnGap={1}>
          <TankStatusTemperature tempStatus={tank.tempStatus} sx={svgStyles} />
          <TankStatusLevel sx={svgStyles} tankLevel={tank.level} />
        </Stack>
      </Box>
      <Typography>{tankLevelDescriptionMapper[tank.level]}</Typography>
    </Stack>
  );
};

export default TankStatus;
