import {Box, SxProps} from '@mui/material';
import {once} from 'lodash';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {genericMemo} from '../../../../utilities/generic-memo';
import {ListTableHeader} from './list-table-header';
import {ListTableRow} from './list-table-row';
import {IListTableContext, IdBase, IdBaseType, ListTableProps, Order} from './list-table-types';
import {appendActionColumn, filterRows, getActionCount, getSkeletonElements, sortRows} from './list-table-util';

export const createListTableContext = once(<T extends IdBase>() => createContext({} as IListTableContext<T>));

export const useListTableInternal = <T extends IdBase>(): IListTableContext<T> =>
  useContext(createListTableContext<T>());

export const ListTable = genericMemo(<T extends IdBase>(props: ListTableProps<T>) => {
  const ListTableContext = createListTableContext<T>();
  const pageSetting = useSelector(selectPageSettingByKey(props.pageSettingKey));
  const [order, setOrder] = useState<Order>(props.defaultOrderDirection ? props.defaultOrderDirection : 'asc');
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(props.defaultOrderBy);
  const [selectedIds, setSelectedIds] = useState<IdBaseType[]>([]);
  const [searchParams] = useSearchParams();

  const localStyles = useMemo(() => getListStyles(props, selectedIds.length), [props, selectedIds.length]);

  useEffect(() => {
    setSelectedIds([]);
  }, [props.rows]);

  const filteredRows = useMemo(() => {
    return filterRows(props.rows, searchParams, props.filterConfigs, pageSetting?.searchString, props.searchKeys);
  }, [pageSetting?.searchString, props.filterConfigs, props.rows, props.searchKeys, searchParams]);

  const sortedRows = useMemo(
    () => sortRows(filteredRows, props.columns, order, orderBy),
    [filteredRows, order, orderBy, props.columns],
  );
  const actionCount = useMemo(() => getActionCount(props), [props]);
  const skeletonElements = useMemo(() => getSkeletonElements(props.skeletonAmount), [props.skeletonAmount]);

  const columns = useMemo(
    () => appendActionColumn(props.columns, actionCount, props.actionHeader),
    [props.columns, props.actionHeader, actionCount],
  );

  const listElements = useMemo(
    () => sortedRows.map((row) => <ListTableRow key={row.id} row={row} onRowClick={props.onRowClick} />),
    [props.onRowClick, sortedRows],
  );

  return (
    <ListTableContext.Provider
      value={{
        columns,
        rows: sortedRows,
        loading: props.loading,
        selectedIds,
        order,
        orderBy,
        setSelectedIds,
        setOrder,
        setOrderBy,
        onEdit: props.onEdit,
        onDelete: props.onDelete,
        actions: props.actions,
        actionCount,
        checkbox: props.checkbox,
        checkboxActions: props.checkboxActions,
      }}>
      <ListTableHeader scrollPage={props.scrollPage} headerBackgroundColor={props.headerBackgroundColor} />
      <Box sx={localStyles.listContainer}>{props.loading ? skeletonElements : listElements}</Box>
    </ListTableContext.Provider>
  );
});

const getListStyles = <T,>(props: ListTableProps<T>, selectedItemsCount: number): {[key: string]: SxProps} => ({
  listContainer: {
    overflowY: !props.scrollPage ? 'scroll' : undefined,
    overflowX: !props.scrollPage ? 'visible' : undefined,
    minWidth: 'fit-content',
    marginRight: !props.scrollPage ? -4 : undefined,
    paddingRight: !props.scrollPage ? 4 : undefined,
    height: !props.scrollPage ? 'calc(' + props.height + (selectedItemsCount ? ' - 70px' : '') + ')' : undefined,
  },
});
