import { SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

interface Props extends TypographyProps {
  sx?: SxProps<Theme>;
}

export const TextLabel: FC<Props> = React.memo((props) => {
  return (
    <Typography sx={props.sx} color="text.secondary" textOverflow="ellipsis" {...props}>
      {props.children}
    </Typography>
  );
});
