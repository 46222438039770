import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {Box} from '@mui/material';
import React, {FC, memo, useCallback} from 'react';
import {BasicSpinner} from '../../../components/default-components/spinner/basic-spinner';
import { IUserDTO, UserDTO } from 'api/api';
import { useResendInvitationMutation } from 'state/features/api/user-slice';

interface Props {
  user: IUserDTO;
}

export const UserResendInvitationAction: FC<Props> = memo((props) => {
  const [resendInvitation, {isLoading}] = useResendInvitationMutation();

  const handleClick = useCallback(() => {
    resendInvitation(props.user as UserDTO);
  }, [props.user, resendInvitation]);

  if (props.user.authId) return null;

  if (isLoading)
    return (
      <Box mr={0.5}>
        <BasicSpinner size={24} />
      </Box>
    );

  return <EmailOutlinedIcon color="primary" sx={{mt: 0.5, mr: 0.5}} onClick={handleClick} />;
});
