import {Divider, Stack} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {genericMemo} from '../../../../utilities/generic-memo';
import {distinctProperties} from '../../../../utilities/helpers/general-helpers';
import {FlexRow} from '../flex-row';
import {FilterPanelChip} from './filter-panel-chip';
import {FilterPanelRow} from './filter-panel-row';
import {FilterProps} from './filter-panel-types';
import {useFilterPanelContextInternal} from '../page-header.tsx/page-header-filter';

const defaultChipHeight: number = 62;

export const FilterPanelContent = genericMemo(<T,>({filterConfigs, data}: FilterProps<T>) => {
  const chipRef = useRef<HTMLDivElement>(null);
  const [chipOffset, setChipOffset] = useState<number>(defaultChipHeight);
  const [activePanel, setActivePanel] = React.useState<string | undefined>(undefined);
  const {selectedValues} = useFilterPanelContextInternal();

  const onPanelOpen = useCallback((panel?: string) => {
    setActivePanel(panel);
  }, []);

  const elements = useMemo(
    () =>
      filterConfigs.map((filter) => {
        return (
          <FilterPanelRow
            heightOffset={chipOffset + filterConfigs.length * 50}
            key={filter.field.toString()}
            onOpen={onPanelOpen}
            activePanel={activePanel}
            filterConfig={filter}
            options={distinctProperties(data, filter.field, filter.valueFormatter)}
          />
        );
      }),
    [activePanel, chipOffset, data, filterConfigs, onPanelOpen],
  );

  const chipElements = useMemo(() => {
    return filterConfigs.flatMap((filter) => {
      return selectedValues[filter.field.toString()].map((v) => (
        <FilterPanelChip key={v} value={v} field={filter.field.toString()} />
      ));
    });
  }, [filterConfigs, selectedValues]);

  useEffect(() => {
    // When selected values change update the height offset the calculation done in panel rows
    setChipOffset(chipRef.current?.clientHeight ?? 0);
  }, [chipRef.current?.clientHeight, selectedValues]);

  return (
    <>
      <FlexRow
        boxref={chipRef}
        sx={{flexWrap: 'wrap', paddingBottom: 1, paddingTop: 2, minHeight: defaultChipHeight, gap: 1}}>
        {chipElements}
      </FlexRow>
      <Stack divider={<Divider sx={{borderBottomWidth: 2}} />}>{elements}</Stack>
    </>
  );
});
