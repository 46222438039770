import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const MachineIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 27.987 33.209">
      <g transform="translate(0 -0.001)">
        <path
          id="Path_65"
          data-name="Path 65"
          d="M10.368,48.342l18.985-2-7.1-2.223-19.8.438Z"
          transform="translate(-1.786 -32.129)"
        />
        <path
          id="Path_66"
          data-name="Path 66"
          d="M102.929,52.886l.009-.122-.023-.007Z"
          transform="translate(-74.951 -38.421)"
        />
        <path
          id="Path_67"
          data-name="Path 67"
          d="M.639,63.908,8.15,67.971,27.222,59.7l.769-9.994L8.5,51.763.068,47.731Z"
          transform="translate(-0.049 -34.761)"
        />
        <path id="Path_68" data-name="Path 68" d="M1.907,0,.56.762,0,11.567l20.263-.144L22.357,3.9Z" />
      </g>
    </SvgIcon>
  );
};

export default React.memo(MachineIcon);
