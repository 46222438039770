import {StyledEngineProvider, Theme, ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {SnackbarProvider} from 'notistack';
import {DialogProvider} from 'providers/dialog-provider';
import React, {FC, useCallback} from 'react';
import {Provider} from 'react-redux';
import {RouterProvider} from 'react-router-dom';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import useLanguageRenderer from 'view/components/specific-components/use-language-renderer/use-language-renderer';
import {router} from './app-routes';
import {GeneralAuthProvider} from './auth/auth-provider';
import {store} from './state/store';
import {theme} from './styles/theme';
import {Snackbar} from './view/components/default-components/snackbar/snackbar';
import {SnackbarCloseButton} from './view/components/default-components/snackbar/snackbar-close-button';
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

// Persist the whole store
const persistor = persistStore(store);

const LanguageRerenderer = (props: {children: React.ReactNode}) => {
  const language = useLanguageRenderer();

  return <div key={language}>{props.children}</div>;
};

export const App: FC = () => {
  const getCloseButton = useCallback((key: number | string) => {
    return <SnackbarCloseButton snackKey={key as number} />;
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LanguageRerenderer>
              <DialogProvider>
                <SnackbarProvider maxSnack={4} action={getCloseButton}>
                  <GeneralAuthProvider>
                    <RouterProvider router={router} fallbackElement={<BasicSpinner size={100} />} />
                    <CssBaseline />
                    <Snackbar />
                  </GeneralAuthProvider>
                </SnackbarProvider>
              </DialogProvider>
            </LanguageRerenderer>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
};
