import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import {BottomNavigation, BottomNavigationAction, Box} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, {FC, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import MachineIcon from 'view/components/default-components/icons/machine-icon';
import {useLayoutStyles} from '../../../app-routes';
import {useAuth} from '../../../auth/auth-provider';
import {localized} from '../../../i18n/i18n';
import {routes} from '../../../state/routes';
import {basicCardBoxShadow} from '../../../styles/color-constants';

const StyledBottomNavigation = withStyles({
  root: {
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    boxShadow: `0px -4px 6pt ${basicCardBoxShadow}`,
  },
})(BottomNavigation);

export const AppDrawer: FC = React.memo(() => {
  const {isAdmin, isGlobalAdmin} = useAuth();
  const [item, setItem] = useState<string>('');
  const navigate = useNavigate();
  let location = useLocation();
  const layoutClasses = useLayoutStyles();

  useEffect(() => {
    setItem(location.pathname);
  }, [location]);

  const onChangeEvent = (event: React.ChangeEvent<{}>, newValue: string) => {
    navigate(`${newValue}`);
    setItem(newValue);
  };

  return (
    <>
      <StyledBottomNavigation
        value={item}
        onChange={onChangeEvent}
        showLabels={true}
        className={layoutClasses.mobileOnly}>
        <Box height={56} />
        <BottomNavigationAction value={routes.machines} label={localized('Machines')} icon={<MachineIcon />} />
        {/* {isAdmin && ( */}
        <BottomNavigationAction value={routes.userManagement} label={localized('Users')} icon={<GroupIcon />} />
        {/* )} */}
        {isGlobalAdmin && (
          <BottomNavigationAction
            value={routes.organization}
            label={localized('Organization')}
            icon={<BusinessIcon />}
          />
        )}
      </StyledBottomNavigation>
    </>
  );
});
