import {changeLanguage} from 'i18n/i18n';
import React from 'react';
import {useSelector} from 'react-redux';
import {selectedLanguage} from 'state/features/settings/settings-slice';

// Ensures rerendering when language changes

const useLanguageRenderer = () => {
  const language = useSelector(selectedLanguage);
  const [lng, setLng] = React.useState(language);

  React.useEffect(() => {
    const changeLang = async () => {
      if (language !== undefined) {
        await changeLanguage(language);
        setLng(language);
      }
    };

    changeLang();
  }, [language]);

  return lng;
};

export default useLanguageRenderer;
