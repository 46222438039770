import {IMachineStatisticDTO} from 'api/api';
import {machineStatisticsClient} from 'state/api-clients';
import {apiSlice} from './api-slice';

export const machineStatisticsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMachineStatistics: builder.query<IMachineStatisticDTO, number | undefined>({
      query: (machineId) =>() =>  machineStatisticsClient.getMachineStatistics(machineId),
    }),
  }),
});

export const {useGetMachineStatisticsQuery} = machineStatisticsSlice;
