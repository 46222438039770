// Theme colors
export const primaryColor = '#212B55';
export const primaryTextColor = '#333333';
export const flexoWashBlueLight = '#7A9CC5';
export const lightBlue = 'rgba(218, 227, 242, 0.5)';
export const secondaryColor = '#E7E7E7';
export const secondaryTextColor = '#212B55';

export const backgroundColor = '#FFFFFF';
export const validColor = '#31FF00';
export const invalidColor = '#f44336';
export const warningColor = '#FDB900';
export const whiteColor = '#FFFFFF';
export const blackColor = '#000000';
export const alarmColor = '#CB4600';
export const containerBackground = '#DDDDDD';
export const containerBackgroundHover = '#CCCCCC';
// Component colors

// Header
export const headerBackgroundColor = lightBlue;

// Sidebar
export const sidebarBackgroundColor = '#212B55';
export const sidebarIconColor = '#ED6923';
export const sidebarBackgroundSelectedColor = '#FFFFFF';
export const sidebarSelectedBorder = '#ED6923';
export const sideBarProfileBackgroundColor = '#212B55';
export const sidebarToggleBarColor = '#4d5577';

// Content Wrapper

// Basic Card
export const basicCardBgColor = '#FFFFFF';
export const basicCardBoxShadow = '0px 3px 6px #00000029';
// Button
export const buttonBoxShadow = '#0000001A';
export const cancelButtonColor = '#AAAAAA';
export const cancelButtonColorHover = '#BBB';

// Input

// Toggle

// Switch

// Info item

// Textfield

// Checkbox Filter
export const checkboxFilterBg = '#FAFAFA';

// SearchInput
export const searchBgColor = '#FFFFFF';

// List Table

// Info item 2
export const infoItem2BackgroundColor = '#FAFAFA';
export const activeAlarmListBackgroundColor = '#ecf1f8';
export const primaryFontColor = '#212B55';
export const secondaryFontColor = '#7A9CC5';

export const onlineColor = '#9DB500';
export const offlineColor = '#CB4600';

// Excel Download Button
export const excelDownloadButtonColor = 'grey.600';
