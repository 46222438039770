import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'state/store';
import {ActiveColumnState} from './types';
import {getDefaultMachineOverviewColumns} from './utils';

const initialState: ActiveColumnState = {
  activeColumns: [...getDefaultMachineOverviewColumns().keys()],
};

export const activeColumnsSlice = createSlice({
  name: 'activeColumns',
  initialState: initialState,
  reducers: {
    setActiveColumns: (state, action: PayloadAction<string[]>) => {
      state.activeColumns = action.payload;
    },
  },
});

export const selectActiveColumns = (store: AppState) => store.activeColumnsReducer.activeColumns;
export const {setActiveColumns} = activeColumnsSlice.actions;
export default activeColumnsSlice.reducer;
