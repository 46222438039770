import {Skeleton, SxProps} from '@mui/material';
import dayjs from 'dayjs';
import React, {ReactNode} from 'react';
import {theme} from '../../../../styles/theme';
import {filterListItems} from '../../../../utilities/helpers/general-helpers';
import {FilterConfig} from '../filter-panel/filter-panel-types';
import {ListTableActionColumn} from './list-table-action-column';
import {ActionConfig, ColumnDef, IdBase, ListTablePropsBase, Order} from './list-table-types';

export const getColumnMaxWidth = (column: ColumnDef<any>, actionCount: number): number | undefined => {
  // As the amount of actions are dynamic the width of the column needs to scale
  const actionWidth = actionCount * 40;

  // Apply the max width for action column
  return column.field === 'action' ? (actionWidth > 100 ? actionWidth : 100) : column.maxWidth;
};

export const getColumnMinWidth = (column: ColumnDef<any>, actionCount: number): number | undefined => {
  // As the amount of actions are dynamic the width of the column needs to scale
  const actionWidth = actionCount * 40;

  // Apply the min width for action column
  let minWidth = column.field === 'action' ? (actionWidth > 100 ? actionWidth : 100) : column.minWidth;

  minWidth = minWidth ?? 150;

  const maxWidth = getColumnMaxWidth(column, actionCount);
  return maxWidth !== undefined && maxWidth < 150 ? maxWidth : minWidth;
};

export const getColumnFormattedValue = <T extends IdBase>(
  column: ColumnDef<T>,
  row: any,
  onDelete?: ActionConfig<T> | ((row: T) => void),
  onEdit?: ActionConfig<T> | ((row: T) => void),
  actions?: ActionConfig<T>[],
): any => {
  // In case of action columns we use the component
  if (column.field === 'action') {
    return <ListTableActionColumn row={row} onDelete={onDelete} onEdit={onEdit} actions={actions} />;
  }

  // If the column is not an action column.
  return column.valueFormatter ? column.valueFormatter(row) : (row[column.field] as any);
};

export const getColumnStyle = (column: ColumnDef<any>): SxProps => {
  const indexStyle = column.style ? column.style : undefined;
  const style: SxProps = indexStyle ? ([textStyle, indexStyle] as SxProps) : textStyle;
  return style;
};

const textStyle: SxProps = {
  fontSize: theme.typography.body2.fontSize,
};

export const VirutalListItemSize: number = 64;

export const sortListRows = <T,>(items: T[], columns: ColumnDef<T>[], orderBy: keyof T, order: Order) => {
  const column = columns.find((c) => c.field === orderBy);
  const sortedArray = items.concat().sort((a, b) => {
    // Use customSort function if one has been defined
    if (column?.customSort) return column.customSort(a, b);

    // If a value formatter exists use it else use normal value
    const aVal = column?.valueFormatter ? column.valueFormatter(a) : a[orderBy];
    const bVal = column?.valueFormatter ? column.valueFormatter(b) : b[orderBy];

    // LocaleCompare only works as intended if you provide it with a locale (i.e 'da' or 'en').
    // We use dayjs().locale() to extract the current locale. The locale is updated if the language is updated through the changeLanguage function in the i81n.tsx file.
    return ('' + aVal).localeCompare('' + bVal, dayjs().locale());
  });

  if (order === 'desc') {
    sortedArray.reverse();
  }

  return sortedArray;
};

export const getSkeletonElements = (skeletonAmount: number = 3): ReactNode => {
  return Array.from(Array(skeletonAmount).keys()).map((key) => {
    return (
      <Skeleton
        key={'skeleton' + key}
        sx={{height: VirutalListItemSize, width: '100%', transform: 'none', mb: 1}}
        animation="wave"
      />
    );
  });
};

export const getActionCount = <T,>(props: ListTablePropsBase<T>): number => {
  return (props.actions?.length ?? 0) + (props.onEdit ? 1 : 0) + (props.onDelete ? 1 : 0);
};

export const appendActionColumn = <T,>(
  columns: ColumnDef<T>[],
  actionCount: number,
  actionHeader?: string,
): ColumnDef<T>[] => {
  if (actionCount === 0) return columns;
  const newColumns = columns.slice();
  newColumns.push({field: 'action', header: actionHeader ?? '', sortable: false});
  return newColumns;
};

export const filterRows = <T,>(
  rows: T[],
  searchParams: URLSearchParams,
  filterConfigs?: FilterConfig<T>[],
  searchString?: string,
  searchKeys?: (keyof T)[],
): T[] => {
  const filteredRows =
    filterConfigs?.reduce((rows, filter) => {
      const values = searchParams.getAll(filter.field.toString());
      return rows.filter((row, index) => {
        if (filter.multiple && Array.isArray(row[filter.field])) {
          return values.length === 0 || (row[filter.field] as unknown as any[]).some((v) => values.includes('' + v));
        }

        const rowVal = filter.valueFormatter ? filter.valueFormatter(row) : '' + row[filter.field];
        return values.includes(rowVal) || values.length === 0;
      });
    }, rows) ?? rows;

  return searchKeys ? filterListItems(filteredRows, searchKeys, searchString) : rows;
};

export const sortRows = <T,>(rows: T[], columns: ColumnDef<T>[], order: Order, orderBy?: keyof T): T[] => {
  return orderBy ? sortListRows(rows, columns, orderBy, order) : rows;
};
