import {Box} from '@mui/material';
import {localizedInterpolation} from 'i18n/i18n';
import {FC, memo, useMemo} from 'react';
import {useMatch} from 'react-router-dom';
import {useGetMachineTypesQuery} from 'state/features/api/machine-type-slice';
import {routes} from 'state/routes';
import PageContentContainer from 'view/components/default-components/page-container/page-content-container';
import {PageHeader} from 'view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from 'view/components/default-components/page-title/page-title';
import {MachineTypeForm} from './machine-type-form';

export const CreateMachineType: FC = memo(() => {
  const match = useMatch(routes.machineTypeEdit);
  const title = useMemo(() => localizedInterpolation(match ? 'EditInt' : 'CreateNewInt', 'MachineType'), [match]);
  useGetMachineTypesQuery();

  return (
    <PageTitle title={title}>
      <PageHeader area="machineTypeCreate" title={title} />
      <PageContentContainer xy>
        <Box maxWidth={700}>
          <MachineTypeForm />
        </Box>
      </PageContentContainer>
    </PageTitle>
  );
});
