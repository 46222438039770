import { InputAdornment, InputBase, Theme } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { FC, useCallback } from 'react';
import { localized } from '../../../../i18n/i18n';
import { BasicIcon } from '../../../components/default-components/basic-icon';

interface PropsFromParent {
  onChange: (searchText: string) => void;
  value: string | undefined;
}

const StyledSearchInput = withStyles((theme: Theme) => ({
  root: {
    padding: 5,
    minWidth: 256,
    background: theme.colors.searchInput.searchBgColor,
    color: theme.colors.searchInput.searchTextColor,
    borderRadius: 2,
    height: 35,
  },
  input: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: theme.palette.common.flexoWashBlueLight,
      opacity: 1
    }
  }
}))(InputBase);

export const SearchInput: FC<PropsFromParent> = React.memo(({onChange, value}) => {
  const textChangedLocal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  return (
    <StyledSearchInput
      startAdornment={
        <InputAdornment position="start">
          <BasicIcon icon="search" iconColor="#212B55" />
        </InputAdornment>
      }
      placeholder={localized('Search')}
      defaultValue={value}
      onChange={textChangedLocal}
    />
  );
});
