import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

export const useGoBackOnSuccess = (isSuccess: boolean, reset?: () => void) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isSuccess) return;

    navigate(-1);
    reset && reset();
  }, [isSuccess, navigate, reset]);
};
