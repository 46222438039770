import {useCallback, useEffect, useRef} from 'react';

export const useEnterPressListener = (handler: (event: KeyboardEvent) => void, element = window) => {
  const escapeListener = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        handler(event);
      }
    },
    [handler],
  );

  useAddEventListener('keydown', escapeListener, undefined, element);
};

const useAddEventListener = <K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
  element = window,
) => {
  const savedHandler = useRef<(this: Window, ev: WindowEventMap[K]) => any>(() => {});

  useEffect(() => {
    savedHandler.current = listener;
  }, [listener]);

  useEffect(() => {
    // Make sure the element supports addEventListener
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    // Add event listener
    element.addEventListener(type, savedHandler.current, options);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(type, savedHandler.current, options);
    };
  }, [element, listener, options, type]); // Include options in the dependency array
};
