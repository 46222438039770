import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const TankStatusMaximumLevelIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64.624 267.001">
      <g transform="translate(-396.687 -385.499)">
        <g transform="translate(405.687 385.499)" fill="#fff">
          <path
            d="M 27.81221389770508 263.001220703125 C 24.59582901000977 263.001220703125 21.47775268554688 262.3721923828125 18.54463768005371 261.1315612792969 C 15.70959854125977 259.9324645996094 13.16259860992432 258.2149658203125 10.97440624237061 256.0267944335938 C 8.786252021789551 253.8386077880859 7.068752288818359 251.2916412353516 5.869637012481689 248.4565734863281 C 4.629021644592285 245.5234527587891 3.999983072280884 242.4054107666016 3.999983072280884 239.1890258789062 L 3.999983072280884 27.81222534179688 C 3.999983072280884 24.59584045410156 4.629021644592285 21.47776412963867 5.869637012481689 18.54464912414551 C 7.068752288818359 15.70960998535156 8.786252021789551 13.16261005401611 10.97440624237061 10.9744176864624 C 13.16259860992432 8.786264419555664 15.70959854125977 7.068764209747314 18.54463768005371 5.869648933410645 C 21.47775268554688 4.62903356552124 24.59582901000977 3.999994993209839 27.81221389770508 3.999994993209839 C 31.02859878540039 3.999994993209839 34.14667510986328 4.62903356552124 37.07979202270508 5.869648933410645 C 39.91482925415039 7.068764209747314 42.46183013916016 8.786264419555664 44.65002059936523 10.9744176864624 C 46.83817672729492 13.16261005401611 48.5556755065918 15.70960998535156 49.75479125976562 18.54464912414551 C 50.99540710449219 21.47776412963867 51.62444305419922 24.59584045410156 51.62444305419922 27.81222534179688 L 51.62444305419922 239.1890258789062 C 51.62444305419922 242.4054107666016 50.99540710449219 245.5234527587891 49.75479125976562 248.4565734863281 C 48.5556755065918 251.2916412353516 46.83817672729492 253.8386077880859 44.65002059936523 256.0267944335938 C 42.46183013916016 258.2149658203125 39.91482925415039 259.9324645996094 37.07979202270508 261.1315612792969 C 34.14667510986328 262.3721923828125 31.02859878540039 263.001220703125 27.81221389770508 263.001220703125 Z"
            stroke="none"
          />
          <path
            d="M 27.81221389770508 8 C 16.88771438598633 8 8.000003814697266 16.8876953125 8.000003814697266 27.81219482421875 L 8.000003814697266 239.1889801025391 C 8.000003814697266 250.1134796142578 16.88771438598633 259.001220703125 27.81221389770508 259.001220703125 C 38.73671340942383 259.001220703125 47.62442016601562 250.1134796142578 47.62442016601562 239.1889801025391 L 47.62442016601562 27.81219482421875 C 47.62442016601562 16.8876953125 38.73671340942383 8 27.81221389770508 8 M 27.81221389770508 0 C 43.17246246337891 0 55.62442398071289 12.45195007324219 55.62442398071289 27.81219482421875 L 55.62442398071289 239.1889801025391 C 55.62442398071289 254.5492706298828 43.17246246337891 267.001220703125 27.81221389770508 267.001220703125 C 12.45196533203125 267.001220703125 3.814697265625e-06 254.5492706298828 3.814697265625e-06 239.1889801025391 L 3.814697265625e-06 27.81219482421875 C 3.814697265625e-06 12.45195007324219 12.45196533203125 0 27.81221389770508 0 Z"
            stroke="none"
            fill="#464f73"
          />
        </g>
        <rect width="33" height="245" rx="16.5" transform="translate(417 396)" fill="red" />
        <line
          x2="17"
          transform="translate(400.687 573.5)"
          fill="none"
          stroke="#464f73"
          strokeLinecap="round"
          strokeWidth="8"
        />
        <line
          x2="17"
          transform="translate(400.687 431.5)"
          fill="none"
          stroke="#464f73"
          strokeLinecap="round"
          strokeWidth="8"
        />
      </g>
    </SvgIcon>
  );
};

export default React.memo(TankStatusMaximumLevelIcon);
