export const VirtualizedContentBoxHeight = 'calc(100vh - 145px) !important';
export const VirtualizedContentBoxHeightmobile = 'calc(100vh - 145px - 2 * 56px) !important';
export const VirtualizedListTableBoxHeight = 'calc(100vh - 305px) !important';
export const VirtualizedCardListTableHeight = 'calc(100vh - 315px) !important';

export const VirtualizedListTableBoxHeightMobile = 'calc(100vh - 225px - 2 * 56px) !important';

export const defaultVirtualizedListTableHeight = 'calc(100vh - 245px)';

// export const ListItemWidth = 'calc(100% - 17px)';
export const ListItemWidth = '100%';
export const ListItemHeaderWidth = 'calc(100% - 18px)';

export const pageHeaderButtonFontWeight = '100';

export const NoMarginPageWrapper = {
  marginTop: -24,
};
