import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import React, {FC, memo} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {theme} from '../../../../styles/theme';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    disabled: boolean;
  }>;
}
export const CheckboxInput: FC<Props> = memo((props) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      render={({field}) => (
        <FormControl sx={{marginTop: 1, marginBottom: 1, marginLeft: 0}} disabled={props.disabled}>
          <FormControlLabel
            label={props.label ?? ''}
            control={<Checkbox checked={field.value ?? false} onChange={(e) => field.onChange(e.target.checked)} />}
          />
          {!!errors[props.name] && (
            <FormHelperText sx={{color: theme.palette.error.main}}>{errors[props.name].message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
});
