import {Box} from '@mui/material';
import {IMachineTypeDTO, MachineTypeDTO} from 'api/api';
import {localized, localizedInterpolation} from 'i18n/i18n';
import {useDialog} from 'providers/dialog-provider';
import {FC, memo, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {selectMachineTypes, useDeleteMachineTypeMutation} from 'state/features/api/machine-type-slice';
import {selectPageSettingByKey} from 'state/features/page-header-setting/page-header-setting-slice';
import {routes} from 'state/routes';
import {defaultVirtualizedListTableHeight} from 'styles/styling-constants';
import {filterListItems} from 'utilities/helpers/general-helpers';
import MachineIcon from 'view/components/default-components/icons/machine-icon';
import {ColumnDef} from 'view/components/default-components/list-table/list-table-types';
import {VirtualizedListTable} from 'view/components/default-components/list-table/virtual-list-table';

const columns: ColumnDef<IMachineTypeDTO>[] = [
  {field: 'name', header: localized('Name')},
  {
    field: 'machineIds',
    sortable: false,
    header: localized('Machines'),
    maxWidth: 100,
    customComponent: (machineType) => {
      return (
        <Box sx={{alignItems: 'center', display: 'flex'}}>
          <Box sx={{width: 35}}>{machineType.machineIds?.length}</Box>
          <MachineIcon />
        </Box>
      );
    },
  },
];
export const MachineTypeListView: FC = memo(() => {
  const machineTypes = useSelector(selectMachineTypes);
  const navigate = useNavigate();
  const [deleteMachineType] = useDeleteMachineTypeMutation();
  const {openDeleteDialog} = useDialog();
  const pageSetting = useSelector(selectPageSettingByKey('machineType'));

  const items = useMemo(() => {
    return filterListItems(machineTypes, ['name'], pageSetting?.searchString);
  }, [machineTypes, pageSetting]);

  const onEditClick = useCallback(
    (machineType: IMachineTypeDTO) => {
      navigate(`${routes.machineType}/${machineType.id}/edit`);
    },
    [navigate],
  );

  const handleDialogDelete = useCallback(
    (machineType: IMachineTypeDTO) => {
      deleteMachineType(machineType as MachineTypeDTO);
    },
    [deleteMachineType],
  );

  const onDeleteClick = useCallback(
    (machineType: IMachineTypeDTO) => {
      openDeleteDialog(localizedInterpolation('ConfirmDeleteInt', 'MachineType'), () =>
        handleDialogDelete(machineType),
      );
    },
    [handleDialogDelete, openDeleteDialog],
  );

  return (
    <Box>
      <VirtualizedListTable<IMachineTypeDTO>
        height={defaultVirtualizedListTableHeight}
        columns={columns}
        rows={items}
        defaultOrderBy={'name'}
        actionHeader={localized('Actions')}
        onEdit={onEditClick}
        onDelete={onDeleteClick}
      />
    </Box>
  );
});
