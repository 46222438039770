import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { SxProps, Theme } from '@mui/material';
import { DownloadFileProps, useDownloadFile } from 'hooks/use-download-file/use-download-file';
import { FC } from 'react';
import { YYMMDDhhmm } from 'utilities/platform-helpers/date-helpers';

interface Props {
  downloadMethod: DownloadFileProps['apiDefinition'];
}

const iconStyles: SxProps<Theme> = {fontSize: '1.5rem', color: 'grey.600'};

export const DownloadCSVButton: FC<Props> = (props) => {
  const {downloadMethod} = props;

  const namePostfix = () => {
    return `-${YYMMDDhhmm(new Date())}`;
  };

  const {ref, url, download, name, isLoading} = useDownloadFile({
    apiDefinition: downloadMethod,
    namePostfix: namePostfix,
  });

  return (
    <LoadingButton sx={{marginLeft: 1}} loading={isLoading} onClick={download}>
      <DownloadIcon sx={iconStyles} />
      <a href={url} download={name} className="hidden" ref={ref} />
    </LoadingButton>
  );
};

export default DownloadCSVButton;
