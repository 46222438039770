import {GridColumns, GridValueFormatterParams, GridValueGetterParams} from '@mui/x-data-grid';
import {IAlarmHistory} from 'api/api';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import {localized} from 'i18n/i18n';
import {DateFormat} from 'utilities/constants';
import {showTimestamp} from 'utilities/platform-helpers/date-helpers';
import {nameof} from 'utilities/platform-helpers/nameof-helper';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const alarmColumns: GridColumns = [
  {
    field: nameof<IAlarmHistory>('timestamp'),
    headerName: 'Date',
    type: 'dateTime',
    flex: 1,
    minWidth: 125,
    maxWidth: 140,
    headerClassName: 'header',
    valueFormatter: (params: GridValueFormatterParams) => `${dayjs(params.value).format(DateFormat)}`,
  },
  {
    field: 'time',
    headerName: 'Time',
    type: 'dateTime',
    flex: 1,
    minWidth: 125,
    maxWidth: 140,
    headerClassName: 'header',
    valueGetter: (params: GridValueGetterParams) => `${showTimestamp(params.row.timestamp)}`,
  },
  {
    field: nameof<IAlarmHistory>('alarmIndex'),
    headerName: 'ID',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: nameof<IAlarmHistory>('errorDescription'),
    headerName: 'Alarmstring',
    type: 'string',
    flex: 5,
    headerClassName: 'header',
  },
  {
    field: nameof<IAlarmHistory>('isOpen'),
    headerName: 'Status',
    type: 'string',
    flex: 1,
    maxWidth: 120,
    headerClassName: 'header',
    valueGetter: (params: GridValueGetterParams) => (params.value ? localized('Open') : localized('Cleared')),
  },
];
