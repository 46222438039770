import {Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useContext, useEffect, useState} from 'react';
import {headerBackgroundColor, secondaryTextColor} from '../../../../styles/color-constants';
import {FlexColumn} from '../flex-column';
import PageContentContainer from '../page-container/page-content-container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: headerBackgroundColor,
      paddingTop: '28px',
      paddingBottom: '16px',
      minHeight: 145,
      rowGap: theme.spacing(2),
      [theme.breakpoints.down(602)]: {
        paddingTop: 0,
      },
    },
    title: {
      color: secondaryTextColor,
      marginBottom: theme.spacing(3),
    },
  }),
);

interface PropsFromParent {
  title?: string;
  area: string;
}

export const PageHeaderContext = React.createContext({} as any);
export const usePageHeader = () => useContext(PageHeaderContext);

export const PageHeader: FC<PropsFromParent> = React.memo(({title, area, ...props}) => {
  const classes = useStyles();
  const [areaId, setAreaId] = useState<string>();
  useEffect(() => {
    setAreaId(area);
  }, [area]);

  return (
    <PageHeaderContext.Provider value={{areaId}}>
      <FlexColumn className={classes.root}>
        <PageContentContainer>
          {title && (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          )}
          {props.children}
        </PageContentContainer>
      </FlexColumn>
    </PageHeaderContext.Provider>
  );
});
