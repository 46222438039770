import {StackProps} from '@mui/material/Stack';
import {SvgIconProps} from '@mui/material/SvgIcon/SvgIcon';
import Typography, {TypographyProps} from '@mui/material/Typography';
import {Theme} from '@mui/system/createTheme';
import {SxProps} from '@mui/system/styleFunctionSx';
import {ConnectionState, IMachineCurrentStateDTO, MachineState as MachineStateEnum} from 'api/api';
import {localized} from 'i18n/i18n';
import React from 'react';
import {MachineStateMapper} from 'utilities/mappers/machine-state-mapper';
import IconLabelPlaceholder from 'view/components/specific-components/icon-label-placeholder';

// keyof Translation to ensure rerendering when chaning language

type Variant = 'machine' | 'machine-details';

const iconStyles: SxProps<Theme> = {color: 'common.flexoWashBlueLight', fontSize: '2.5rem'};

const variantMapper: Record<Variant, TypographyProps> = {
  machine: {
    fontSize: '1rem',
  },
  'machine-details': {
    fontSize: '1.25rem',
  },
};

interface Props extends StackProps {
  machineCurrentState?: IMachineCurrentStateDTO;
  variant?: Variant;
  iconProps?: SvgIconProps;
}

export const MachineState: React.FC<Props> = (props) => {
  const {children, variant = 'machine', iconProps, machineCurrentState, ...rest} = props;

  const machineState =
    machineCurrentState?.machineState !== undefined && machineCurrentState?.connectionState !== ConnectionState.Offline
      ? machineCurrentState?.machineState
      : MachineStateEnum.Unknown;

  const {stateTranslationKey: stateStatus, icon: StateIcon} = MachineStateMapper[machineState];
  return (
    <IconLabelPlaceholder icon={<StateIcon sx={iconStyles} {...iconProps} />} {...rest}>
      <Typography
        variant="body3"
        color="common.flexoWashBlue"
        textTransform="uppercase"
        fontWeight={500}
        {...variantMapper[variant]}>
        {localized(stateStatus)}
      </Typography>
      <Typography variant="body3" color="common.flexoWashBlueLight">
        {localized('MachineStatus')}
      </Typography>
    </IconLabelPlaceholder>
  );
};

export default MachineState;
