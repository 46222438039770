import {Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent, SxProps} from '@mui/material';
import {localized} from 'i18n/i18n';
import {FC, memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectActiveColumns, setActiveColumns} from 'state/features/machine-overview-columns/active-column-slice';
import {getAllMachineOverviewColumns} from 'state/features/machine-overview-columns/utils';
import {primaryColor, whiteColor} from 'styles/color-constants';

export const PageHeaderColumnSelector: FC = memo(() => {
  const columns = useSelector(selectActiveColumns);
  const dispatch = useDispatch();
  const machineOverviewColumns = useMemo(getAllMachineOverviewColumns, []);

  const getRenderValue = useCallback(() => {
    return <>{localized('Columns')}</>;
  }, []);

  const handleChange = useCallback(
    (event: SelectChangeEvent<typeof columns>) => {
      const {
        target: {value},
      } = event;
      dispatch(
        setActiveColumns(
          // On autofill we get a stringified value.
          typeof value === 'string' ? columns : value,
        ),
      );
    },
    [columns, dispatch],
  );

  return (
    <FormControl sx={{ml: 2}}>
      <Select
        sx={{...localStyles.select}}
        multiple
        value={columns}
        onChange={handleChange}
        renderValue={getRenderValue}>
        {[...machineOverviewColumns.entries()].sort().map((value) => (
          <MenuItem key={value[0]} value={value[0]}>
            <Checkbox checked={columns.indexOf(value[0]) > -1} />
            <ListItemText primary={value[1]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

const localStyles: {[key: string]: SxProps} = {
  select: {
    justifyContent: 'space-between',
    backgroundColor: primaryColor,
    color: whiteColor,
    height: 37,
    boxShadow: 2,
    fontSize: 14,
    fontWeight: 450,
    '& .MuiSelect-icon': {
      color: whiteColor,
    },
  },
};
