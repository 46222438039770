import {UserRole} from 'api/api';
import {createContext, memo, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLazyGetCurrentUserQuery} from 'state/features/api/user-slice';
import {setAccessToken, setAuthPending} from '../state/features/auth/auth-slice';
import {AppState} from '../state/store';
import {RedirectUriStorageKey} from '../utilities/constants';
import {getSubFromToken, isTokenExpired} from '../utilities/platform-helpers/auth-helper';
import {usePrevious} from '../utilities/use-previous-hook';
import {tokenStorageKey} from './auth-constants';
import {IAuthContext} from './auth-types';
import {
  clientAcquireTokenSilent,
  clientLoginSilent,
  clientLoginWithRedirect,
  clientLogout,
  initAuthClient,
} from './b2c/b2c-auth-provider';

// Initial state for the auth context.
const initialState: IAuthContext = {
  isAuthenticated: undefined,
  user: undefined,
  loading: false,
  isAdmin: undefined,
  isGlobalAdmin: undefined,
  isAgent: undefined,
  authId: '',
  loginSilent: () => {},
  login: () => {},
  logout: () => {},
  getToken: () => '',
  tokenExpiredHandling: () => true,
};

const AuthContext = createContext(initialState);
export const useAuth = (): IAuthContext => useContext(AuthContext);

export const GeneralAuthProvider = memo(({children}) => {
  // State for holding values related to the user
  const {initLogin, initLoginSilent, pending, accessToken, initLogout} = useSelector(
    (store: AppState) => store.authReducer,
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(!!accessToken && !isTokenExpired());
  const [authId, setAuthId] = useState<string>('');
  const [token, setToken] = useState<string>(accessToken);
  const [loading, setLoading] = useState<boolean>(pending);
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean | undefined>(undefined);
  const [isAgent, setIsAgent] = useState<boolean | undefined>(undefined);
  const [triggerLogin, setTriggerLogin] = useState<boolean>(false);
  const [triggerLogout, setTriggerLogout] = useState<boolean>(false);
  const prevTriggerLogin = usePrevious(triggerLogin);
  const prevTriggerLogout = usePrevious(triggerLogout);
  // Special case for api, as AsyncOperationHandler cannot access the useAuth hook.
  const prevInitLogin = usePrevious(initLogin);
  const prevInitLoginSilent = usePrevious(initLoginSilent);
  // Special case for api, as AsyncOperationHandler cannot access the useAuth hook.
  const prevInitLogout = usePrevious(initLogout);
  const dispatch = useDispatch();
  const [getCurrentUser, {data: currentUser}] = useLazyGetCurrentUserQuery();

  const setLoadingHelper = useCallback(
    (value: boolean) => {
      setLoading(value);
      dispatch(setAuthPending(value));
    },
    [dispatch],
  );

  const setTokenHelper = useCallback(
    (value: string) => {
      setToken(value);
      localStorage.setItem(tokenStorageKey, value);
      dispatch(setAccessToken(value));
    },
    [dispatch],
  );

  // Callback function for registering the relevant information about the user in the auth provider.
  const handleTokenResponseCallback = useCallback(
    (tokenResponse: string) => {
      setIsAuthenticated(true);
      setTokenHelper(tokenResponse);
      setAuthId(getSubFromToken()!);
      setTriggerLogin(false);
      setLoadingHelper(false);
      getCurrentUser();
    },
    [getCurrentUser, setLoadingHelper, setTokenHelper],
  );

  // Callback function to reset the authentication state held in the auth provider
  const handleLogoutCallback = useCallback(() => {
    setIsAuthenticated(false);
    setTokenHelper('');
    setAuthId('');
    setIsAdmin(false);
    setLoadingHelper(false);
    setIsGlobalAdmin(false);
    setTriggerLogout(false);
  }, [setLoadingHelper, setTokenHelper]);

  // Responsible for initiating the client used in the service specific auth providers
  // For B2C and AAD this function instantiates the MSAL instance and registers the provided callbacks
  useEffect(() => {
    const initAuth = async () => {
      await initAuthClient(handleTokenResponseCallback, handleLogoutCallback);
    };
    initAuth();
  }, [handleTokenResponseCallback, handleLogoutCallback]);

  // Attempts to authenticate the user silently with at refresh token.
  // If it fails it will initiate a login with redirect automatically
  const loginSilent = useCallback(async () => {
    setLoadingHelper(true);
    await clientLoginSilent();
  }, [setLoadingHelper]);
  const getToken = useCallback((): string => {
    return token ?? localStorage.getItem(tokenStorageKey);
  }, [token]);

  // Initiates the login flow
  const login = useCallback(() => {
    setTriggerLogin(true);
  }, []);

  // Initiates the logout flow
  const logout = useCallback(() => {
    // This is if the user chooses to sign in again after signing out
    window.sessionStorage.setItem(RedirectUriStorageKey, window.location.pathname);

    setTriggerLogout(true);
  }, []);

  const tokenExpiredHandling = useCallback((tokenStorageKey: string) => {
    if (isTokenExpired(tokenStorageKey)) {
      clientAcquireTokenSilent(true);
      return true;
    }
    return false;
  }, []);

  // Effect comparing prevState of the variable to avoid multiple calls to login
  useEffect(() => {
    if (triggerLogin && triggerLogin !== prevTriggerLogin) {
      setLoadingHelper(true);
      clientLoginWithRedirect();
    }
  }, [triggerLogin, prevTriggerLogin, setLoadingHelper]);

  // Effect comparing prevState of the variable to avoid multiple calls to logout
  useEffect(() => {
    if (triggerLogout && triggerLogout !== prevTriggerLogout) {
      setLoadingHelper(true);
      clientLogout();
    }
  }, [triggerLogout, prevTriggerLogout, setLoadingHelper]);

  // Handlers for refresh token cases from api-helper
  useEffect(() => {
    if (initLogin && initLogin !== prevInitLogin) {
      setLoadingHelper(true);
      clientLoginWithRedirect();
    }
  }, [initLogin, prevInitLogin, setLoadingHelper]);

  useEffect(() => {
    if (initLoginSilent && initLoginSilent !== prevInitLoginSilent) {
      loginSilent();
    }
  }, [initLoginSilent, prevInitLoginSilent, loginSilent, isAuthenticated]);

  useEffect(() => {
    if (initLogout && initLogout !== prevInitLogout) {
      logout();
    }
  }, [initLogout, logout, prevInitLogout]);

  useEffect(() => {
    if (currentUser?.role !== undefined && currentUser.role === UserRole.GlobalAdmin) {
      setIsAdmin(true);
      setIsGlobalAdmin(true);
    }
    setIsAgent(!!currentUser?.agentId);
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        user: currentUser,
        isAdmin,
        isGlobalAdmin,
        authId,
        isAgent,
        login,
        logout,
        loginSilent,
        getToken,
        tokenExpiredHandling,
      }}>
      {children}
    </AuthContext.Provider>
  );
});
