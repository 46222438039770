import {
  AgentClient,
  MachineClient,
  MachineHistoryClient,
  MachineStatisticsClient,
  MachineTypeClient,
  OrganizationClient,
  TankStatusClient,
  UserClient,
} from '../api/api';
import {ApiBaseUrl} from '../utilities/constants';

export const userClient = new UserClient(ApiBaseUrl);

export const organizationClient = new OrganizationClient(ApiBaseUrl);

export const machineClient = new MachineClient(ApiBaseUrl);

export const machineHistoryClient = new MachineHistoryClient(ApiBaseUrl);

export const machineStatisticsClient = new MachineStatisticsClient(ApiBaseUrl);
export const tankStatusClient = new TankStatusClient(ApiBaseUrl);
export const agentsClient = new AgentClient(ApiBaseUrl);
export const machineTypeClient = new MachineTypeClient(ApiBaseUrl);
