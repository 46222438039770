import {UserDTO, UserRole} from 'api/api';
import {localized, localizedInterpolation} from 'i18n/i18n';
import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useMatch, useParams} from 'react-router-dom';
import {selectUserById, useCreateUserMutation, useUpdateUserMutation} from 'state/features/api/user-slice';
import {routes} from 'state/routes';
import {primaryColor} from 'styles/color-constants';
import {useGoBackOnSuccess} from 'utilities/navigation-helper';
import {requiredValidator} from 'utilities/platform-helpers/validator-helper';
import {BasicCard} from 'view/components/default-components/basic-card/basic-card';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {FlexColumn} from 'view/components/default-components/flex-column';
import {FlexRow} from 'view/components/default-components/flex-row';
import {Form} from 'view/components/default-components/form/form';
import {TextInput} from 'view/components/default-components/form/text-input';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import {TextLabel} from 'view/components/default-components/text-label';

interface PropsFromParent {}
const sharedCacheKey = 'userMutation';

export const CreateAgentUserForm: FC<PropsFromParent> = React.memo(() => {
  const [updateUser] = useUpdateUserMutation({fixedCacheKey: sharedCacheKey});
  const [createUser, {isLoading, isSuccess, reset}] = useCreateUserMutation({fixedCacheKey: sharedCacheKey});

  const match = useMatch(routes.agentUsersEdit);
  const {agentId, userId} = useParams<{agentId: string; userId: string}>();

  const agentUser = useSelector(selectUserById(Number(userId)));

  useGoBackOnSuccess(isSuccess, reset);

  const save = useCallback(
    (data: UserDTO) => {
      if (agentUser) {
        agentUser.name = data.name;
        updateUser(agentUser as UserDTO);
      } else {
        const newUser = new UserDTO();
        newUser.init(data);
        newUser.role = data.role ? UserRole.GlobalAdmin : UserRole.User;
        newUser.agentId = Number(agentId);
        createUser(newUser);
      }
    },
    [agentId, agentUser, createUser, updateUser],
  );

  return (
    <BasicCard sx={{width: 'unset', height: 'fit-contet'}}>
      <FlexRow>
        <FlexColumn>
          <TextLabel variant="h6" fontWeight="bold" color={primaryColor} marginBottom={0}>
            {localized('UserProfile')}
          </TextLabel>
          <TextLabel variant="body1" marginBottom={3}>
            {match !== null ? localized('SaveUserFormSubTitle') : localized('CreateUserFormSubTitle')}
          </TextLabel>
          <TextLabel variant="h6" fontWeight="bold">
            {localized('PersonalInformation')}
          </TextLabel>
        </FlexColumn>
      </FlexRow>
      <Form onSubmit={save} sx={{maxWidth: 400}} defaultValues={agentUser}>
        <TextInput name="name" label={localized('Name')} validators={requiredValidator} />
        <TextInput name="email" label={localized('Email')} validators={requiredValidator} disabled={match !== null} />

        <FlexRow gap={2}>
          <BasicButton
            type="submit"
            buttonColor={'primary'}
            disabled={isLoading}
            text={localizedInterpolation(match !== null ? 'SaveInt' : 'CreateNewInt', 'User')}
          />
          <BasicButton buttonColor={'secondary'} backButton text={localized('Cancel')} />
          {isLoading && <BasicSpinner size={35} />}
        </FlexRow>
      </Form>
    </BasicCard>
  );
});
