import {Box, Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useEffect, useRef, useState} from 'react';
import {AutoSizer, List, ListRowProps, ListRowRenderer} from 'react-virtualized';
import {
  NoMarginPageWrapper,
  VirtualizedContentBoxHeight,
  VirtualizedContentBoxHeightmobile,
} from '../../../../styles/styling-constants';
import {FlexRow} from '../flex-row';

interface PropsFromParent {
  items: any[];
  rowRenderer: ListRowRenderer;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      width: 'auto',
    },
    list: {
      height: VirtualizedContentBoxHeight, // subtract page header,
      [theme.breakpoints.down('sm')]: {
        height: VirtualizedContentBoxHeightmobile, // subtract page header and mobile menu-bars,
      },
      outline: 'none',
    },
    noMarginPageWrapper: NoMarginPageWrapper,
  }),
);

type Props = PropsFromParent;

export const VirtualizedContentBoxList: FC<Props> = React.memo(({items, rowRenderer}) => {
  const classes = useStyles();

  const divRef = useRef<HTMLDivElement>(null);
  const [divHeight, setHeight] = useState(divRef.current?.clientHeight || 1000);

  useEffect(() => {
    const handler = () => {
      if (divRef && divRef.current) {
        const h = divRef.current.clientHeight;
        if (divHeight !== h) setHeight(h);
      }
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
    // eslint-disable-next-line
  }, []);

  return (
    <AutoSizer className={classes.noMarginPageWrapper}>
      {({height, width}) => {
        const itemsPerRow = Math.floor(width / 248);
        const rowCount = Math.ceil(items.length / itemsPerRow);

        // In order to align header and list items when scrollbar is shown, we have to handle the width
        const boxItemRenderer = ({index, key, style}: ListRowProps) => {
          const rowItems = [];
          const fromIndex = index * itemsPerRow;
          const toIndex = Math.min(fromIndex + itemsPerRow, items.length);

          for (let i = fromIndex; i < toIndex; i++) {
            rowItems.push(rowRenderer({index: i, key: key + i, style} as ListRowProps));
          }

          return (
            <FlexRow key={key} style={style} sx={{display: 'flex', columnGap: 2}}>
              {rowItems}
            </FlexRow>
          );
        };

        return (
          <div ref={divRef}>
            <Box>
              <List
                className={classes.list}
                overscanRowCount={2}
                height={divRef.current?.clientHeight || divHeight}
                rowCount={rowCount}
                rowHeight={248}
                width={width}
                rowRenderer={boxItemRenderer}
              />
            </Box>
          </div>
        );
      }}
    </AutoSizer>
  );
});
