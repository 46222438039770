import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Grid, {GridProps} from '@mui/material/Grid';
import React from 'react';

interface Props extends GridProps {
  children?: React.ReactNode | React.ReactNode[];
}

const StatisticItemRootRoot = styled(Box)`
  border-top: 1px solid #dae3f2;
  border-bottom: 1px solid #dae3f2;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: ${({theme}) => `${theme.spacing(1)} ${theme.spacing(2)}`};
`;

export const StatisticItemRoot: React.FC<Props> = (props) => {
  const {children, xs = 6, lg = 3, ...rest} = props;
  return (
    <Grid item xs={xs} lg={lg} {...rest}>
      <StatisticItemRootRoot>{children}</StatisticItemRootRoot>
    </Grid>
  );
};
