import {Message, ValidationRule} from 'react-hook-form';
import {localized, localizedInterpolationDynamic} from '../../i18n/i18n';

export interface Validators {
  required?: Message | ValidationRule<boolean>;
  min?: ValidationRule<number | string>;
  max?: ValidationRule<number | string>;
  maxLength?: ValidationRule<number>;
  minLength?: ValidationRule<number>;
  pattern?: ValidationRule<RegExp>;
  valueAsNumber?: boolean;
  valueAsDate?: boolean;
  setValueAs?: (value: any) => any;
  shouldUnregister?: boolean;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
}

export const requiredValidator: Validators = {required: {value: true, message: localized('FieldIsRequired')}};
export const maxLength20Validator: Validators = {
  maxLength: {value: 20, message: localizedInterpolationDynamic('FieldHasMaxLength', '20')},
};

export const linkValidator: Validators = {
  pattern: {
    value:
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\<+.~#?&\\/=]*)$/,
    message: localized('InvalidLinkMeta'),
  },
};
