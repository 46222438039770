// Local Storage keys
export const AccessTokenKey = 'accessToken';

// Dates
export const DateFormat = 'YYYY-MM-DD';
export const DateFormatWithHours = 'YYYY-MM-DD HH:mm:ss';
export const TimestampFormat = 'HH:mm:ss';

// Auth
export const AuthGetAdminRoleArray = 'https://jm-iot-api/roles';
export const RedirectUriStorageKey = 'url';

export const AuthRoles = {
  User: 'User',
  Admin: 'Admin',
  GlobalAdmin: 'GlobalAdmin',
};

// App Config
export const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const BrandName = process.env.REACT_APP_CUSTOMER_BRAND;
export const InsightsKey = process.env.REACT_APP_INSIGHTS_KEY;

// Google maps
export const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const StaticMapsApiUrl = process.env.REACT_APP_GOOGLE_MAPS_STATIC_MAP_API_URL;

// Assets Blob Storage
export const AssetsBlobStorageUrl = process.env.REACT_APP_ASSETS_BLOB_STORAGE_ACCOUNT_URL;

export const PlayStoreLink = 'https://play.google.com/store/apps/details?id=com.flexowash.service';
export const AppStoreLink = 'https://apps.apple.com/us/app/cleanlink-by-flexo-wash/id6448720969';
