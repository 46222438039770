import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const RoundedCrossIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 41 41">
      <g id="Group_38" data-name="Group 38" transform="translate(-874 -613)">
        <circle
          cx="20.5"
          cy="20.5"
          r="20.5"
          transform="translate(874 613)"
          fill="#7a9cc5"
        />
        <line
          x2="11.968"
          y2="11.968"
          transform="translate(888.365 627.691)"
          fill="none"
          stroke="#dae3f2"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          x1="11.968"
          y2="11.968"
          transform="translate(888.365 627.691)"
          fill="none"
          stroke="#dae3f2"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );
};

export default React.memo(RoundedCrossIcon);
