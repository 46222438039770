import {PaletteOptions} from '@mui/material/styles';
import {
  backgroundColor,
  lightBlue,
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
} from './color-constants';

export const DefaultPalette: PaletteOptions = {
  primary: {
    main: primaryColor,
  },
  secondary: {
    main: secondaryColor,
  },
  background: {
    default: backgroundColor,
  },
  info: {
    main: '#7A9CC5',
  },
  common: {
    flexoWashBlue: '#212B55',
    flexoWashBlueLight: '#7A9CC5',
    blue50: lightBlue,
    flexowashRed: '#CB4600',
    flexowashGreen: '#9DB500',

  },
  text: {
    primary: primaryTextColor,
    secondary: secondaryTextColor,
  },
  Example: {main: '#333'},
};
