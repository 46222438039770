import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import {Box, Fade, SortDirection, Stack, Typography} from '@mui/material';
import {localized} from 'i18n/i18n';
import {FC, useEffect, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {selectMachines, useGetMachinesQuery} from 'state/features/api/machines-slice';
import {selectPageSettingByKey} from 'state/features/page-header-setting/page-header-setting-slice';
import {filterListItems, sortListItems} from 'utilities/helpers/general-helpers';
import PageContentContainer from 'view/components/default-components/page-container/page-content-container';
import {Machine} from '../machine/machine';
import MachineSkeleton from '../machine/machine-skeleton';

const MachinesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(490px, 1fr));

  ${({theme}) => theme.breakpoints.up('xs')} {
    grid-gap: ${({theme}) => theme.spacing(5)};
  }
  ${({theme}) => theme.breakpoints.up('xl')} {
    grid-gap: ${({theme}) => theme.spacing(6)};
  }
`;

export const MachinesBoxView: FC = () => {
  const mounted = useRef(false);
  const {isError, isLoading} = useGetMachinesQuery();

  const pageSetting = useSelector(selectPageSettingByKey('machineOverview'));

  const machines = useSelector(selectMachines);
  const filteredItems = useMemo(() => {
    const filteredArray = filterListItems(
      machines ?? [],
      ['serialNumber', 'machineTypeName'],
      pageSetting?.searchString,
    );
    return sortListItems(filteredArray, 'serialNumber', pageSetting ? (pageSetting.sort as SortDirection) : 'asc');
  }, [machines, pageSetting]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <>
      {isError ? (
        <Box>
          <PageContentContainer>
            <Typography sx={{marginTop: 4}} variant="h5">
              {localized('Unhandled exception')}
            </Typography>
            <Typography>{localized('Contact administrator')}</Typography>
          </PageContentContainer>
        </Box>
      ) : (
        <PageContentContainer xy>
          {isLoading === false && filteredItems && filteredItems.length === 0 && (
            <Stack direction="row" columnGap={2} alignItems="center" justifyContent="center">
              <InfoIcon color="info" fontSize="large" />
              <Typography variant="h5">{localized('No machines found')}</Typography>
            </Stack>
          )}
          <Fade in>
            <MachinesContainer>
              {isLoading ? (
                [...Array(3)].map((_, index) => <MachineSkeleton key={index} />)
              ) : (
                <>
                  {filteredItems?.map((machine, index) => (
                    <Fade in appear={mounted.current} key={index} timeout={850}>
                      <div>
                        <Machine machine={machine} key={machine.serialNumber} />
                      </div>
                    </Fade>
                  ))}
                </>
              )}
            </MachinesContainer>
          </Fade>
        </PageContentContainer>
      )}
    </>
  );
};
