import {Check} from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Typography} from '@mui/material';
import {
  AlarmSeverity,
  ConnectionState,
  IMachineDTO,
  IMachineLatestCleaningDTO,
  IMachineLatestStatisticsDTO,
  IMachineLatestTankStatusDTO,
  IOrganizationDTO,
  MachineState as MachineStateEnum,
  TankLevel,
} from 'api/api';
import {localized} from 'i18n/i18n';
import React, {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useGetMachinesQuery} from 'state/features/api/machines-slice';
import {selectOrganizations} from 'state/features/api/organization-slice';
import {selectActiveColumns} from 'state/features/machine-overview-columns/active-column-slice';
import {selectPageSettingByKey} from 'state/features/page-header-setting/page-header-setting-slice';
import {routes} from 'state/routes';
import {alarmColor, warningColor} from 'styles/color-constants';
import {defaultVirtualizedListTableHeight} from 'styles/styling-constants';
import {theme} from 'styles/theme';
import {filterListItems} from 'utilities/helpers/general-helpers';
import {FilterConfig} from 'view/components/default-components/filter-panel/filter-panel-types';
import {ColumnDef} from 'view/components/default-components/list-table/list-table-types';
import {VirtualizedListTable} from 'view/components/default-components/list-table/virtual-list-table';
import {MachineConnectionCell} from './machine-connection-cell';
import {MachineLinkStatusCell} from './machine-links-cell';
import {MachineStatusCell} from './machine-status-cell';

type Props = {
  machineFilterConfigs: FilterConfig<IMachineDTO>[];
};

export const MachineOverviewListView: FC<Props> = React.memo((props) => {
  const organizations = useSelector(selectOrganizations);
  const activeColumns = useSelector(selectActiveColumns);

  const mapTankLevel = useCallback((level: TankLevel): string => {
    switch (level) {
      case TankLevel.BelowOk:
        return localized('BelowOK');
      case TankLevel.LevelOk:
        return localized('Ok');
      case TankLevel.MaximumLevel:
        return localized('Maximum');
      case TankLevel.MinimumLevel:
        return localized('Minimum');
    }
  }, []);

  const iconColor = useCallback(
    (machine: IMachineDTO) =>
      machine.currentAlarms?.some((a) => a.alarmSeverity === AlarmSeverity.Alarm) ? alarmColor : warningColor,
    [],
  );

  const showIcon = useCallback(
    (machine: IMachineDTO) =>
      machine.currentAlarms?.length && machine.currentState?.connectionState === ConnectionState.Online,
    [],
  );

  // ColumnDef inside the component to access organizations
  const columns: ColumnDef<IMachineDTO>[] = useMemo((): ColumnDef<IMachineDTO>[] => {
    const allCols: ColumnDef<
      IMachineDTO & IMachineLatestCleaningDTO & IMachineLatestStatisticsDTO & IMachineLatestTankStatusDTO
    >[] = getAllCols(organizations, showIcon, iconColor, mapTankLevel);
    //Filter out all inactive columns by checking whether they're in the activeColumns array
    return allCols.filter((c) => activeColumns.indexOf(c.field) > -1);
  }, [organizations, activeColumns, mapTankLevel, iconColor, showIcon]);

  const {data: allMachines} = useGetMachinesQuery(undefined, {
    pollingInterval: 10000,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const pageSetting = useSelector(selectPageSettingByKey('machineOverview'));

  const items = useMemo(
    () => filterListItems(allMachines ?? [], ['serialNumber', 'machineTypeName'], pageSetting?.searchString),
    [allMachines, pageSetting],
  );

  return (
    <Box>
      <VirtualizedListTable<IMachineDTO>
        columns={columns}
        rows={items}
        height={defaultVirtualizedListTableHeight}
        searchKeys={['machineTypeId']}
        defaultOrderBy={'serialNumber'}
        filterConfigs={props.machineFilterConfigs}
      />
    </Box>
  );
});
function getAllCols(
  organizations: IOrganizationDTO[],
  showIcon: (machine: IMachineDTO) => boolean | 0 | undefined,
  iconColor: (machine: IMachineDTO) => '#CB4600' | '#FDB900',
  mapTankLevel: (level: TankLevel) => string,
): ColumnDef<IMachineDTO & IMachineLatestCleaningDTO & IMachineLatestStatisticsDTO & IMachineLatestTankStatusDTO>[] {
  return [
    {
      field: 'serialNumber',
      header: localized('SerialNumber'),
      maxWidth: 150,
      valueFormatter: (machine) => `#${machine.serialNumber}`,
    },
    {field: 'machineTypeName', header: localized('MachineType')},
    {
      field: 'organizationId',
      valueFormatter: (machine) => organizations.find((org) => org.id === machine.organizationId)?.name ?? '',
      header: localized('CustomerName'),
    },
    {
      field: 'currentState',
      header: localized('Status'),
      valueFormatter: (machine) =>
        machine.currentState?.machineState && machine.currentState?.connectionState !== ConnectionState.Offline
          ? machine.currentState?.machineState.toString()
          : MachineStateEnum.Unknown.toString(),
      customComponent: (machine) => <MachineStatusCell machine={machine} />,
    },
    {
      field: 'currentAlarms',
      header: localized('ActiveAlarms'),
      maxWidth: 100,
      customComponent: (machine) =>
        showIcon(machine) ? (
          <WarningAmberIcon sx={{position: 'relative', top: 4, fontSize: 40, color: iconColor(machine)}} />
        ) : (
          <Check sx={{position: 'relative', top: 3, fontSize: 40}} />
        ),
    },
    {
      field: 'deviceId',
      header: localized('Connection'),
      valueFormatter: (machine) => machine.currentState?.latestConnectionStateChange?.valueOf().toString() ?? '0',
      customComponent: (machine) => <MachineConnectionCell machine={machine} />,
      minWidth: 250,
    },
    {
      field: 'linkSupport',
      header: localized('Links'),
      sortable: false,
      minWidth: 220,
      maxWidth: 220,
      customComponent: (machine) => <MachineLinkStatusCell machine={machine} />,
    },
    {
      field: 'id',
      header: localized('Details'),
      maxWidth: 70,
      sortable: false,
      customComponent: (machine) => {
        return (
          <Link to={`${routes.machines}/${machine?.id}` ?? ''} style={{textDecoration: 'none'}}>
            <Typography variant="body3" color={theme.colors.sideBar.sidebarSelectedBorder}>
              {localized('Details')}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: 'totalWashes',
      header: localized('TotalWashes'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.totalWashes != undefined ? machine.latestStatistics.totalWashes.toString() : 'N/A',
    },
    {
      field: 'washesSinceService',
      header: localized('WashesSinceService'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.washesSinceService != undefined
          ? machine.latestStatistics.washesSinceService.toString()
          : 'N/A',
    },
    {
      field: 'totalWashHours',
      header: localized('TotalWashHours'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.totalWashHours != undefined
          ? machine.latestStatistics.totalWashHours.toString()
          : 'N/A',
    },
    {
      field: 'washHoursSinceService',
      header: localized('WashHoursSinceService'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.washHoursSinceService != undefined
          ? machine.latestStatistics.washHoursSinceService.toString()
          : 'N/A',
    },
    {
      field: 'totalRinses',
      header: localized('TotalRinses'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.totalRinses != undefined ? machine.latestStatistics.totalRinses.toString() : 'N/A',
    },
    {
      field: 'rinsesSinceService',
      header: localized('RinsesSinceService'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.rinsesSinceService != undefined
          ? machine.latestStatistics.rinsesSinceService.toString()
          : 'N/A',
    },
    {
      field: 'totalRinseHours',
      header: localized('TotalRinseHours'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.totalRinseHours != undefined
          ? machine.latestStatistics.totalRinseHours.toString()
          : 'N/A',
    },
    {
      field: 'rinseHoursSinceService',
      header: localized('RinseHoursSinceService'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.rinseHoursSinceService != undefined
          ? machine.latestStatistics.rinseHoursSinceService.toString()
          : 'N/A',
    },
    {
      field: 'washesLeftBeforeService',
      header: localized('WashesLeftBeforeService'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.washesLeftBeforeService != undefined
          ? machine.latestStatistics.washesLeftBeforeService.toString()
          : 'N/A',
    },
    {
      field: 'washesLeftBeforeFilterChange',
      header: localized('WashesLeftBeforeFilterChange'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.washesLeftBeforeFilterChange != undefined
          ? machine.latestStatistics.washesLeftBeforeFilterChange.toString()
          : 'N/A',
    },
    {
      field: 'totalAniloxes',
      header: localized('TotalAniloxes'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.totalAniloxes != undefined
          ? machine.latestStatistics.totalAniloxes.toString()
          : 'N/A',
    },
    {
      field: 'aniloxesSinceService',
      header: localized('AniloxesSinceService'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.aniloxesSinceService != undefined
          ? machine.latestStatistics.aniloxesSinceService.toString()
          : 'N/A',
    },
    {
      field: 'totalLaserHours',
      header: localized('TotalLaserHours'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.totalLaserHours != undefined
          ? machine.latestStatistics.totalLaserHours.toString()
          : 'N/A',
    },
    {
      field: 'laserHoursSinceService',
      header: localized('LaserHoursSinceService'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.laserHoursSinceService != undefined
          ? machine.latestStatistics.laserHoursSinceService.toString()
          : 'N/A',
    },
    {
      field: 'totalVacuumHours',
      header: localized('TotalVacuumHours'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.totalVacuumHours != undefined
          ? machine.latestStatistics.totalVacuumHours.toString()
          : 'N/A',
    },
    {
      field: 'vacuumHoursSinceFilterChange',
      header: localized('VacuumHoursSinceFilterChange'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.vacuumHoursSinceFilterChange != undefined
          ? machine.latestStatistics.vacuumHoursSinceFilterChange.toString()
          : 'N/A',
    },
    {
      field: 'vacuumHoursUntilNextFilter',
      header: localized('VacuumHoursLeftBeforeFilterChange'),
      valueFormatter: (machine) =>
        machine.latestStatistics?.vacuumHoursUntilNextFilter != undefined
          ? machine.latestStatistics.vacuumHoursUntilNextFilter.toString()
          : 'N/A',
    },
    {
      field: 'date',
      header: localized('Date'),
      valueFormatter: (machine) => (machine.latestCleaning?.date != undefined ? machine.latestCleaning.date : 'N/A'),
    },
    {
      field: 'time',
      header: localized('Time'),
      valueFormatter: (machine) => (machine.latestCleaning?.time != undefined ? machine.latestCleaning.time : 'N/A'),
    },
    {
      field: 'sequence',
      header: localized('Sequence'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.sequence != undefined ? machine.latestCleaning.sequence.toString() : 'N/A',
    },
    {
      field: 'recipe',
      header: localized('Recipe'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.recipe != undefined ? machine.latestCleaning.recipe : 'N/A',
    },
    {
      field: 'washTime',
      header: localized('Recipe'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.washTime != undefined ? machine.latestCleaning.washTime.toString() : 'N/A',
    },
    {
      field: 'tank',
      header: localized('Tank'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.tank != undefined ? machine.latestCleaning.tank.toString() : 'N/A',
    },
    {
      field: 'temp',
      header: localized('Temperature'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.temp != undefined ? machine.latestCleaning.temp.toString() : 'N/A',
    },
    {
      field: 'rinse',
      header: localized('Rinse'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.rinse != undefined ? machine.latestCleaning.rinse.toString() : 'N/A',
    },
    {
      field: 'dry',
      header: localized('Dry'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.dry != undefined ? machine.latestCleaning.dry.toString() : 'N/A',
    },
    {
      field: 'washSpeed',
      header: localized('WashSpeed'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.washSpeed != undefined ? machine.latestCleaning.washSpeed.toString() : 'N/A',
    },
    {
      field: 'employeeId',
      header: localized('EmployeeId'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.employeeId != undefined
          ? machine.latestCleaning.employeeId.toString()
          : localized('Unknown'),
    },
    {
      field: 'placeHolder',
      header: 'Placeholder',
      valueFormatter: (machine) => (machine.latestCleaning?.placeHolder ? machine.latestCleaning.placeHolder : 'N/A'),
    },
    {
      field: 'rollId',
      header: localized('RollId'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.rollId != undefined ? machine.latestCleaning.rollId.toString() : 'N/A',
    },
    {
      field: 'intensity',
      header: localized('Intensity'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.intensity != undefined ? machine.latestCleaning.intensity.toString() : 'N/A',
    },
    {
      field: 'color',
      header: localized('Color'),
      valueFormatter: (machine) => (machine.latestCleaning?.color != undefined ? machine.latestCleaning.color : 'N/A'),
    },
    {
      field: 'passes',
      header: localized('Passes'),
      valueFormatter: (machine) =>
        machine.latestCleaning?.passes != undefined ? machine.latestCleaning.passes.toString() : 'N/A',
    },
    {
      field: 'numberOfTanks',
      header: localized('NumberOfTanks'),
      valueFormatter: (machine) =>
        machine.latestTankStatus?.numberOfTanks != undefined
          ? machine.latestTankStatus.numberOfTanks.toString()
          : 'N/A',
    },
    {
      field: 'levelTank1',
      header: localized('LevelTank1'),
      valueFormatter: (machine) =>
        machine.latestTankStatus?.levelTank1 != undefined ? mapTankLevel(machine.latestTankStatus.levelTank1) : 'N/A',
    },
    {
      field: 'temperatureTank1',
      header: localized('TemperatureTank1'),
      valueFormatter: (machine) =>
        machine.latestTankStatus?.temperatureTank1 != undefined
          ? machine.latestTankStatus.temperatureTank1.toString() + '°C'
          : 'N/A',
    },
    {
      field: 'levelTank2',
      header: localized('LevelTank2'),
      valueFormatter: (machine) =>
        machine.latestTankStatus?.levelTank2 != undefined ? mapTankLevel(machine.latestTankStatus.levelTank2) : 'N/A',
    },
    {
      field: 'temperatureTank2',
      header: localized('TemperatureTank2'),
      valueFormatter: (machine) =>
        machine.latestTankStatus?.temperatureTank2 != undefined
          ? machine.latestTankStatus.temperatureTank2.toString() + '°C'
          : 'N/A',
    },
  ];
}
