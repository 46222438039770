import Grid from '@mui/material/Grid';
import {ILaserMachineStatisticDTO} from 'api/api';
import {localized} from 'i18n/i18n';
import React, {memo} from 'react';
import {StatisticItem, getValueOrUnknown} from '../statistic-item';
import {SmallStatisticItemWrapper} from '../statistic-item-container';
import {StatisticsProps} from '../statistics';
import {StatisticItemRoot} from '../statistics-container-item';

export const LaserStatistics: React.FC<StatisticsProps> = memo((props) => {
  const {statistics} = props.data;

  if (!statistics) {
    return <div>{localized('NoStatistics')}</div>;
  }

  const {
    totalAniloxes,
    aniloxesSinceService,
    totalLaserHours,
    laserHoursSinceService,
    totalVacuumHours,
    vacuumHoursSinceFilterChange,
    vacuumHoursUntilNextFilter,
  } = statistics as ILaserMachineStatisticDTO;

  return (
    <Grid
      container
      spacing={{
        xs: 5,
        xl: 6,
      }}>
      <StatisticItemRoot xs lg>
        <SmallStatisticItemWrapper>
          <StatisticItem label={localized('TotalAniloxes')} placeholder={getValueOrUnknown(totalAniloxes)} />
          <StatisticItem
            label={localized('AniloxesSinceService')}
            placeholder={getValueOrUnknown(aniloxesSinceService)}
          />
          <StatisticItem label={localized('TotalLaserHours')} placeholder={getValueOrUnknown(totalLaserHours)} />
        </SmallStatisticItemWrapper>
      </StatisticItemRoot>

      <StatisticItemRoot xs lg>
        <SmallStatisticItemWrapper>
          <StatisticItem
            label={localized('LaserHoursSinceService')}
            placeholder={getValueOrUnknown(laserHoursSinceService)}
          />
          <StatisticItem label={localized('TotalVacuumHours')} placeholder={getValueOrUnknown(totalVacuumHours)} />
          <StatisticItem
            label={localized('VacuumHoursSinceFilterChange')}
            placeholder={getValueOrUnknown(vacuumHoursSinceFilterChange)}
          />
        </SmallStatisticItemWrapper>
      </StatisticItemRoot>

      <StatisticItemRoot xs={12} lg={4}>
        <StatisticItem
          label={localized('VacuumHoursLeftBeforeFilterChange')}
          placeholder={getValueOrUnknown(vacuumHoursUntilNextFilter)}
          variant="large"
        />
      </StatisticItemRoot>
    </Grid>
  );
});
