import {useAuth} from 'auth/auth-provider';
import {localized} from 'i18n/i18n';
import {useDialog} from 'providers/dialog-provider';
import React, {FC, useCallback} from 'react';
import {useDeleteSelfUserMutation} from 'state/features/api/user-slice';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {FlexColumn} from 'view/components/default-components/flex-column';
import {FlexRow} from 'view/components/default-components/flex-row';
import PageContentContainer from 'view/components/default-components/page-container/page-content-container';
import {PageHeader} from 'view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from 'view/components/default-components/page-title/page-title';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import {TextLabel} from 'view/components/default-components/text-label';

export const DeleteUser: FC = React.memo(() => {
  const {user: currentUser, loading: showSpinner, logout} = useAuth();
  const [deleteSelf, {isLoading}] = useDeleteSelfUserMutation();
  const {openDeleteDialog} = useDialog();

  const handleDeleteConfirm = useCallback(() => {
    deleteSelf().then(() => logout());
  }, [deleteSelf, logout]);

  const onDeletePressed = useCallback(() => {
    openDeleteDialog(localized('ConfirmDeleteUser'), handleDeleteConfirm);
  }, [handleDeleteConfirm, openDeleteDialog]);

  return (
    <PageTitle title={localized('DeleteAccount')}>
      <PageHeader area="userManagement" title={localized('DeleteAccount')}></PageHeader>
      {showSpinner ? (
        <BasicSpinner />
      ) : (
        <PageContentContainer xy display={'flex'} flexDirection={'column'} alignItems={'center'} gap={3}>
          <TextLabel>{localized('DeleteOwnUserDescription')}</TextLabel>
          <FlexColumn alignItems={'center'}>
            <TextLabel sx={{marginBottom: 3}} fontWeight={'bold'}>
              {localized('PersonalInformation')}:
            </TextLabel>
            <FlexRow gap={10}>
              <FlexColumn>
                <TextLabel>{localized('Name')}:</TextLabel>
                <TextLabel>{localized('Email')}:</TextLabel>
              </FlexColumn>
              <FlexColumn>
                <TextLabel>{currentUser?.name}</TextLabel>
                <TextLabel>{currentUser?.email}</TextLabel>
              </FlexColumn>
            </FlexRow>
          </FlexColumn>
          <BasicButton
            disabled={isLoading || !currentUser}
            text={localized('DeleteAccount')}
            buttonColor="error"
            onClick={onDeletePressed}
          />
        </PageContentContainer>
      )}
    </PageTitle>
  );
});
