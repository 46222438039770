import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC} from 'react';
import {secondaryTextColor} from '../../../../styles/color-constants';
import {FlexRow} from '../flex-row';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      rowGap: theme.spacing(2),
    },
    title: {
      fontSize: theme.typography.h3.fontSize,
      color: secondaryTextColor,
    },
  }),
);

interface PropsFromParent {}

export const PageHeaderContent: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();

  return <FlexRow className={classes.root}>{props.children}</FlexRow>;
});
