import dayjs from 'dayjs';
import 'dayjs/locale/da'; // Danish
import 'dayjs/locale/de'; // German
import 'dayjs/locale/en-gb'; // English
import 'dayjs/locale/es'; // Spanish
import 'dayjs/locale/fr'; // French
import 'dayjs/locale/ru'; // Russian

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {parseJSON} from 'utilities/json-helpers';

import danishTranslation from './da.json';
import danishPlatformTranslation from './platform-translations/da.json';

import englishTranslation from './en.json';
import englishPlatformTranslation from './platform-translations/en.json';

import germanTranslation from './de.json';
import germanPlatformTranslation from './platform-translations/de.json';

import spanishTranslation from './es.json';
import spanishPlatformTranslation from './platform-translations/es.json';

import frenchTranslation from './fr.json';
import frenchPlatformTranslation from './platform-translations/fr.json';

export const Languages = {
  da: 'Dansk',
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
} as const;

export type LanguageType = keyof typeof Languages;

export type Translation = typeof i18nResources.en.translation;

// NB. When adding a NEW TRANSLATION, REMEMBER to IMPORT the corresponding dayjs LOCALE at the top!
const i18nResources = {
  da: {
    translation: {...danishPlatformTranslation, ...danishTranslation},
  },
  en: {
    translation: {...englishPlatformTranslation, ...englishTranslation},
  },
  de: {
    translation: {...germanPlatformTranslation, ...germanTranslation},
  },
  es: {
    translation: {...spanishPlatformTranslation, ...spanishTranslation},
  },
  fr: {
    translation: {...frenchPlatformTranslation, ...frenchTranslation},
  },
};

export type i18nTranslationKey = any;

i18n.use(detector);
i18n.init(
  {
    fallbackLng: 'en',
    resources: i18nResources,
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
    react: {
      //withRef: false,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
    },
  },
  async (err, t) => {
    const storedLang = window.localStorage.getItem('language');

    let userLang = (parseJSON(storedLang) as LanguageType) ?? navigator.language;

    if (userLang === 'da') {
      await i18n.changeLanguage('da');
    }
    if (userLang.includes('en')) {
      await i18n.changeLanguage('en');
    }
    if (userLang.includes('de')) {
      await i18n.changeLanguage('de');
    }
    if (userLang.includes('es')) {
      await i18n.changeLanguage('es');
    }
    if (userLang.includes('fr')) {
      await i18n.changeLanguage('fr');
    }
  },
);

dayjs.extend(require('dayjs/plugin/localizedFormat'));

if (navigator.language) {
  const lngLower = navigator.language.toLocaleLowerCase();
  if (lngLower === 'da') {
    dayjs.locale('da');
  } else if (['en', 'en-us'].includes(lngLower)) {
    dayjs.locale('en');
  } else if (lngLower.includes('de')) {
    dayjs.locale('de');
  } else if (lngLower.includes('es')) {
    dayjs.locale('es');
  } else if (lngLower.includes('fr')) {
    dayjs.locale('fr');
  } else {
    //Fall back to UK-format. Appropriate for anyone that is neither US, or specifically supported with language (like DK)
    dayjs.locale('en-gb');
  }
}

// type-safe translation lookup
export const localized = (key: keyof Translation): string => {
  return i18n.t(key);
};

export const localizedDynamic = (key: keyof Translation | string): string => {
  return i18n.t(key);
};

export const localizedInterpolation = (key: keyof Translation, ...keysToReplace: (keyof Translation)[]): string => {
  return i18n.t(
    key,
    Object.fromEntries(keysToReplace.map((key, index) => [index, localized(key).toLocaleLowerCase()])),
  );
};

export const localizedInterpolationDynamic = (key: keyof Translation, ...keysToReplace: string[]): string => {
  return i18n.t(
    key,
    Object.fromEntries(keysToReplace.map((key, index) => [`{${index}}`, localizedDynamic(key).toLocaleLowerCase()])),
  );
};

export const getTranslation = (language: keyof typeof Languages) => i18n.getFixedT(language);

export const getLanguage = (): LanguageType => {
  return (i18n.language === 'en-US' ? 'en' : i18n.language) as LanguageType;
};

export const changeLanguage = async (language: keyof typeof Languages) => {
  await i18n.changeLanguage(language);

  // TODO: IS locale set correct???
  dayjs.locale('en');
};
