import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {selectUserById} from 'state/features/api/user-slice';
import {UserRole} from '../../../../api/api';
import {useAuth} from '../../../../auth/auth-provider';
import {localizedDynamic} from '../../../../i18n/i18n';
import {routes} from '../../../../state/routes';
import {ContentBox} from '../../../components/default-components/content-box/content-box';
import {ContentBoxBody} from '../../../components/default-components/content-box/content-box-body';
import {ContentBoxHeader} from '../../../components/default-components/content-box/content-box-header';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {TextLabel} from '../../../components/default-components/text-label';

interface PropsFromParent {
  userId: number;
}

export const UserManagementBoxItem: FC<PropsFromParent> = React.memo(({userId}) => {
  const navigate = useNavigate();
  const {isAdmin} = useAuth();
  const item = useSelector(selectUserById(userId));

  const editUser = useCallback(() => {
    if (isAdmin) navigate(`${routes.userManagement}/${item?.id}/edit`);
  }, [navigate, item, isAdmin]);

  if (!item) {
    return null;
  }

  return (
    <ContentBox itemSelected={editUser} contentItem={item}>
      <ContentBoxHeader>{item.name}</ContentBoxHeader>
      <ContentBoxBody>
        <FlexColumn height="100%">
          <TextLabel m={0}>{localizedDynamic(UserRole[item.role])}</TextLabel>
          <TextLabel m={0}>{item.email}</TextLabel>
        </FlexColumn>
      </ContentBoxBody>
    </ContentBox>
  );
});
