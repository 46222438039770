import {Box, SxProps, TableSortLabel} from '@mui/material';
import React, {useCallback, useMemo} from 'react';
import {backgroundColor} from '../../../../styles/color-constants';
import {theme} from '../../../../styles/theme';
import {genericMemo} from '../../../../utilities/generic-memo';
import {FlexColumn} from '../flex-column';
import {FlexRow} from '../flex-row';
import {useListTableInternal} from './list-table';
import {ListTableCheckbox} from './list-table-check-box';
import {ListTableCheckboxActions} from './list-table-check-box-actions';
import {IdBase, ListTableHeaderProps} from './list-table-types';
import {getColumnMaxWidth, getColumnMinWidth, getColumnStyle} from './list-table-util';

export const ListTableHeader = genericMemo(<T extends IdBase>(props: ListTableHeaderProps) => {
  const {
    rows,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    actionCount,
    columns,
    checkbox,
    checkboxActions,
    loading,
    selectedIds,
    setSelectedIds,
  } = useListTableInternal();
  const localStyles = useMemo(() => getHeaderStyles(props), [props]);

  const onSortClicked = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as any);
  };

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      event.stopPropagation();
      const ids = checked ? rows.map((row) => row.id) : [];
      setSelectedIds(ids);
    },
    [rows, setSelectedIds],
  );

  return (
    <FlexColumn sx={localStyles.container}>
      {selectedIds.length > 0 && checkboxActions?.length ? <ListTableCheckboxActions /> : <></>}
      <FlexRow sx={localStyles.headerContainer} paddingLeft={checkbox ? 2 : 3}>
        {checkbox && (
          <ListTableCheckbox
            checked={!loading && rows.length > 0 && selectedIds.length === rows.length}
            onChange={handleCheckboxChange}
          />
        )}

        {columns.map((column, index) => {
          // Width from props
          const maxWidth = getColumnMaxWidth(column, actionCount);
          const minWidth = getColumnMinWidth(column, actionCount);
          // Styling override from props
          const style = getColumnStyle(column);
          return (
            <Box
              key={index}
              sx={style}
              flex={1}
              maxWidth={maxWidth}
              minWidth={minWidth}
              color={theme.colors.listTable.ListTableHeaderTextColor}
              alignItems={'center'}
              justifyContent={column.field === 'action' ? 'flex-end' : undefined}
              display={'flex'}>
              {column.field === 'action' || column.sortable === false ? (
                <Box sx={{wordBreak: 'break-word'}} pr={1}>
                  {column.header}
                </Box>
              ) : (
                <TableSortLabel
                  sx={{
                    fontWeight: orderBy === column.field ? 'bold' : 'normal',
                  }}
                  active={orderBy === column.field}
                  direction={orderBy === column.field ? order : 'asc'}
                  onClick={onSortClicked(column.field)}>
                  {column.header}
                </TableSortLabel>
              )}
            </Box>
          );
        })}
      </FlexRow>
    </FlexColumn>
  );
});

const getHeaderStyles = (props: ListTableHeaderProps): {[key: string]: SxProps} => ({
  container: {
    top: 0,
    zIndex: 1,
    minWidth: 'fit-content',
    width: '100%',
    position: props.scrollPage ? 'sticky' : undefined,
    backgroundColor: props.headerBackgroundColor ? props.headerBackgroundColor : backgroundColor,
  },
  headerContainer: {
    height: 64,
    paddingRight: 3,
    gap: 2,
    overflowX: 'hidden',
  },
});
