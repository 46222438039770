import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const StandbyIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 41 41">
      <g id="Group_5" data-name="Group 5" transform="translate(-694 -141)">
        <circle
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="20.5"
          cy="20.5"
          r="20.5"
          transform="translate(694 141)"
          fill="#7a9cc5"
        />
        <circle
          id="Ellipse_8"
          data-name="Ellipse 8"
          cx="3"
          cy="3"
          r="3"
          transform="translate(703 159)"
          fill="#dae3f2"
        />
        <circle
          id="Ellipse_9"
          data-name="Ellipse 9"
          cx="3"
          cy="3"
          r="3"
          transform="translate(712 159)"
          fill="#dae3f2"
        />
        <circle
          id="Ellipse_10"
          data-name="Ellipse 10"
          cx="3"
          cy="3"
          r="3"
          transform="translate(721 159)"
          fill="#dae3f2"
        />
      </g>
    </SvgIcon>
  );
};

export default React.memo(StandbyIcon);
