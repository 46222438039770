import React, {FC} from 'react';
import {Box, BoxProps, SxProps, Theme} from '@mui/material';

interface Props extends BoxProps {
  sx?: SxProps<Theme>;
  boxref?: React.RefObject<HTMLDivElement>;
}
export const FlexRow: FC<Props> = React.memo((props) => {
  return (
    <Box display="flex" flexDirection="row" width={1} {...props}>
      {props.children}
    </Box>
  );
});
