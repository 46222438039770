import { Box, BoxProps } from '@mui/material';
import React, { FC } from 'react';

export const ContentBoxBody: FC<BoxProps> = React.memo((props) => {
  return (
    <Box flex={1}>
      {props.children}
    </Box>
  );
});
