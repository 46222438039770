import {Box, FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {useLocalStorage} from 'hooks/use-local-storage';
import {LanguageType, Languages, getLanguage} from 'i18n/i18n';
import React, {memo} from 'react';
import {useDispatch} from 'react-redux';
import {setSelectedLanguage} from 'state/features/settings/settings-slice';
import DanishFlagIcon from 'view/components/default-components/icons/danish-flag-icon';
import {EnglishFlagIcon} from 'view/components/default-components/icons/english-flag-icon/english-flag-icon';
import FrenchFlagIcon from 'view/components/default-components/icons/french-flag-icon';
import GermanFlagIcon from 'view/components/default-components/icons/german-flag-icon';
import SpanishFlagIcon from 'view/components/default-components/icons/spanish-flag-icon';

const LanguageIdentifiers = Object.entries(Languages).map(([key, value]) => {
  return {
    key: key as LanguageType,
    language: value,
  };
});

const flagsMapper: Record<LanguageType, JSX.Element> = {
  da: <DanishFlagIcon />,
  en: <EnglishFlagIcon />,
  de: <GermanFlagIcon />,
  es: <SpanishFlagIcon />,
  fr: <FrenchFlagIcon />,
};

export const LanguagePicker: React.FC = () => {
  const dispatch = useDispatch();
  const [storedLanguage, setStoredLanguage] = useLocalStorage('language', getLanguage());

  const handleChange = async (event: SelectChangeEvent) => {
    const lang = event.target.value as keyof typeof Languages;
    setStoredLanguage(lang);
    dispatch(setSelectedLanguage(lang));
  };

  return (
    <FormControl sx={{minWidth: 138}} size="small">
      <Select
        inputProps={{'aria-label': 'Without label'}}
        displayEmpty
        fullWidth
        defaultChecked={true}
        value={storedLanguage}
        sx={{
          '& .MuiSelect-select': {
            padding: '5px 14px',
          },
        }}
        onChange={handleChange}>
        {LanguageIdentifiers.map(({key, language}) => (
          <MenuItem key={key} value={key}>
            <Stack direction="row" columnGap="12px" alignItems="center">
              <Box
                className="flag-icon"
                sx={{
                  display: 'inline-flex',
                  '& > svg': {
                    borderRadius: '50%',
                    fontSize: '1.2rem',
                  },
                }}>
                {flagsMapper[key]}
              </Box>
              <Typography variant="body3" color="common.flexoWashBlue">
                {language}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(LanguagePicker);
