import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, {FC, useCallback} from 'react';

interface PropsFromParent {
  selectionChanged: (newSelection: string | string[]) => void;
  selection: string | string[];
  exclusive?: boolean;
}

const StyledToggleButtonGroup = withStyles((theme: Theme) => ({
  grouped: {
    border: 'none',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
    '&:not(:first-child)': {
      borderRadius: 2,
      margin: 0,
      border: 'unset',
      background: 'transparent',
    },
    '&:first-child': {
      borderRadius: 2,
      margin: 0,
      border: 'unset',
      background: 'transparent',
    },
  },
  root: {flexWrap: 'wrap'},
}))(ToggleButtonGroup);

type Props = PropsFromParent;

export const HorizontalToggleButtons: FC<Props> = React.memo((props) => {
  const {selectionChanged, selection, children} = props;
  const selectionChangedLocal = useCallback(
    (event: React.MouseEvent<HTMLElement>, newSelection: string | string[]) => {
      if (newSelection) {
        selectionChanged(newSelection);
      }
    },
    [selectionChanged],
  );

  return (
    <StyledToggleButtonGroup value={selection} exclusive={props.exclusive} onChange={selectionChangedLocal}>
      {children}
    </StyledToggleButtonGroup>
  );
});

HorizontalToggleButtons.defaultProps = {
  exclusive: true,
};
