import {Box, Stack, StackProps} from '@mui/material';
import React from 'react';
import {FlexColumn} from 'view/components/default-components/flex-column';

type IconPlacement = 'left' | 'right';

export interface IconLabelPlaceholderProps extends StackProps {
  icon?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  iconPlacement?: IconPlacement;
}

const variantMapper: Record<IconPlacement, StackProps> = {
  left: {
    flexDirection: 'row',
    textAlign: 'left',
  },
  right: {
    flexDirection: 'row-reverse',
    textAlign: 'right',
  },
};

export const IconLabelPlaceholder: React.FC<IconLabelPlaceholderProps> = (props) => {
  const {icon, children, iconPlacement = 'left', ...rest} = props;

  return (
    <Stack alignItems="flex-start" {...variantMapper[iconPlacement]} columnGap={2} {...rest}>
      <Box sx={{mt: '4px'}}>{icon}</Box>
      <FlexColumn>{children}</FlexColumn>
    </Stack>
  );
};

export default IconLabelPlaceholder;
