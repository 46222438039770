import {GridColumns, GridValueFormatterParams, GridValueGetterParams} from '@mui/x-data-grid';
import {ICleaningHistoryDTO} from 'api/api';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import {DateFormat} from 'utilities/constants';
import {getFormattedDate, showTimestamp} from 'utilities/platform-helpers/date-helpers';
import {nameof} from 'utilities/platform-helpers/nameof-helper';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const standardCleaningColumns: GridColumns = [
  {
    field: nameof<ICleaningHistoryDTO>('timestamp'),
    headerName: 'Date',
    type: 'dateTime',
    minWidth: 125,
    flex: 1,
    maxWidth: 140,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
    valueFormatter: (params: GridValueFormatterParams) => `${dayjs(params.value).format(DateFormat)}`,
  },
  {
    field: 'time',
    headerName: 'Time',
    type: 'dateTime',
    minWidth: 125,
    flex: 1,
    maxWidth: 140,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
    valueGetter: (params: GridValueGetterParams) => `${showTimestamp(params.row.timestamp)}`,
  },
  {
    field: 'sequence',
    headerName: 'Sequence',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
  {
    field: 'recipe',
    headerName: 'Recipe',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 125,
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'washTime',
    headerName: 'WashTime',
    headerClassName: 'header',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
  },
  {
    field: 'tank',
    headerName: 'Tank',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'temp',
    headerName: 'Temperature',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'rinse',
    headerName: 'Rinse',
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'dry',
    headerName: 'Dry',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'employeeId',
    headerName: 'EmployeeId',
    minWidth: 125,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
];

export const PLC_FWcleaningColumns: GridColumns = [
  {
    field: nameof<ICleaningHistoryDTO>('timestamp'),
    headerName: 'Date',
    type: 'dateTime',
    minWidth: 125,
    flex: 1,
    maxWidth: 140,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
    valueFormatter: (params: GridValueFormatterParams) => getFormattedDate(params.value),
  },
  {
    field: 'time',
    headerName: 'Time',
    type: 'dateTime',
    minWidth: 125,
    flex: 1,
    maxWidth: 140,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
    valueGetter: (params: GridValueGetterParams) => showTimestamp(params.row.timestamp),
  },
  {
    field: 'rollId',
    headerName: 'RollId',
    flex: 1,
    // minWidth: 125,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
  {
    field: 'sequence',
    headerName: 'Sequence',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
  {
    field: 'recipe',
    headerName: 'Recipe',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 125,
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'washTime',
    headerName: 'WashTime',
    headerClassName: 'header',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
  },
  {
    field: 'tank',
    headerName: 'Tank',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'temp',
    headerName: 'Temperature',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'rinse',
    headerName: 'Rinse',
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'dry',
    headerName: 'Dry',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'employeeId',
    headerName: 'EmployeeId',
    minWidth: 125,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
];

export const laserCleaningColumns: GridColumns = [
  {
    field: nameof<ICleaningHistoryDTO>('timestamp'),
    headerName: 'Date',
    type: 'dateTime',
    minWidth: 125,
    flex: 1,
    maxWidth: 140,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
    valueFormatter: (params: GridValueFormatterParams) => getFormattedDate(params.value),
  },
  {
    field: 'time',
    headerName: 'Time',
    type: 'dateTime',
    minWidth: 125,
    flex: 1,
    maxWidth: 140,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
    valueGetter: (params: GridValueGetterParams) => showTimestamp(params.row.timestamp),
  },
  {
    field: 'rollId',
    headerName: 'RollId',
    flex: 1,
    // minWidth: 125,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
  {
    field: 'sequence',
    headerName: 'Sequence',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
  {
    field: 'intensity',
    headerName: 'Intensity',
    align: 'left',
    headerAlign: 'left',
    minWidth: 125,
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'color',
    headerName: 'Color',
    headerClassName: 'header',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
  },
  {
    field: 'passes',
    headerName: 'Passes',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'employeeId',
    headerName: 'EmployeeId',
    minWidth: 125,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
];

export const pwCleaningColumns: GridColumns = [
  {
    field: nameof<ICleaningHistoryDTO>('timestamp'),
    headerName: 'Date',
    type: 'dateTime',
    minWidth: 125,
    flex: 1,
    maxWidth: 140,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
    valueFormatter: (params: GridValueFormatterParams) => getFormattedDate(params.value),
  },
  {
    field: 'time',
    headerName: 'Time',
    type: 'dateTime',
    minWidth: 125,
    flex: 1,
    maxWidth: 140,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
    valueGetter: (params: GridValueGetterParams) => showTimestamp(params.row.timestamp),
  },
  {
    field: 'sequence',
    headerName: 'Sequence',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
  {
    field: 'recipe',
    headerName: 'Recipe',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 125,
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'washTime',
    headerName: 'WashTime',
    headerClassName: 'header',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
  },
  {
    field: 'tank',
    headerName: 'Tank',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'temp',
    headerName: 'Temperature',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'washSpeed',
    headerName: 'WashSpeed',
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'employeeId',
    headerName: 'EmployeeId',
    minWidth: 125,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    headerClassName: 'header',
  },
];
