import {FC, memo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetAgentsQuery} from 'state/features/api/agents-slice';
import {useGetMachinesQuery} from 'state/features/api/machines-slice';
import {useGetOrganizationsQuery} from 'state/features/api/organization-slice';
import {useGetUsersQuery} from 'state/features/api/user-slice';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import {useAuth} from '../../../auth/auth-provider';
import {localized, localizedInterpolation} from '../../../i18n/i18n';
import {routes} from '../../../state/routes';
import {BasicButton} from '../../components/default-components/buttons/basic-button';
import {FlexColumn} from '../../components/default-components/flex-column';
import {FlexRow} from '../../components/default-components/flex-row';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../components/default-components/page-header.tsx/page-header-content';
import {PageHeaderSearch} from '../../components/default-components/page-header.tsx/page-header-search';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {AgentsListView} from './agents-list-view/agents-list-view';

export const Agents: FC = memo(() => {
  const navigate = useNavigate();
  const {isGlobalAdmin} = useAuth();
  const {isLoading: showSpinner} = useGetAgentsQuery(undefined, {refetchOnMountOrArgChange: true});
  useGetUsersQuery(undefined, {refetchOnMountOrArgChange: true});
  useGetMachinesQuery();
  useGetOrganizationsQuery();

  const goToCreateAgent = useCallback(() => {
    navigate(routes.agentCreate);
  }, [navigate]);

  return (
    <PageTitle title={localized('Agents')}>
      <PageHeader area="agents" title={localized('Agents')}>
        <PageHeaderContent>
          <FlexColumn>
            <FlexRow flexWrap="wrap">
              <PageHeaderSearch />
            </FlexRow>
          </FlexColumn>
          {isGlobalAdmin && (
            <BasicButton onClick={goToCreateAgent} text={localizedInterpolation('CreateNewInt', 'Agent')} />
          )}
        </PageHeaderContent>
      </PageHeader>
      <PageContentContainer xy>{showSpinner ? <BasicSpinner /> : <AgentsListView />}</PageContentContainer>
    </PageTitle>
  );
});
