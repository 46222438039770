import {BaseQueryFn, createApi} from '@reduxjs/toolkit/query/react';
import {ApiException} from 'api/api';
import {AsyncOperationHandler} from 'utilities/platform-helpers/api-helper';
// Define our single API slice object - "one API slice per base URL"
// https://redux.js.org/tutorials/essentials/part-7-rtk-query-basics

// Set auth header:
// https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery

// Querying data correctly:
// https://redux-toolkit.js.org/rtk-query/usage/queries

// Check how to split up endpoints, with injectEndpoints
// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints

// keepUnusedDataFor : How long (seconds) to keep data with no subscribers
//  (no rendered components that use it). The default value is 60.

interface ApiOptions {
  showResponse?: boolean;
  skipAuth?: boolean;
}
const clientBaseQuery =
  (): BaseQueryFn<() => Promise<unknown>, unknown, ApiException, ApiOptions> => async (query, api, extraOptions) => {
    try {
      const result = await AsyncOperationHandler(query, extraOptions?.showResponse, extraOptions?.skipAuth);
      //Important to return null instead of undefined => https://github.com/reduxjs/redux-toolkit/issues/2090
      return {data: result ?? null};
    } catch (apiException) {
      console.error(apiException);
      return {error: apiException as ApiException};
    }
  };

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: clientBaseQuery(),
  endpoints: (builder) => ({}),
});
