import {Box, BoxProps, SxProps} from '@mui/material';
import React, {FC, useCallback} from 'react';
import {useAuth} from '../../../../auth/auth-provider';
import {theme} from '../../../../styles/theme';

interface PropsFromParent<T> {
  itemSelected?: (item: T) => void;
  contentItem?: T;
}

type Props = BoxProps & PropsFromParent<any>;

const style: SxProps = {
  cursor: 'pointer',
  width: 216,
  height: 216,
  padding: 3,
  backgroundColor: theme.colors.contentWrapper.contentWrapperBackgroundColor,
  wordBreak: 'break-word'
};
export const ContentBox: FC<Props> = React.memo((props) => {
  const {isAdmin} = useAuth();
  const itemSelectedLocal = useCallback(() => {
    if (props.itemSelected && props.contentItem) {
      props.itemSelected(props.contentItem);
    }
  }, [props]);

  return (
    <Box {...props} sx={{...style, cursor: isAdmin ? 'pointer' : 'default'}} onClick={itemSelectedLocal}>
      {props.children}
    </Box>
  );
});
