import dayjs from 'dayjs';
import {DateFormat, TimestampFormat} from '../constants';

export const getFormattedDate = (date: Date) => {
  return dayjs(date).format(DateFormat);
};

export const setFromDate = (dateFrom: Date) => {
  dateFrom.setHours(0);
  dateFrom.setMinutes(0);
  dateFrom.setSeconds(0);

  return dateFrom;
};

export const setToDate = (dateTo: Date) => {
  dateTo.setHours(23);
  dateTo.setMinutes(59);
  dateTo.setSeconds(59);

  return dateTo;
};

export const showTimestamp = (timestamp: Date) => {
  return dayjs(timestamp).format(TimestampFormat);
};

export const adjustForTimezone = (date: Date | undefined, negativeOffset: boolean = false): Date | undefined => {
  if (date) {
    let timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    if (negativeOffset) {
      date.setTime(date.getTime() + timeOffsetInMS);
    } else {
      date.setTime(date.getTime() - timeOffsetInMS);
    }

    return date;
  }
  return undefined;
};

function pad2(n: number) {
  return n < 10 ? '0' + n : n;
}
export const YYMMDDhhmm = (date: Date) => {
  return (
    date.getFullYear().toString() +
    pad2(date.getMonth() + 1) +
    pad2(date.getDate()) +
    pad2(date.getHours()) +
    pad2(date.getMinutes())
  );
};
