import {Checkbox, FormControlLabel} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {genericMemo} from '../../../../utilities/generic-memo';
import {FilterConfig} from './filter-panel-types';
import {useFilterPanelContextInternal} from '../page-header.tsx/page-header-filter';

interface Props<T> {
  filterConfig: FilterConfig<T>;
  value: any;
}

export const FilterPanelOption = genericMemo(<T,>({value, filterConfig}: Props<T>) => {
  const {selectedValues, setSelectedValues} = useFilterPanelContextInternal();
  const field = useMemo(() => filterConfig.field.toString(), [filterConfig.field]);
  // Check state is held locally to provide a smooth ui experience without delay from clicking the checkbox to the checkbox being updated.
  const [checkedLocal, setCheckedLocal] = useState<boolean>(selectedValues[field].includes('' + value));

  const onChangeLocal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (selectedValues[field] === undefined) return;
      const copy = {...selectedValues};
      setCheckedLocal(checked);
      copy[field] = checked ? [...copy[field], '' + value] : copy[field].filter((v) => v !== '' + value);

      setSelectedValues(copy);
    },
    [field, selectedValues, setSelectedValues, value],
  );

  // Needed for updating the checkbox when the filter is reset.
  useEffect(() => {
    setCheckedLocal(selectedValues[field].includes('' + value));
  }, [field, selectedValues, value]);

  return (
    <FormControlLabel
      sx={{userSelect: 'none'}}
      control={
        <Checkbox
          color="primary"
          sx={{padding: 0.2, marginRight: 0.8}}
          checked={checkedLocal}
          onChange={onChangeLocal}
          focusRipple={true}
        />
      }
      label={value}
    />
  );
});
