import {FC, memo, useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {routes} from 'state/routes';
import {tokenStorageKey} from './auth-constants';
import {useAuth} from './auth-provider';

export const PrivateRoute: FC = memo(() => {
  const {isAuthenticated, loading, user, login, tokenExpiredHandling} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  // Renew token if expired on navigation
  useEffect(() => {
    if (!loading && user) {
      tokenExpiredHandling(tokenStorageKey);
    }
    // location is here to make sure this runs every time location changes
  }, [location, loading, user, tokenExpiredHandling]);

  // Redirect to landing screen if user is trying to access private route without being signed in
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    } else {
      navigate(routes.root);
    }
  }, [isAuthenticated, loading, navigate]);

  return isAuthenticated ? <Outlet /> : <></>;
});
