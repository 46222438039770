import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LanguageType} from 'i18n/i18n';
import {AppState} from '../../store';
import {SettingsState} from './types';

const initialState: SettingsState = {
  selectedLanguage: undefined,
};
export const settingsSlice = createSlice({
  name: 'settingSlice',
  initialState,
  reducers: {
    setSelectedLanguage: (state, action: PayloadAction<LanguageType>) => {
      state.selectedLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const selectedLanguage = (store: AppState) => store.settingsReducer.selectedLanguage;

// eslint-disable-next-line no-empty-pattern
export const {setSelectedLanguage} = settingsSlice.actions;
export default settingsSlice.reducer;
