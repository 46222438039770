import { Theme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import withStyles from '@mui/styles/withStyles';
export const ToggleItem = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    color: theme.colors.toggle.toggleButtonColor,
    border: 'none',
    '&:hover': {
      background: 'transparent',
      opacity: 0.7,
    },
    '&$selected': {
      // this is to refer to the prop provided by M-UI
      color: theme.colors.toggle.toggleButtonColorSelected,
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
        opacity: 0.7,
      },
    },
  },
  selected: {},
}))(ToggleButton);

// Togglebutton got alot of stupid overrides depending og selected, child and so on... !important fixes this
export const ToggleTextItem = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    fontSize: theme.typography.body3.fontSize,
    color: theme.colors.toggle.toggleButtonColorSelected,

    background: 'transparent',
    '& > span': {
      background: 'transparent',
    },

    '&:hover': {
      background: 'transparent',
      opacity: 0.7,
    },
    '&$selected': {
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
        opacity: 0.7,
      },
      '& span > *:not(span)': {
        color: theme.colors.toggle.toggleButtonTextColor,
        background: theme.colors.toggle.toggleButtonColorSelected,
      },
    },
  },
  selected: {},
}))(ToggleButton);
