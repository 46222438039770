import {Box, Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  searchChanged,
  selectPageSettingByKey,
} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {SearchInput} from '../search-input/search-input';
import {usePageHeader} from './page-header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBox: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '280px',
        marginRight: 0,
      },
    },
  }),
);

export const PageHeaderSearch: FC = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {areaId} = usePageHeader();
  const pageSetting = useSelector(selectPageSettingByKey(areaId));

  const searchChangedLocal = useCallback(
    (newSearchString: string) => {
      dispatch(searchChanged({value: newSearchString, key: areaId}));
    },
    [dispatch, areaId],
  );

  useEffect(() => {
    return () => {
      dispatch(searchChanged({value: '', key: areaId}));
    };
  }, [areaId, dispatch]);

  return (
    <Box marginRight={4} className={classes.searchBox}>
      <SearchInput value={pageSetting?.searchString} onChange={searchChangedLocal} />
    </Box>
  );
});
