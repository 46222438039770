import {Box, BoxProps, SxProps, Theme} from '@mui/material';
import React, {FC} from 'react';
import {basicCardBgColor, basicCardBoxShadow} from '../../../../styles/color-constants';

interface Props extends BoxProps {
  sx?: SxProps<Theme>;
}
export const BasicCard: FC<Props> = React.memo(({sx, children, ...props}) => {
  return (
    <Box
      sx={sx}
      minWidth={150}
      position="relative"
      minHeight={150}
      width="fit-content"
      bgcolor={basicCardBgColor}
      boxShadow={basicCardBoxShadow}
      p={2.5}
      borderRadius="2px"
      {...props}>
      {children}
    </Box>
  );
});
