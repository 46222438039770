import {Box, Skeleton, Stack, Typography} from '@mui/material';
import React from 'react';

const MachineSkeleton: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: 'common.blue50',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}>
        <Stack
          spacing={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}>
          <Skeleton
            variant="rectangular"
            width={236}
            height={236}
            sx={{
              margin: '0 auto',
            }}
          />
          <Typography variant="h4">
            <Skeleton width={200} sx={{marginTop: 4}} />
          </Typography>
          <Typography>
            <Skeleton width={100} sx={{marginTop: -1, marginBottom: 4}} />
          </Typography>

          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row" alignItems="center" columnGap={2}>
              <Skeleton variant="circular" height={40} width={40} />
              <Stack>
                <Typography>
                  <Skeleton width={120} />
                  <Skeleton width={150} />
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" columnGap={2}>
              <Stack alignItems="flex-end">
                <Skeleton width={80} />
                <Skeleton width={150} />
              </Stack>
              <Skeleton variant="circular" height={40} width={40} />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default React.memo(MachineSkeleton);
