import {localized, localizedInterpolation} from 'i18n/i18n';
import {FC, memo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetMachineTypesQuery} from 'state/features/api/machine-type-slice';
import {routes} from 'state/routes';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {FlexColumn} from 'view/components/default-components/flex-column';
import {FlexRow} from 'view/components/default-components/flex-row';
import PageContentContainer from 'view/components/default-components/page-container/page-content-container';
import {PageHeader} from 'view/components/default-components/page-header.tsx/page-header';
import {PageHeaderSearch} from 'view/components/default-components/page-header.tsx/page-header-search';
import {PageTitle} from 'view/components/default-components/page-title/page-title';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import {MachineTypeListView} from './machine-type-list-view/machine-type-list-view';

export const MachineTypes: FC = memo(() => {
  const navigate = useNavigate();

  const {isLoading: showSpinner} = useGetMachineTypesQuery();
  const goToCreateMachine = useCallback(() => {
    navigate(routes.machineTypeCreate);
  }, [navigate]);

  return (
    <PageTitle title={localized('MachineType')}>
      <PageHeader area="machineType" title={localized('MachineType')}>
        <FlexColumn>
          <FlexRow flexWrap="wrap" justifyContent={'space-between'}>
            <PageHeaderSearch />
            <BasicButton onClick={goToCreateMachine} text={localizedInterpolation('CreateNewInt', 'MachineType')} />
          </FlexRow>
        </FlexColumn>
      </PageHeader>
      <PageContentContainer xy>{showSpinner ? <BasicSpinner /> : <MachineTypeListView />}</PageContentContainer>
    </PageTitle>
  );
});
