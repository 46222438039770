import {Box, SxProps, Theme} from '@mui/material';
import {FC, ReactNode, memo, useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

interface Props {
  defaultValues?: any;
  onSubmit: any;
  sx?: SxProps<Theme>;
  children: ReactNode;
}

export const Form: FC<Props> = memo((props) => {
  const methods = useForm({
    defaultValues: useMemo(() => {
      return props.defaultValues;
    }, [props]),
  });

  useEffect(() => {
    if (props.defaultValues) {
      methods.reset(props.defaultValues);
    }
  }, [props.defaultValues, methods]);

  return (
    <FormProvider {...methods}>
      <Box
        component={'form'}
        onSubmit={methods.handleSubmit(props.onSubmit)}
        sx={{...props.sx, display: 'flex', flexDirection: 'column', flex: 1}}>
        {props.children}
      </Box>
    </FormProvider>
  );
});
