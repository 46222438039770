import {Stack} from '@mui/material';
import {IMachineCurrentStateDTO} from 'api/api';
import React from 'react';
import MachineConnection from './machine-connection';
import MachineState from './machine-state';

interface Props {
  currentState: IMachineCurrentStateDTO;
}

export const CurrentState: React.FC<Props> = (props) => {
  const {currentState} = props;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" columnGap={3}>
      <MachineState machineCurrentState={currentState} />
      <MachineConnection
        connectionState={currentState.connectionState}
        latestHeartbeat={currentState.latestConnectionStateChange}
      />
    </Stack>
  );
};

export default React.memo(CurrentState);
