import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const GermanFlagIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path fill="#ffda44" d="m0 345 256.7-25.5L512 345v167H0z" />
      <path fill="#d80027" d="m0 167 255-23 257 23v178H0z" />
      <path fill="#333" d="M0 0h512v167H0z" />
    </SvgIcon>
  );
};

export default React.memo(GermanFlagIcon);
