import {DialogActions, DialogTitle, Drawer, SxProps, Theme, Typography} from '@mui/material';
import React, {FC, createContext, memo, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {unstable_useBlocker as useBlocker, useMatch, useNavigate} from 'react-router-dom';
import {localized} from '../../../../i18n/i18n';
import {cancelButtonColor, cancelButtonColorHover, primaryTextColor} from '../../../../styles/color-constants';
import {BasicButton} from '../buttons/basic-button';
import {FlexColumn} from '../flex-column';
import {BasicModal} from '../modal/basic-modal';
interface PropsFromParent {
  route: string;
  defaultValues?: any;
  onSubmit: any;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  isSuccess?: boolean;
  reset?: () => void;
  warnUnsavedchanges?: boolean;
  anchor?: 'bottom' | 'left' | 'right' | 'top' | undefined;
}

interface IFormSideBarContext {
  closeSideBar: () => void;
  setManualIsDirty(value: boolean): void;
}
// Initial state for the auth context.
const initialState: IFormSideBarContext = {
  closeSideBar: () => {},
  setManualIsDirty: () => {},
};

const FormSidebarContext = createContext(initialState);
export const useFormSideBar = (): IFormSideBarContext => useContext(FormSidebarContext);

export const FormSideBar: FC<PropsFromParent> = memo((props) => {
  const match = useMatch(props.route);
  // Not rendering the side bar unless the route matches.
  // This is done to unmount the form component when navigating away from edit/create routes. If this is not done, the form will retain state across navigations events
  return match && <FormSideBarContent {...props} />;
});

const FormSideBarContent: FC<PropsFromParent> = memo((props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [manualBlock, setManualBlock] = useState<boolean>(false);
  const [manualIsDirty, setManualIsDirty] = useState<boolean>(false);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: useMemo(() => {
      return props.defaultValues;
    }, [props]),
  });

  const blocker = useBlocker(
    (props.warnUnsavedchanges ?? false) &&
      (methods.formState.isDirty || manualIsDirty) &&
      !manualBlock &&
      !methods.formState.isSubmitSuccessful,
  );

  const closeLocal = useCallback(() => {
    setDrawerOpen(false);

    // Timeout to allow animation to complete as navigation will hide sidebar instantly
    setTimeout(() => {
      navigate(-1);
    }, 100);
  }, [navigate]);

  const closeSideBar = useCallback(() => {
    if (props.warnUnsavedchanges && (methods.formState.isDirty || manualIsDirty)) {
      setManualBlock(true);
    } else {
      closeLocal();
    }
  }, [closeLocal, manualIsDirty, methods.formState.isDirty, props.warnUnsavedchanges]);

  const handleCancel = useCallback(() => {
    blocker.reset?.();
    setManualBlock(false);
  }, [blocker]);

  const handleConfirm = useCallback(() => {
    blocker.proceed?.();
    if (manualBlock) {
      closeLocal();
    }
  }, [blocker, closeLocal, manualBlock]);

  useEffect(() => {
    // Opens the drawer after the component has been mounted. This allows the animation to run.
    setDrawerOpen(true);
  }, []);

  useEffect(() => {
    if (props.defaultValues) {
      methods.reset(props.defaultValues);
    }
  }, [props.defaultValues, methods]);

  useEffect(() => {
    if (!props.isSuccess) return;
    closeLocal();

    props.reset && props.reset();
  }, [closeLocal, props, props.isSuccess]);

  // Reset manual block on unmount to avoid clashing with navigation and animation
  useEffect(() => {
    return () => {
      setManualBlock(false);
    };
  }, []);

  return (
    <FormSidebarContext.Provider value={{closeSideBar, setManualIsDirty}}>
      <FormProvider {...methods}>
        <Drawer anchor="right" open={drawerOpen} onClose={closeSideBar}>
          <FlexColumn
            component={'form'}
            onSubmit={methods.handleSubmit(props.onSubmit)}
            width={600}
            padding={4}
            justifyContent={'space-between'}
            flex={1}
            sx={props.sx}>
            {props.children}
          </FlexColumn>
          <BasicModal open={blocker.state === 'blocked' || manualBlock}>
            <DialogTitle>
              <Typography>{localized('NavigateWarning')}</Typography>
            </DialogTitle>
            <DialogActions>
              <BasicButton onClick={handleConfirm} text={localized('Continue')} buttonColor={'primary'} />
              <BasicButton
                onClick={handleCancel}
                text={localized('Cancel')}
                buttonColor={'secondary'}
                sx={{...sideBarButtonSx, color: primaryTextColor}}
              />
            </DialogActions>
          </BasicModal>
        </Drawer>
      </FormProvider>
    </FormSidebarContext.Provider>
  );
});

export const sideBarButtonSx: SxProps = {
  pl: 4,
  pr: 4,
  pt: 1,
  pb: 1,
  fontWeight: 'normal',
};

export const cancelSideBarButtonSx: SxProps = {
  ...sideBarButtonSx,
  backgroundColor: cancelButtonColor,
  ':hover': {
    backgroundColor: cancelButtonColorHover,
  },
};
