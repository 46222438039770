import {LoadingButton} from '@mui/lab';
import {useDownloadFile} from 'hooks/use-download-file/use-download-file';
import {FC, memo, useCallback} from 'react';
import {machineClient} from 'state/api-clients';
import {AsyncOperationHandler} from 'utilities/platform-helpers/api-helper';
import {YYMMDDhhmm} from 'utilities/platform-helpers/date-helpers';
import DownloadIcon from '@mui/icons-material/Download';
import {Theme} from '@emotion/react';
import {SxProps} from '@mui/material';
import {excelDownloadButtonColor} from 'styles/color-constants';
import {localized} from 'i18n/i18n';

export const DownloadExcelButton: FC = memo(() => {
  const iconStyles: SxProps<Theme> = {fontSize: '1.5rem', color: excelDownloadButtonColor, ml: 1};

  const exportMachineOverview = useCallback(async () => {
    return await AsyncOperationHandler(() => machineClient.exportMachineOverview());
  }, []);

  const namePostfix = () => {
    return `-${YYMMDDhhmm(new Date())}`;
  };

  const {ref, url, download, name, isLoading} = useDownloadFile({
    apiDefinition: exportMachineOverview,
    namePostfix: namePostfix,
  });

  return (
    <LoadingButton sx={{marginLeft: 1}} loading={isLoading} onClick={download}>
      {localized('ExportExcel')}
      <DownloadIcon sx={iconStyles} />
      <a href={url} download={name} className="hidden" ref={ref} />
    </LoadingButton>
  );
});
