import ClearIcon from '@mui/icons-material/Clear';
import TuneIcon from '@mui/icons-material/Tune';
import {Drawer, Typography} from '@mui/material';
import {Box, SxProps} from '@mui/system';
import {once} from 'lodash';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {localized} from '../../../../i18n/i18n';
import {
  blackColor,
  containerBackground,
  containerBackgroundHover,
  primaryColor,
  primaryTextColor,
  secondaryColor,
  whiteColor,
} from '../../../../styles/color-constants';
import {genericMemo} from '../../../../utilities/generic-memo';
import {useEnterPressListener} from '../../../../utilities/platform-helpers/event-listener-helper';
import {BasicButton} from '../buttons/basic-button';
import {FlexColumn} from '../flex-column';
import {FlexRow} from '../flex-row';
import {FilterPanelContent} from '../filter-panel/filter-panel-content';
import {FilterConfig, FilterProps} from '../filter-panel/filter-panel-types';
import {cancelSideBarButtonSx, sideBarButtonSx} from '../side-bar/form-side-bar';
import {PageHeaderButton} from './page-header-button';
interface IFilterPanelContext<T> {
  configs: FilterConfig<T>[];
  selectedValues: Record<string, string[]>;
  setSelectedValues(selectedValues: Record<string, string[]>): void;
}

export const createFilterPanelContext = once(<T,>() => createContext({} as IFilterPanelContext<T>));

export const useFilterPanelContextInternal = <T,>(): IFilterPanelContext<T> =>
  useContext(createFilterPanelContext<T>());

export const PageHeaderFilter = genericMemo(<T,>(props: FilterProps<T>) => {
  const FilterPanelContext = createFilterPanelContext<T>();
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialValues = useMemo(() => {
    const record: Record<string, string[]> = {};

    props.filterConfigs.forEach((filter) => {
      const key = filter.field.toString();
      const values = searchParams?.getAll(key) ?? [];
      record[key] = values;
    });
    return record;
  }, [props.filterConfigs, searchParams]);

  const [selectedValues, setSelectedValues] = useState<Record<string, string[]>>(initialValues);

  const icon = useMemo(
    () => (
      <FlexColumn>
        <TuneIcon sx={{fontSize: 24}} />
      </FlexColumn>
    ),
    [],
  );

  const text = useMemo(
    () => (
      <FlexRow alignItems={'center'} gap={0.5}>
        {localized('Filters')}
        {searchParams.keys.length > 0 ? <Box sx={localStyles.filterCount}>{searchParams.keys.length}</Box> : <></>}
      </FlexRow>
    ),
    [searchParams.keys.length],
  );

  const onApplyFilter = useCallback(() => {
    const newParams = new URLSearchParams();
    Object.keys(selectedValues).forEach((key) => {
      selectedValues[key].forEach((value) => {
        newParams.append(key, value);
      });
    });
    setOpen(false);
    setSearchParams(newParams, {replace: true});
  }, [selectedValues, setSearchParams]);

  const onReset = useCallback(() => {
    const record: Record<string, string[]> = {};

    props.filterConfigs.forEach((filter) => {
      const key = filter.field.toString();
      record[key] = [];
    });
    setSelectedValues(record);
    setSearchParams(new URLSearchParams(), {replace: true});
    setOpen(false);
  }, [props.filterConfigs, setSearchParams]);

  const onCancel = useCallback(() => {
    setSelectedValues(initialValues);
    setOpen(false);
  }, [initialValues]);

  useEffect(() => {
    setSelectedValues(initialValues);
  }, [initialValues]);

  useEnterPressListener(onApplyFilter);

  return (
    <FilterPanelContext.Provider value={{configs: props.filterConfigs, selectedValues, setSelectedValues}}>
      <>
        <PageHeaderButton
          sx={{...localStyles.button, minWidth: selectedValues.length ? 165 : 135}}
          text={text}
          onClick={() => setOpen(true)}
          icon={icon}
        />
        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{sx: {backgroundColor: secondaryColor}}}>
          <FlexColumn width={600} padding={4} flex={1} justifyContent={'space-between'}>
            <FlexColumn>
              <FlexRow alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="h1">{localized('Filters')}</Typography>
                <BasicButton
                  variant="text"
                  buttonColor={'primary'}
                  sx={{boxShadow: 'none', ':hover': {boxShadow: 'none'}, color: blackColor, marginTop: 2}}
                  icon={<ClearIcon />}
                  onClick={onReset}
                  text={localized('ResetFilter').toLocaleUpperCase()}
                />
              </FlexRow>
              <FilterPanelContent {...props} />
            </FlexColumn>
            <FlexRow gap={2}>
              <BasicButton
                sx={{...cancelSideBarButtonSx, color: whiteColor}}
                buttonColor={'secondary'}
                onClick={onCancel}
                text={localized('Cancel').toLocaleUpperCase()}
              />
              <BasicButton
                sx={{...sideBarButtonSx}}
                onClick={onApplyFilter}
                buttonColor="primary"
                text={localized('ShowResults').toLocaleUpperCase()}
              />
            </FlexRow>
          </FlexColumn>
        </Drawer>
      </>
    </FilterPanelContext.Provider>
  );
});

const localStyles: {[key: string]: SxProps} = {
  button: {
    justifyContent: 'space-between',
    backgroundColor: containerBackground,
    color: primaryColor,
    ':hover': {
      backgroundColor: containerBackgroundHover,
    },
  },
  filterCount: {
    backgroundColor: primaryColor,
    color: whiteColor,
    borderRadius: 30,
    height: 29,
    width: 29,
  },
  resetButton: {
    boxShadow: 'none',
    marginTop: 2,
    color: primaryTextColor,
    ':hover': {
      boxShadow: 'none',
    },
  },
};
