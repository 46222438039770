import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {}

export const WashingIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 41.351 41.181">
      <g transform="translate(3732 893)">
        <path
          id="Path_72"
          data-name="Path 72"
          d="M15.277,31.1q-.352-.255-.687-.536a11.9,11.9,0,0,1-4.23-8.311,11.773,11.773,0,0,1,2.77-8.459c.448-.529.96-.117.722.368a6.526,6.526,0,0,0-.585,4.109,7.771,7.771,0,0,0,1.615,3.42,10.127,10.127,0,0,0,3.158,2.586,10.656,10.656,0,0,0,8.759.584,11.406,11.406,0,0,0,5.6-4.416,10.512,10.512,0,0,1-3.371,2.215,11.347,11.347,0,0,1-3.947.85,10.691,10.691,0,0,1-3.961-.606,9.5,9.5,0,0,1-2.649-1.454,7.518,7.518,0,0,1-2.853-7.535,6.115,6.115,0,0,1,2.505-3.313,18.477,18.477,0,0,1,4.357-1.978C29.771,6.1,28.565-.646,19.054.047A20.566,20.566,0,0,0,9.215,37.7,18.369,18.369,0,0,1,14.8,6.878a.4.4,0,1,1,.337.725c-9.189,6.525-9.058,18.859.139,23.5"
          transform="translate(-3732 -892.997)"
          fillRule="evenodd"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M465.811,279.566a18.359,18.359,0,0,1-1.691,28.743,17.547,17.547,0,0,1-6.353,2.778v5.924q.887-.143,1.76-.365a20.564,20.564,0,0,0,11.783-31.718,22.053,22.053,0,0,0-1.665-2.089,15.785,15.785,0,0,0-1.965-1.878c-.617-.5-1.2-.956-1.868-1.395m-8.044,27.558v-2.357a12.176,12.176,0,0,0,5.135-2.016,14.322,14.322,0,0,0,4.523-5.217,19.974,19.974,0,0,0,2.016-6.091c.02-.124.039-.253.057-.386a17.157,17.157,0,0,1-1.929,9.187,13.785,13.785,0,0,1-9.8,6.88m0,3.963a15.656,15.656,0,0,1-8.79-.582,14.179,14.179,0,0,1-8.105-7.821,13.927,13.927,0,0,1-1.115-6.458,14.606,14.606,0,0,1,.26-1.94,17.528,17.528,0,0,0,3.646,17.278,21.133,21.133,0,0,0,8.789,5.61,20.593,20.593,0,0,0,5.315-.164Zm0-6.32v2.357a10.377,10.377,0,0,1-7.028-1.324,10.262,10.262,0,0,1-1.169-.826,9.8,9.8,0,0,1-3.579-6.578,8.9,8.9,0,0,0,1.557,2.73,11.2,11.2,0,0,0,9.8,3.7q.212-.023.423-.054"
          transform="translate(-4165.675 -1169.094)"
          fillRule="evenodd"
        />
      </g>
    </SvgIcon>
  );
};

export default React.memo(WashingIcon);
