import {Typography, TypographyProps} from '@mui/material';
import React, {FC} from 'react';

function isOverflown(element: any) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export const ContentBoxHeader: FC<TypographyProps> = React.memo((props) => {
  return (
    <Typography sx={{overflow: 'auto'}} textAlign="left" color="text.secondary" variant="h6" fontWeight="bold" {...props}>
      {props.children}
    </Typography>
  );
});
