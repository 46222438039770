import {GridCallbackDetails, GridSortModel} from '@mui/x-data-grid';
import {SortOrder} from 'api/api';
import {FC, memo, useCallback, useMemo, useState} from 'react';
import {useGetAlarmHistoryQuery} from 'state/features/api/machines-history-slice';
import {HistoryDataGrid, defaultRowsPerPage} from 'view/components/specific-components/data-grid/history-data-grid';
import {alarmColumns} from './alarm-columns';

interface Props {
  machineId: number;
}

const sortingMapper: Record<'asc' | 'desc', SortOrder> = {
  asc: SortOrder.Asc,
  desc: SortOrder.Desc,
};

export const AlarmHistory: FC<Props> = memo((props) => {
  const {machineId} = props;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultRowsPerPage);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'timestamp',
      sort: 'desc',
    },
  ]);

  const queryOptions = useMemo(() => {
    let sortOrder = sortModel[0]?.sort ? sortingMapper[sortModel[0].sort] : undefined;
    return {
      page,
      pageSize,
      sortField: sortModel[0]?.field ?? '',
      sortOrder: sortOrder,
    };
  }, [page, pageSize, sortModel]);

  const {data, isFetching} = useGetAlarmHistoryQuery({
    pageNumber: queryOptions.page,
    pageSize: queryOptions.pageSize,
    machineId: machineId,
    sortField: queryOptions.sortField,
    sortOrder: queryOptions.sortOrder,
  });

  const {data: rows, totalItems} = data ?? {};

  const onPageSizeChange = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
    },
    [setPageSize],
  );

  const onPageChange = useCallback((pageNumber: number) => {
    setPage(pageNumber);
  }, []);

  const onSortModelChange = (model: GridSortModel, details: GridCallbackDetails<any>) => {
    setSortModel(model);
  };

  return (
    <HistoryDataGrid
      initialState={{
        sorting: {
          sortModel: [{field: 'timestamp', sort: 'desc'}],
        },
      }}
      loading={isFetching}
      rows={rows ?? []}
      page={page}
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      paginationMode="server"
      sortingMode="server"
      rowCount={totalItems}
      columns={alarmColumns}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      pagination
    />
  );
});
