import {ICleaningHistoriesDTO, PagedResponseOfAlarmHistory, SortOrder} from 'api/api';
import {machineHistoryClient} from 'state/api-clients';
import {apiSlice} from './api-slice';

export const machineHistorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCleaningHistory: builder.query<
      ICleaningHistoriesDTO,
      {pageNumber: number; pageSize: number; sortField: string; sortOrder?: SortOrder; machineId: number}
    >({
      query:
        ({pageNumber, pageSize, sortField, sortOrder, machineId}) =>
        () =>
          machineHistoryClient.getCleaningHistory(pageNumber, pageSize, sortField, sortOrder, machineId),
    }),

    getAlarmHistory: builder.query<
      PagedResponseOfAlarmHistory,
      {pageNumber: number; pageSize: number; sortField: string; sortOrder?: SortOrder; machineId: number}
    >({
      query:
        ({pageNumber, pageSize, sortField, sortOrder, machineId}) =>
        () =>
          machineHistoryClient.getAlarmHistory(pageNumber, pageSize, sortField, sortOrder, machineId),
    }),
  }),
});

export const {useGetAlarmHistoryQuery, useGetCleaningHistoryQuery} = machineHistorySlice;
