import {ITankStatusDTO} from 'api/api';
import {tankStatusClient} from 'state/api-clients';
import {apiSlice} from './api-slice';

export const tankStatusSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTankStatus: builder.query<ITankStatusDTO, number | undefined>({
      query: (machineId) => () => tankStatusClient.getBySerialNumber(machineId),
    }),
  }),
});

export const {useGetTankStatusQuery} = tankStatusSlice;
