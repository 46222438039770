import {Action, configureStore, Reducer, ThunkAction} from '@reduxjs/toolkit';
import {PersistConfig, persistReducer} from 'redux-persist';
import {PersistPartial} from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import {apiSlice} from './features/api/api-slice';
import authReducerBase from './features/auth/auth-slice';
import pageSettingReducerBase from './features/page-header-setting/page-header-setting-slice';
import settingsReducerBase from './features/settings/settings-slice';
import snackbarReducer from './features/snackbar/snackbar-slice';
import webAppReducerBase from './features/webapp/webapp-slice';
import activeColumnsReducerBase from './features/machine-overview-columns/active-column-slice';
import {setStore} from './store-container';

export type AppState = ReturnType<typeof store.getState>;

const createPersistReducer = <T extends Reducer<any, Action<any>>>(config: PersistConfig<any>, reducer: T) => {
  return persistReducer(config, reducer) as typeof reducer & PersistPartial;
};

// Create persisted reducers
const webappPersistConfig: PersistConfig<any> = {
  key: 'webapp',
  storage,
  whitelist: ['open'],
  blacklist: ['showOverlaySpinner'],
};
const webAppReducer = createPersistReducer(webappPersistConfig, webAppReducerBase);

const settingsPersistConfig: PersistConfig<any> = {
  key: 'settingsReducer',
  storage,
  whitelist: ['selectedOrganization'],
};
const settingsReducer = createPersistReducer(settingsPersistConfig, settingsReducerBase);

const pageSettingPersistConfig: PersistConfig<any> = {
  key: 'pageSettingReducer',
  storage,
  whitelist: ['pageSettings'],
};

const pageSettingReducer = createPersistReducer(pageSettingPersistConfig, pageSettingReducerBase);

const authPersistConfig: PersistConfig<any> = {
  key: 'authReducer',
  storage,
  whitelist: ['accessToken', 'pending'],
};
const authReducer = createPersistReducer(authPersistConfig, authReducerBase);

const activeColumnsPeristConfig: PersistConfig<any> = {
  key: 'activeColumnsReducer',
  storage,
  whitelist: ['activeColumns'],
};
const activeColumnsReducer = createPersistReducer(activeColumnsPeristConfig, activeColumnsReducerBase);

export const store = configureStore({
  reducer: {
    snackbarReducer,
    authReducer,
    settingsReducer,
    webAppReducer,
    pageSettingReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    activeColumnsReducer,
  },
  // Middleware declard here now as old method was deprecated. Auth interceptor no longer used as functionality is handled elsewere.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Needed as Date object is considered un-serializable by redux
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});
setStore(store);
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;
