import {createSelector} from '@reduxjs/toolkit';
import {IUserDTO, SelfSignUpRequestDTO, UserDTO} from 'api/api';
import {userClient} from 'state/api-clients';
import {apiSlice} from './api-slice';

const userSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['User'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUsers: builder.query<IUserDTO[], void>({
        query: () => () => userClient.get(),
        providesTags: ['User'],
      }),
      getCurrentUser: builder.query<IUserDTO, void>({
        query: () => () => userClient.getCurrentUser(),
        providesTags: ['User'],
      }),
      createUser: builder.mutation<IUserDTO, UserDTO>({
        query: (user) => () => userClient.post(user),
        extraOptions: {showResponse: true},
        invalidatesTags: ['User'],
      }),
      updateUser: builder.mutation<IUserDTO, UserDTO>({
        query: (user) => () => userClient.put(user),
        extraOptions: {showResponse: true},
        invalidatesTags: ['User'],
      }),
      resendInvitation: builder.mutation<void, UserDTO>({
        query: (user) => () => userClient.resendInvitationEmail(user),
        extraOptions: {showResponse: true},
      }),
      deleteUser: builder.mutation<void, UserDTO>({
        query: (user) => () => userClient.delete(user),
        extraOptions: {showResponse: true},
        invalidatesTags: ['User'],
      }),
      deleteSelfUser: builder.mutation<void, void>({
        query: () => () => userClient.deleteSelf(),
        extraOptions: {showResponse: true},
        invalidatesTags: ['User'],
      }),
      removeMachineFromUser: builder.mutation<void, {machineId: number; userId: number}>({
        query: (args) => () => userClient.removeMachineFromUser(args.machineId, args.userId),
        extraOptions: {showResponse: true},
        invalidatesTags: ['User'],
      }),
      selfSignUp: builder.mutation<void, SelfSignUpRequestDTO>({
        query: (signUpRequest) => () => userClient.selfSignUp(signUpRequest),
        extraOptions: {showResponse: true, skipAuth: true},
        invalidatesTags: ['User'],
      }),
    }),
  });

export const selectUsers = createSelector(userSlice.endpoints.getUsers.select(), (result) => result.data ?? []);

export const selectCurrentUser = createSelector(
  userSlice.endpoints.getCurrentUser.select(),
  (result) => result.data ?? undefined,
);

export const selectUserById = (id: number | undefined) =>
  createSelector(selectUsers, (users) => {
    return users.find((mt) => mt.id === id);
  });

export const selectUserByAgentId = (agentId: number) =>
  createSelector(selectUsers, (users) => {
    return users.filter((user) => user.agentId === agentId);
  });
export const selectAgentUsers = createSelector(selectUsers, (users) => {
  return users.filter((user) => !!user.agentId);
});

export const selectNonAgentUsers = createSelector(selectUsers, (users) => {
  return users.filter((user) => !user.agentId);
});

export const {
  useGetUsersQuery,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useDeleteSelfUserMutation,
  useRemoveMachineFromUserMutation,
  useSelfSignUpMutation,
  useResendInvitationMutation,
} = userSlice;
