import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {AppLayout} from 'app-layout';
import {AdminRoute} from 'auth/admin-route';
import {Callback} from 'auth/callback';
import {GlobalAdminRoute} from 'auth/global-admin-route';
import {Route, createBrowserRouter, createRoutesFromElements} from 'react-router-dom';
import {AgentUsers} from 'view/pages/agents/agent-users/agent-users';
import {AgentUsersCreate} from 'view/pages/agents/agent-users/agent-users-create/agent-users-create';
import {Agents} from 'view/pages/agents/agents';
import {CreateAgent} from 'view/pages/agents/create-agent/create-agent';
import {CreateMachineType} from 'view/pages/machine-type/create-machine-type/create-machine-type';
import {MachineTypes} from 'view/pages/machine-type/machine-types';
import {AddMachine} from 'view/pages/machines/add-machine/add-machine';
import {CreateMachine} from 'view/pages/machines/create-machine/create-machine';
import {MachineDetails} from 'view/pages/machines/machine-details/machine-details';
import {MachineOverview} from 'view/pages/machines/machine-overview';
import {CreateOrganization} from 'view/pages/organization/create-organization/create-organization';
import {Organization} from 'view/pages/organization/organization';
import {LandingPage} from 'view/pages/sign-up/landing-page';
import {CreateUser} from 'view/pages/user-management/create-user/create-user';
import {UserManagement} from 'view/pages/user-management/user-management';
import {PrivateRoute} from './auth/private-route';
import {routes} from './state/routes';
import {DeleteUser} from 'view/pages/user-management/detele-user/delete-user';

export const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileOnly: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    desktopOnly: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    appContent: {flex: 1},
  }),
);
export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={routes.root} element={<AppLayout />}>
        <Route index element={<LandingPage />} />

        <Route element={<PrivateRoute />}>
          <Route path={routes.machines} element={<MachineOverview />} />
          <Route path={routes.machineDetails} element={<MachineDetails />} />
          <Route path={routes.machinesAdd} element={<AddMachine />} />
          <Route path={routes.userManagement}>
            <Route index element={<UserManagement />} />
            <Route element={<AdminRoute />}>
              <Route path={routes.userManagementCreate} element={<CreateUser />} />
              <Route path={routes.userManagementEdit} element={<CreateUser />} />
            </Route>
            <Route path={routes.userManagementDelete} element={<DeleteUser />} />
          </Route>

          <Route element={<GlobalAdminRoute />}>
            <Route path={routes.machinesCreate} element={<CreateMachine />} />
            <Route path={routes.machinesEdit} element={<CreateMachine />} />
            <Route path={routes.machineType}>
              <Route index element={<MachineTypes />} />
              <Route path={routes.machineTypeCreate} element={<CreateMachineType />} />
              <Route path={routes.machineTypeEdit} element={<CreateMachineType />} />
            </Route>
            <Route path={routes.organization}>
              <Route index element={<Organization />} />
              <Route path={routes.organizationCreate} element={<CreateOrganization />} />
              <Route path={routes.organizationEdit} element={<CreateOrganization />} />
            </Route>
            <Route path={routes.agent}>
              <Route index element={<Agents />} />
              <Route path={routes.agentCreate} element={<CreateAgent />} />
              <Route path={routes.agentEdit} element={<CreateAgent />} />
              <Route path={routes.agentUsers} element={<AgentUsers />} />
              <Route path={routes.agentUsersCreate} element={<AgentUsersCreate />} />
              <Route path={routes.agentUsersEdit} element={<AgentUsersCreate />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path={routes.callback} element={<Callback />} />
    </>,
  ),
);
