import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useGetOrganizationsQuery} from 'state/features/api/organization-slice';
import {useAuth} from '../../../auth/auth-provider';
import {localized, localizedInterpolation} from '../../../i18n/i18n';
import {ViewType} from '../../../state/features/page-header-setting/types';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {BasicButton} from '../../components/default-components/buttons/basic-button';
import {FlexColumn} from '../../components/default-components/flex-column';
import {FlexRow} from '../../components/default-components/flex-row';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../components/default-components/page-header.tsx/page-header-content';
import {PageHeaderSearch} from '../../components/default-components/page-header.tsx/page-header-search';
import {PageHeaderToggleView} from '../../components/default-components/page-header.tsx/page-header-toggle-view';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {BasicSpinner} from '../../components/default-components/spinner/basic-spinner';
import {OrganizationBoxView} from './organization-box/organization-box-view';
import {OrganizationListView} from './organization-list/organization-list-view';

export const Organization: FC = React.memo(() => {
  const navigate = useNavigate();
  const {isGlobalAdmin} = useAuth();
  const pageSettings = useSelector((store: AppState) => store.pageSettingReducer.pageSettings);
  const pageSetting = pageSettings.find((setting) => setting.key === 'organization');
  const {isLoading: showSpinner} = useGetOrganizationsQuery();

  const goToCreateOrganization = () => {
    navigate(routes.organizationCreate);
  };

  return (
    <PageTitle title={localized('Organization')}>
      <PageHeader area="organization" title={localized('Organization')}>
        <PageHeaderContent>
          <FlexColumn>
            <FlexRow flexWrap="wrap">
              <PageHeaderSearch />
              <FlexRow flex={1}>
                <PageHeaderToggleView />
              </FlexRow>
            </FlexRow>
          </FlexColumn>
          {isGlobalAdmin && (
            <BasicButton
              onClick={goToCreateOrganization}
              text={localizedInterpolation('CreateNewInt', 'Organization')}
            />
          )}
        </PageHeaderContent>
      </PageHeader>
      <PageContentContainer xy>
        {showSpinner ? (
          <BasicSpinner />
        ) : !pageSetting || pageSetting.itemView === ViewType.BoxView ? (
          <OrganizationBoxView />
        ) : pageSetting.itemView === ViewType.ListView ? (
          <OrganizationListView />
        ) : null}
      </PageContentContainer>
    </PageTitle>
  );
});
