import {Checkbox} from '@mui/material';
import React, {FC, memo} from 'react';

interface Props {
  checked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void;
}
export const ListTableCheckbox: FC<Props> = memo(({checked, onChange}) => {
  return <Checkbox color="primary" disableRipple sx={{padding: 0}} checked={checked} onChange={onChange} />;
});
