import {IAgentDTO, IMachineDTO} from 'api/api';
import {localized} from 'i18n/i18n';
import {FC, memo, useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {nameof} from 'utilities/platform-helpers/nameof-helper';
import {BasicIcon} from 'view/components/default-components/basic-icon';

interface Props {
  machine?: IMachineDTO;
}

export const CreateAgentListRemoveCell: FC<Props> = memo(({machine}) => {
  const {setValue, getValues} = useFormContext();

  const handleRemoveMachine = useCallback(() => {
    const machineIds: number[] = getValues(nameof<IAgentDTO>('machineIds'));
    setValue(
      nameof<IAgentDTO>('machineIds'),
      machineIds.filter((id) => id !== machine?.id),
    );
  }, [getValues, machine, setValue]);

  if (!machine) return <></>;

  return <BasicIcon onClick={handleRemoveMachine} icon="close" title={localized('Edit')} sx={{ml: 2.5}} />;
});
