import { Icon, Theme, IconProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, {FC} from 'react';
import {MdIcons} from '../../../utilities/material-design-icons';
import clsx from 'clsx';

interface PropsFromParent {
  icon: MdIcons;
  iconColor?: string;
  size?: number | string;
  onClick?: (event?: any) => void;
}
type Props = PropsFromParent & IconProps;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: (props: Props) => ({
      fontSize: props.size,
      color: props.iconColor,
      marginLeft: props.size && -props.size / 9, // Google adds spacing around the icon in the image file itself. The left margin is a self calculated value
    }),
    showCursor: {
      cursor: 'pointer',
    },
  }),
);

export const BasicIcon: FC<Props> = React.memo(({icon, iconColor, size, onClick, ...props}) => {
  const classes = useStyles({icon, size, iconColor, ...props});
  return (
    <Icon
      {...props}
      className={clsx(classes.iconContainer, props.className, {[classes.showCursor]: onClick})}
      onClick={onClick}>
      {icon}
    </Icon>
  );
});
