import {MachineGroupType, UserRole} from '../../api/api';
import {localizedDynamic} from '../../i18n/i18n';
import {DropdownOption} from '../../view/components/default-components/baisc-dropdown/basic-search-dropdown';

export function getUserRoleDropdownOptions(): DropdownOption[] {
  var options = Object.keys(UserRole)
    .filter((k) => typeof UserRole[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), UserRole[e as any]));

  return options.filter((option) => option.label !== 'Admin');
}

export function getMachineGroupTypeDropdownOptions(): DropdownOption[] {
  return Object.keys(MachineGroupType)
    .filter((k) => typeof MachineGroupType[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), MachineGroupType[e as any]));
}
